import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
// import _ from 'lodash';

const initialState = {
	routeParams: {}
}

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + 'messages';

export const getMessages = createAsyncThunk('messagesApp/messages/getMessages', async (routeParams, { dispatch, getState }) => {
	const response = await axios.get(API_URL);
	const data = await response.data;

	return { data };
});

export const addMessage = createAsyncThunk('messagesApp/messages/addMessage', async (message, { dispatch, getState }) => {
	const response = await axios.post(API_URL, message);
	const data = await response.data;
	dispatch(getMessages());
	return { data };
});

export const updateMessage = createAsyncThunk('messagesApp/messages/updateMessage', async (message, { dispatch, getState }) => {
	const response = await axios.put(API_URL + '/' + message.id, message);
	const data = await response.data;
	dispatch(getMessages());
	return { data };
});

export const removeMessage = createAsyncThunk('messagesApp/messages/removeMessage', async (message_id, { dispatch, getState }) => {
	const response = await axios.delete(API_URL + '/' + message_id);
	const data = await response.data;
	dispatch(getMessages());
	return { data };
});

export const sendMessage = createAsyncThunk('messagesApp/messages/sendMessage', async (message_id, { dispatch, getState }) => {
	const response = await axios.get(API_URL + '/send/' + message_id);
	const data = await response.data;
	dispatch(getMessages());
	return { data };
});

const messagesAdapter = createEntityAdapter({});

export const { selectAll: selectMessages, selectById: selectMessageById } = messagesAdapter.getSelectors(
	state => state.messagesApp.messages
);

const messagesSlice = createSlice({
	name: 'messagesApp/messages',
	initialState: messagesAdapter.getInitialState({
		messageDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		}
	}),
	reducers: {
		openNewMessageDialog: (state, action) => {
			state.messageDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: null
			};
		},
		closeNewMessageDialog: (state, action) => {
			state.messageDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: null
			};
		},
		openEditMessageDialog: (state, action) => {
			state.messageDialog = {
				type: 'edit',
				props: {
					open: true
				},
				data: action.payload
			};
		},
		closeEditMessageDialog: (state, action) => {
			state.messageDialog = {
				type: 'edit',
				props: {
					open: false
				},
				data: null
			};
		}
	},
	extraReducers: {
		[getMessages.fulfilled]: (state, action) => {
			const { data, routeParams } = action.payload;
			messagesAdapter.setAll(state, data);
			state.routeParams = routeParams;
		},
	}
});

export const {
	openNewMessageDialog,
	closeNewMessageDialog,
	openEditMessageDialog,
	closeEditMessageDialog,
} = messagesSlice.actions;

export default messagesSlice.reducer;
