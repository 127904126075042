import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "36px",
  },
  header: {
    backgroundColor: "#F4E1FD",
    marginLeft: "16px",
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
      minHeight: "200px",
      textAlign: "center",
      padding: "6px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
      minHeight: "200px",
      marginBottom: "16px",
      textAlign: "right",
      padding: "16px",
    },
  },
  headerTitle: {
    fontSize: "1.5em",
    fontWeight: "bold",
    color: "#000000",
    marginBottom: "26px",
  },
  avatar: {
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px",
      padding: "6px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100px",
      height: "100px",
      padding: "12px",
    },
    "&:hover": {
      background: "#BDBDBD",
    },
    cursor: "pointer",
  },
  profileBlock: {
    marginLeft: "12px",
  },
  profileBody: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "16px",
  },
}));

const LazyMatchView = () => {
  const classes = useStyles();
  const userDetail = useSelector(
    ({ userAccountApp }) => userAccountApp.user.user
  );
  const matchUser =
    userDetail.role.name.toLowerCase() === "little"
      ? userDetail.match.big
      : userDetail.match.little;
  const matchUserType =
    userDetail.role.name.toLowerCase() === "little" ? "Mentor" : "Mentee";

  return (
    <div
      className={clsx(
        classes.root,
        "flex flex-col items-center w-full justify-center"
      )}
    >
      <div className={clsx(classes.header, "flex flex-row w-full")}>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <span className={classes.headerTitle}>My match</span>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col">
              <Avatar
                alt={matchUser.firstname}
                src={`/assets/images/avatars/${matchUser.avatar.image}`}
                className={classes.avatar}
              />
            </div>
            <div
              className={clsx(
                classes.profileBlock,
                "flex flex-col items-start justify-start"
              )}
            >
              <p className={classes.profileBody}>{matchUserType} user</p>
              <p className={classes.profileBody}>
                {matchUser.firstname} {matchUser.lastname}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LazyMatchView;
