import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import DOMPurify from "dompurify";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles({
  card: {
    maxWidth: "260px",
    width: "260px",
    maxHeight: "246px",
    height: "246px",
    margin: "6px",
    backgroundImage: "url(assets/images/site/documents_panel_white.png)",
    backgroundImageRepeat: "no-repeat",
    padding: "12px",
  },
  docTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "6px",
  },
});

function DocumentCard({ doc }) {
  const classes = useStyles();
  let textContent = doc.text ? doc.text.substr(0, 100) : "";
  if (doc.text && doc.text.length > 100) {
    textContent += "...";
  }

  const handleDocSelection = () => {
    window.open(`assets/uploads/documents/${doc.url}`, "_blank");
  };

  return (
    <div className={clsx(classes.card, "flex flex-col")}>
      <div className="flex flex-row  items-stretch">
        <h4 className={classes.docTitle}>{doc.title}</h4>
      </div>
      <div className="flex flex-row flex-grow">
        <p
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textContent) }}
        ></p>
      </div>
      <div className="flex flex-row w-full flex-grow items-end justify-end">
        <Button
          size="small"
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            handleDocSelection();
          }}
        >
          Download <Icon>download</Icon>
        </Button>
      </div>
    </div>
  );
}

DocumentCard.propTypes = {
  doc: PropTypes.object,
};

export default DocumentCard;
