import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    // minWidth: "100%",
    // width: "calc(100% - 10rem)",
    // minWidth: "calc(100vw - 10rem)",
    // minHeight: "calc(100vh - 10rem)",
  },
}));

function ErrorDialog(props) {
  const {
    open,
    closeDialog,
    title: dialogTitle,
    message: dialogContent,
    ...other
  } = props;

  const classes = useStyles();

  const refreshPage = () => {
    window.location.reload();
  };

  const reconnect = () => {
    const { reInitialiseConnection } = window.socketInstance;
    closeDialog();

    reInitialiseConnection();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xl"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <div className={clsx(classes.modalContent)}>
        <DialogTitle id="confirmation-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent dividers>{dialogContent}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={reconnect} color="primary">
            Reconnect
          </Button>
          <Button onClick={refreshPage} color="primary">
            Refresh
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

ErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default ErrorDialog;
