import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextChatMessage from './TextChatMessage';

const useStyles = makeStyles(theme => ({
  root: {
  },
  wrapper: {
    overflow: 'hidden',
  },
  container: {
    height: '500px',
    minHeight: '500px',
    minWidth: '200px',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  textChatHeader: {
    color: '#521B5F',
    fontWeight: 'bold',
    paddingBottom: '10px'
  },
  textChatLocalTitle: {
    color: '#521B5F',
    fontWeight: 'bold',
  },
  textChatLocalTitle: {
    color: '#521B5F',
    fontWeight: 'bold',
  },
  textChatRemoteTitle: {
    color: '#521B5F',
    fontWeight: 'bold',
  },
  textChatTime: {
    color: '#BDBDBD',
    fontWeight: 'bold',
  },
  textChatMessageBody: {
    color: '#000000',
    fontWeight: 'bold',
  }
}));

const isBig = (userRole) => userRole[0].toLowerCase() === 'big';

function CatchupTextChat({ messages = [], saveTextChatMessage }) {
	const classes = useStyles();
	const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user.data);

  const textChatField = useRef(null);

	return (
			<FuseAnimate animation="transition.fadeIn">
      <>
        {/* chat header */}
        <div className="flex flex-row w-full">
          <h4 className={classes.textChatHeader}>Chat</h4>
        </div>

        {/* messages list */}
        <div className={clsx(classes.wrapper, 'flex flex-row')}>
          <div id="chat-container" className={clsx(classes.container, 'flex flex-col w-full justify-start items-start')}>

            {/* TODO: loop */}
            { messages.map((msg, index) => (
              <TextChatMessage
                user={msg.user}
                created_at={msg.created_at}
                msg={msg.text}
                key={msg.id}
              />
            ))}

            <hr id="chatscrollmarker" />

          </div>
        </div>

        {/* chat form */}
        <div className="flex flex-row">
          <div className="flex flex-col w-full chatform">
            <hr />
            <textarea ref={textChatField} width="190px" rows="2"></textarea>
            <br/><br/>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                saveTextChatMessage(textChatField.current.value);
                textChatField.current.value = '';
              }}>Send</Button>
          </div>
        </div>
      </>
		</FuseAnimate>
	);
}

CatchupTextChat.propTypes = {
  messages: PropTypes.array.isRequired,
	saveTextChatMessage: PropTypes.func.isRequired
};

export default CatchupTextChat;
