import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FuseAnimate from "@fuse/core/FuseAnimate";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import ImageUploader from "react-images-upload";
import history from "@history";
import Typography from "@material-ui/core/Typography";
import ExplorePostSideBar from "./ExplorePostSideBar";
import ExplorePostCommentForm from "./ExplorePostCommentForm";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExplorePostComment from "./ExplorePostComment";
import { selectPostById } from "./store/postsSlice";
import { addPostComment, addImagePostComment } from "./store/commentsSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
      maxWidth: "600px",
    },
  },
  media: {
    height: 140,
  },
  post: {},
  postTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginTop: "16px",
  },
  postImage: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "360px",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "500px",
    },
  },
  categoryIcon: {
    width: 112,
  },
  spacer: {
    minWidth: "20px",
  },
  sidebar: {
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  backBar: {
    backgroundColor: "#BDBDBD",
  },
  backButton: {
    color: "#521B5F",
    fontWeight: "bold",
  },
}));

function ExplorePost() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentPost = useSelector((state) => selectPostById(state, id));
  const user = useSelector(({ auth }) => auth.user.data);

  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [imageUpload, setImageUpload] = useState("");
  const isMobile = useMediaQuery("(max-width:647px)");

  const LazyExploreSidebar = (
    <ExplorePostSideBar
      links={currentPost.postLinks}
      downloads={currentPost.postDownloads}
    />
  );

  const handleImageDialogOpen = () => {
    setIsImageDialogOpen(true);
  };

  const handleImageDialogClose = () => {
    setIsImageDialogOpen(false);
  };

  const handleImageDialogPost = () => {
    setIsImageDialogOpen(false);
    dispatch(
      addImagePostComment({
        match_id: user.match.id,
        user_id: user.id,
        activity_id: currentPost.id,
        imageUpload: imageUpload,
      })
    );
  };

  const handlePostComment = (msg) => {
    if (msg) {
      dispatch(
        addPostComment({
          post_id: currentPost.id,
          match_id: user.match.id,
          user_id: user.id,
          text: msg,
        })
      );
    }
  };

  const goBackToExplore = () => {
    history.push({
      pathname: "/explore/",
    });
  };

  function onImageChange(pictureFiles, pictureDataURLs) {
    setImageUpload(pictureFiles[0]);
  }

  useEffect(() => {});

  return (
    <>
      <FuseAnimate animation="transition.fadeIn">
        <div className="flex flex-col w-full">
          <div className={clsx(classes.backBar, "flex flex-row w-full")}>
            <Button
              className={classes.backButton}
              onClick={(e) => {
                e.stopPropagation();
                goBackToExplore();
              }}
            >
              <Icon>arrow_back_ios</Icon>
              Back to Explore
            </Button>
          </div>

          <div
            className={clsx(
              classes.root,
              "flex flex-row min-h-full w-full items-center justify-items-center sm:w-full md:w-4/5"
            )}
          >
            <div
              className={clsx(
                classes.root,
                "flex flex-col min-h-full items-center justify-items-center sm:w-full md:w-3/5"
              )}
            >
              <div
                className={clsx(
                  classes.post,
                  "flex flex-col flex-shrink justify-items-center min-h-full"
                )}
              >
                <img
                  src={`assets/images/explore/${currentPost.post_type.siteLabel.toLowerCase()}_icon_trimmed.svg`}
                  aria-label={currentPost.post_type.siteLabel}
                  className={classes.categoryIcon}
                />

                <h2 className={classes.postTitle}>{currentPost.title}</h2>

                <div dangerouslySetInnerHTML={{ __html: currentPost.text }} />

                <br />
                <br />

                {currentPost.image && (
                  <div className="flex flex-row items-center justify-items-center">
                    <img
                      className={classes.postImage}
                      src={`assets/uploads/postImages/${currentPost.image.url}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (currentPost.link && currentPost.link.length) {
                          window.open(currentPost.link, "_blank");
                        }
                      }}
                      align="center"
                    />
                  </div>
                )}

                {!isMobile && (
                  <>
                    <br />
                    <br />
                  </>
                )}

                {isMobile && <div>{LazyExploreSidebar}</div>}

                {currentPost.comments.map((comment, index) => (
                  <ExplorePostComment key={index} item={comment} />
                ))}

                <br />
                <br />

                <ExplorePostCommentForm
                  openImageDialog={handleImageDialogOpen}
                  commentHandler={handlePostComment}
                />

                <br />
                <br />
              </div>
            </div>
            {!isMobile && (
              <div
                className={clsx(
                  classes.root,
                  "flex flex-col min-h-full items-center justify-items-center w-1/5"
                )}
              >
                {/* spacer */}
                <div
                  className={clsx(classes.spacer, "flex flex-col items-center")}
                ></div>

                <div
                  className={clsx(classes.sidebar, "flex flex-row flex-shrink")}
                >
                  {LazyExploreSidebar}
                </div>
              </div>
            )}
          </div>
        </div>
      </FuseAnimate>

      <Dialog
        open={isImageDialogOpen}
        onClose={handleImageDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Post an Image Status</DialogTitle>
        <DialogContent>
          <ImageUploader
            withIcon={true}
            buttonText="Choose Image"
            buttonStyles={{
              textAlign: "left",
            }}
            onChange={onImageChange}
            singleImage={true}
            withPreview={true}
            imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
            maxFileSize={5242880}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImageDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleImageDialogPost} color="primary">
            Post
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ExplorePost;
