import AppBar from "@material-ui/core/AppBar";
import { ThemeProvider } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectFooterTheme } from "app/store/fuse/settingsSlice";
import history from "@history";
import CatchupControls from "app/main/apps/catchups/CatchupControls";

function FooterLayout1(props) {
  const footerTheme = useSelector(selectFooterTheme);
  const isCatchupVisible = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupVisible
  );
  const isCatchupBackgrounded = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupBackgrounded
  );
  const path = history.location.pathname;
  let currentBackgroundColor = isCatchupVisible ? "#000000" : "#521B5F";

  useEffect(() => {}, [isCatchupVisible, isCatchupBackgrounded]);

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className="relative z-10 shadow-md"
        color="default"
        style={{ backgroundColor: currentBackgroundColor, color: "#FFFFFF" }}
      >
        <Toolbar className="min-h-48 md:min-h-64 px-8 sm:px-12 py-0 flex items-center overflow-x-auto">
          {!isCatchupVisible && (
            <div className="flex flex-grow flex-shrink-0">
              <img
                src="assets/images/logos/ourspace.logo.white.svg"
                alt="Ourspace Logo"
                style={{ height: "20px" }}
              />
            </div>
          )}

          {(isCatchupVisible || isCatchupBackgrounded) && <CatchupControls />}

          {!isCatchupVisible && (
            <div className="flex flex-grow flex-shrink-0 px-12 justify-end">
              Copyright 2021 Big Brothers Big Sisters Australia Ltd
            </div>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default React.memo(FooterLayout1);
