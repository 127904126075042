import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

const BackupsAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.superuser,
	routes: [
		{
			path: '/apps/backups/:id',
			component: React.lazy(() => import('./BackupsApp'))
		},
		{
			path: '/apps/backups',
			component: () => <Redirect to="/apps/backups/all" />
		}
	]
};

export default BackupsAppConfig;
