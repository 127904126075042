import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import FuseNavHorizontalCollapse from "./horizontal/FuseNavHorizontalCollapse";
import FuseNavHorizontalGroup from "./horizontal/FuseNavHorizontalGroup";
import FuseNavHorizontalItem from "./horizontal/FuseNavHorizontalItem";
import FuseNavHorizontalLink from "./horizontal/FuseNavHorizontalLink";
import FuseNavVerticalCollapse from "./vertical/FuseNavVerticalCollapse";
import FuseNavVerticalGroup from "./vertical/FuseNavVerticalGroup";
import FuseNavVerticalItem from "./vertical/FuseNavVerticalItem";
import FuseNavVerticalLink from "./vertical/FuseNavVerticalLink";
import FuseNavItem, { registerComponent } from "./FuseNavItem";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import history from "@history";
import {
  navbarCloseFolded,
  navbarOpenFolded,
  navbarCloseMobile,
} from "app/store/fuse/navbarSlice";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
/*
Register Fuse Navigation Components
 */
registerComponent("vertical-group", FuseNavVerticalGroup);
registerComponent("vertical-collapse", FuseNavVerticalCollapse);
registerComponent("vertical-item", FuseNavVerticalItem);
registerComponent("vertical-link", FuseNavVerticalLink);
registerComponent("horizontal-group", FuseNavHorizontalGroup);
registerComponent("horizontal-collapse", FuseNavHorizontalCollapse);
registerComponent("horizontal-item", FuseNavHorizontalItem);
registerComponent("horizontal-link", FuseNavHorizontalLink);
registerComponent("vertical-divider", () => <Divider className="my-16" />);
registerComponent("horizontal-divider", () => <Divider className="my-16" />);

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 100,
      width: "100%",
      backgroundColor: "#521B5F",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#521B5F",
    fontFamily: "Montserrat",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(0),
    opacity: 0.5,
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  navigation: {
    "& .list-item": {
      "&:hover": {
        backgroundColor:
          theme.palette.type === "dark"
            ? "rgba(255, 255, 255, 0.05)"
            : "rgba(0,0,0,.04)",
      },
      "&:focus:not(.active)": {
        backgroundColor:
          theme.palette.type === "dark"
            ? "rgba(255, 255, 255, 0.06)"
            : "rgba(0,0,0,.05)",
      },
    },
  },
  verticalNavigation: {
    "&.active-square-list": {
      "& .list-item, & .active.list-item": {
        width: "100%",
        borderRadius: "0",
      },
    },
    "&.dense": {
      "& .list-item": {
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
      },
    },
  },
  horizontalNavigation: {
    "&.active-square-list": {
      "& .list-item": {
        borderRadius: "0",
      },
    },
    "& .list-item": {
      padding: "8px 12px 8px 12px",
      height: 40,
      minHeight: 40,
      "&.level-0": {
        height: 44,
        minHeight: 44,
      },
      "& .list-item-text": {
        padding: "0 0 0 8px",
      },
    },
  },
  "@global": {
    ".popper-navigation-list": {
      "& .list-item": {
        padding: "8px 12px 8px 12px",
        height: 40,
        minHeight: 40,
        "& .list-item-text": {
          padding: "0 0 0 8px",
        },
      },
      "&.dense": {
        "& .list-item": {
          minHeight: 32,
          height: 32,
          "& .list-item-text": {
            padding: "0 0 0 8px",
          },
        },
      },
    },
  },
  indicator: {
    backgroundColor: "#521B5F",
  },
  verticalLinkList: {
    paddingTop: "26px",
    paddingLeft: "36px",
  },
  verticalNavLink: {
    textAlign: "left",
    color: "#000000 !important",
    fontWeight: "bold",
    marginBottom: "12px",
  },
}));

function FuseNavigation(props) {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const { navigation, onChange, layout, active, dense, className } = props;
  const user = useSelector(({ auth }) => auth.user);
  const isCatchupLive = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupLive
  );

  let isOurspaceUser = true;
  if (user && user.role && user.role.length > 0) {
    isOurspaceUser =
      user.role[0].toLowerCase() === "little" ||
      user.role[0].toLowerCase() === "big"
        ? true
        : false;
  } else {
    isOurspaceUser = true;
  }

  const [currentTab, setCurrentTab] = useState(0);
  const isMobile = useMediaQuery("(max-width:647px)");

  const handleChange = (event, newTabId) => {
    event.stopPropagation();
    let newTabIndex = 0;
    switch (newTabId) {
      case "activityFeed": {
        newTabIndex = 0;
        break;
      }
      case "explore": {
        newTabIndex = 1;
        break;
      }
      case "documents": {
        newTabIndex = 2;
        break;
      }
      case "help": {
        newTabIndex = 3;
        break;
      }
    }
    setCurrentTab(newTabIndex);
    onChange(newTabIndex);
  };

  const handleMobileNavChange = (event, newTabId) => {
    event.stopPropagation();

    if (window.socketInstance) {
      const { popoutVideos } = window.socketInstance;
      popoutVideos();
    }

    let theUrl = "";
    switch (newTabId) {
      case 0: {
        theUrl = "/feed";
        break;
      }
      case 1: {
        theUrl = "/explore";
        break;
      }
      case 2: {
        theUrl = "/documentList";
        break;
      }
      case 3: {
        theUrl = "/help";
        break;
      }
    }
    dispatch(navbarCloseFolded());
    dispatch(navbarCloseMobile());
    history.push({
      pathname: theUrl,
    });
  };

  let verticalNav;
  if (isMobile && isOurspaceUser) {
    verticalNav = (
      <div className={clsx(classes.verticalLinkList, "flex flex-col w-full")}>
        <span
          className={clsx(classes.verticalNavLink, "vertical-link")}
          onClick={(e) => {
            handleMobileNavChange(e, 0);
          }}
        >
          Activity Feed
        </span>
        {!isCatchupLive && (
          <span
            className={clsx(classes.verticalNavLink, "vertical-link")}
            onClick={(e) => {
              handleMobileNavChange(e, 1);
            }}
          >
            Explore
          </span>
        )}
        <span
          className={clsx(classes.verticalNavLink, "vertical-link")}
          onClick={(e) => {
            handleMobileNavChange(e, 2);
          }}
        >
          Documents
        </span>
        <span
          className={clsx(classes.verticalNavLink, "vertical-link")}
          onClick={(e) => {
            handleMobileNavChange(e, 3);
          }}
        >
          Help
        </span>
      </div>
    );
  } else {
    verticalNav = (
      <List
        className={clsx(
          "navigation whitespace-nowrap",
          classes.navigation,
          classes.verticalNavigation,
          `active-${active}-list`,
          dense && "dense",
          className
        )}
      >
        {navigation.map((_item) => (
          <FuseNavItem
            key={_item.id}
            type={`vertical-${_item.type}`}
            item={_item}
            nestedLevel={0}
          />
        ))}
      </List>
    );
  }

  const horizontalNav = (
    <>
      <StyledTabs value={currentTab} aria-label="Ourspace Main Menu">
        {navigation.map((_item) => {
          if (isOurspaceUser && _item.auth[0] === "user") {
            return (
              <StyledTab
                className="indicator"
                label={_item.title}
                onClick={(event, item_id) => {
                  event.preventDefault();
                  handleChange(event, _item.id);
                }}
                id={`user-nav-${_item.id}`}
                key={_item.id}
              />
            );
          }
        })}
      </StyledTabs>
      <Typography className={classes.padding} />
    </>
  );

  if (navigation.length > 0) {
    switch (layout) {
      case "horizontal": {
        return horizontalNav;
      }
      case "vertical":
      default: {
        return verticalNav;
      }
    }
  } else {
    return null;
  }
}

FuseNavigation.propTypes = {
  navigation: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

FuseNavigation.defaultProps = {
  layout: "vertical",
};

export default React.memo(FuseNavigation);
