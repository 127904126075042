import { combineReducers } from '@reduxjs/toolkit';
import catchups from './catchupsSlice';
import chatMsgs from './chatMsgsSlice';

const reducer = combineReducers({
	catchups,
	chatMsgs
});

export default reducer;
