const locale = {
	WELCOME_BACK_MESSAGE: 'Welcome Back',
	MY_MATCHES_TITLE: 'My Matches',
	MY_MATCHES_LAST_ACTIVITY: 'Last Activity',
	MY_MATCHES_NEXT_CATCHUP: 'Next Catchup',
	MY_MATCHES_BUTTON_LABEL: 'GO TO MATCHES',
	MATCH_ACTIVITY_TITLE: 'Match Activity',
	MY_MATCHES_USERS: 'Mentor & Mentee',
	MATCH_ACTIVITY_SUBTITLE: 'Activity',
	MATCH_ACTIVITY_LOGINS_TAB_TITLE: 'Logins',
	MATCH_ACTIVITY_CATCHUPS_TAB_TITLE: 'Catchups',
	MATCH_ACTIVITY_LEGEND_JAN: 'JAN',
	MATCH_ACTIVITY_LEGEND_FEB: 'FEB',
	MATCH_ACTIVITY_LEGEND_MAR: 'MAR',
	MATCH_ACTIVITY_LEGEND_APR: 'APR',
	MATCH_ACTIVITY_LEGEND_MAY: 'MAY',
	MATCH_ACTIVITY_LEGEND_JUN: 'JUN',
	MATCH_ACTIVITY_LEGEND_JUL: 'JUL',
	MATCH_ACTIVITY_LEGEND_AUG: 'AUG',
	MATCH_ACTIVITY_LEGEND_SEP: 'SEP',
	MATCH_ACTIVITY_LEGEND_OCT: 'OCT',
	MATCH_ACTIVITY_LEGEND_NOV: 'NOV',
	MATCH_ACTIVITY_LEGEND_DEC: 'DEC',
	MATCH_ACTIVITY_TOOLTIP_LEGEND_LOGINS: 'Logins',
	MATCH_ACTIVITY_TOOLTIP_LEGEND_CATCHUPS: 'Logins',
	CATCHUPS_TITLE: 'Catchups',
	CATCHUPS_USERS: 'Match',
	CATCHUPS_NEXT: 'Start Time',
	CATCHUPS_BUTTON_JOIN: 'IN PROGRESS - JOIN',
	CATCHUPS_BUTTON_MONITOR: 'MONITOR',
	CATCHUPS_BUTTON_LABEL: 'GO TO CATCHUPS',
	FLAGGED_TITLE: 'Flagged Items',
	FLAGGED_HEADER_MATCH: 'Match',
	FLAGGED_HEADER_TYPE: 'Type',
	FLAGGED_HEADER_DATE: 'Date Flagged',
	FLAGGED_BUTTON_LABEL: 'GO TO FLAGGED ITEMS'
};

export default locale;
