import React, { useRef, useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import FuseAnimate from "@fuse/core/FuseAnimate";
import CatchupTextChat from "./CatchupTextChat";
import Drawer from "@material-ui/core/Drawer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "./store";
import history from "@history";
import WhiteBoard from "./whiteboard/WhiteBoard";
import { showMessage } from "app/store/fuse/messageSlice";
import { setIsReturningToCatchup } from "app/store/fuse/settingsSlice";
import {
  getCatchupByMatch,
  setIsTextChatOpen,
  setIsCatchupBackgrounded,
  setCatchupScreenShareOff,
  startCatchup,
  setIsCatchupVisible,
  sendDataMessageToRoom,
  registerCatchupInProgress,
} from "./store/catchupsSlice";
import {
  getTextChatMsgsByCatchup,
  saveTextChatMsg,
  selectChatMsgs,
} from "./store/chatMsgsSlice";
import ErrorDialog from "./ErrorDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      margin: "0px",
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: "26px",
      paddingLeft: "26px",
      marginRight: "26px",
      marginLeft: "26px",
    },
  },
  headerimg: {
    marginBottom: "26px",
  },
  catchupTitle: {
    backgroundColor: "#E3E4E5",
    color: "#797A7B",
    fontWeight: "bold",
    width: "100%",
    padding: "6px",
    marginTop: "26px",
  },
  localVideoWrapper: {
    backgroundColor: "#000000",
    minHeight: "650px",
    overflow: "hidden",
    width: "100%",
    minWidth: "500px",
  },
  localVideo: {
    height: "100% !important",
    minHeight: "650px",
    width: "100%",
    minWidth: "500px",
    // transform: 'scaleX(.5)'
    // transform: 'translateX(-50%)'
  },
  localVideoWrapperFloating: {
    position: "absolute",
    backgroundColor: "#000000",
    overflow: "hidden",
    bottom: "50px",
    left: "50px",
    width: "80px",
    height: "120px",
    zIndex: 10,
  },
  localVideoFloating: {
    height: "120px",
    minHeight: "120px",
    width: "80px",
    minWidth: "80px",
    // transform: 'scaleX(.5)'
    // transform: 'translateX(-50%)'
  },
  remoteVideoWrapper: {
    height: "100%",
    width: "100%",
    backgroundColor: "#000000",
    // marginLeft: '10px',
    minHeight: "650px",
    minWidth: "500px",
  },
  remoteVideo: {
    height: "100%",
    width: "100%",
    // marginRight: '10px',
    minHeight: "650px",
    minWidth: "500px",
  },
  wrapper: {
    width: "100%",
    height: "100%",
    paddingRight: "26px",
    paddingLeft: "26px",
    minHeight: "650px",
  },
  controlBar: {
    backgroundColor: "#000000 !important",
    minHeight: "120px",
    width: "100%",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  imageControl: {
    marginRight: "16px",
    marginLeft: "16px",
    height: "40px",
    width: "40px",
  },
  chatColumn: {
    alignItems: "flex-start",
    verticalAlign: "top",
    height: "100%",
    minHeight: "100%",
    backgroundColor: "#F6F7F9",
    zIndex: 1302,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "100%",
    },

    [theme.breakpoints.up("md")]: {
      minWidth: "300px",
    },
    padding: "8px",
  },
}));

// get video/voice stream

const submitChatMessages = (e) => {
  e.preventDefault();
};

function CatchupsApp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const catchup = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.catchup
  );
  const isCatchupTextChatOpen = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupTextChatOpen
  );
  const whiteBoardDialog = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.whiteBoardDialog
  );
  const socketInstance = window.socketInstance;
  const textChatMessages = useSelector(selectChatMsgs);
  const remote_id =
    user.data.match.big.id === user.data.id
      ? user.data.match.little.id
      : user.data.match.big.id;
  const [matchUserName, setMatchUsername] = useState("");

  const isMobile = useMediaQuery("(max-width:647px)");
  const chatDrawerAnchor = "right";

  const handleIncomingDataMessage = (msg, catchupID) => {
    dispatch(getTextChatMsgsByCatchup(catchupID)).then((result) => {
      scrollToBottomOfChat();
    });
  };

  const registerInProgress = (data) => {
    dispatch(registerCatchupInProgress(data));
  };

  const startConnection = (roomID, catchupID) => {
    const params = {
      catchup_id: catchupID,
      roomID: roomID,
      quality: 12,
      user_id: user.data.id,
      match_id: user.data.match_id,
      remote_id: remote_id,
      isAdmin: false,
      isMobile: isMobile,
      isMonitoring: false,
      handleDataMessage: handleIncomingDataMessage,
      registerInProgress: registerInProgress,
      onScreenShareEnd: handleScreenShareEnd,
    };
    dispatch(startCatchup(params));
  };

  const scrollToBottomOfChat = () => {
    let objDiv = document.getElementById("chatscrollmarker");
    if (objDiv) {
      objDiv.scrollIntoView();
    }
  };

  const saveTextChatMessage = (msg) => {
    const newMsg = {
      fromUser: user,
      catchup_id: catchup.id,
      msg,
    };
    dispatch(saveTextChatMsg(newMsg)).then((result) => {
      dispatch(sendDataMessageToRoom(msg));
      scrollToBottomOfChat();
    });
  };

  useEffect(() => {
    if (!window.socketInstance) {
      dispatch(setIsCatchupVisible(true));
      dispatch(getCatchupByMatch(user.match_id))
        .then((result) => {
          if (
            result.payload &&
            result.payload.data &&
            result.payload.data.error
          ) {
            dispatch(showMessage({ message: result.payload.data.error }));
            history.push({
              pathname: "/feed",
              state: { redirectUrl: history.location.pathname },
            });
          } else {
            const theCatchup = result.payload.data[0];
            if (theCatchup && theCatchup.id) {
              setMatchUsername(
                user.role[0] === "little"
                  ? theCatchup.big.firstname
                  : theCatchup.little.firstname
              );
              dispatch(getTextChatMsgsByCatchup(theCatchup.id)).then(
                (result) => {
                  startConnection(theCatchup.room_id, theCatchup.id);
                  scrollToBottomOfChat();
                }
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      socketInstance.moveVideosToMainPage();
      dispatch(setIsCatchupBackgrounded(false));
      dispatch(setIsReturningToCatchup(false));
    }

    // returned function will be called on component unmount
    return () => {
      // dispatch(hangupCatchup());
      if (window.socketInstance) {
        dispatch(setIsCatchupBackgrounded(true));
      }
    };
  }, []);

  const handleDrawerClose = (e) => {
    e.stopPropagation();
    dispatch(setIsTextChatOpen(false));
  };

  const handleScreenShareEnd = () => {
    dispatch(setCatchupScreenShareOff());
  };

  const LazyCatchupTextChat = (
    <CatchupTextChat
      messages={textChatMessages}
      saveTextChatMessage={saveTextChatMessage}
      handleDrawerClose={handleDrawerClose}
    />
  );

  console.log(whiteBoardDialog.props.open);

  return (
    <React.Fragment>
      <FuseAnimate animation="transition.fadeIn">
        <div id="catchupwrapper" className="flex h-full">
          {/* wrapper column */}
          <div
            className={clsx(
              classes.root,
              "flex flex-col min-h-full w-full items-center justify-center"
            )}
          >
            <br />

            {/* Header */}
            <div
              className={clsx(
                classes.catchupTitle,
                "flex flex-row items-center justify-center"
              )}
            >
              <p>Catchup with {matchUserName}</p>
            </div>

            {/* main body */}
            <div className="flex flex-row min-h-full">
              {/* inner left column */}
              <div className="flex flex-col min-h-full items-center justify-center">
                {/* videos */}
                <div id="room-container" className="flex roomContainer"></div>
              </div>

              {/* inner right column (text chat) */}
              {isCatchupTextChatOpen && !isMobile && (
                <div
                  className={clsx(
                    classes.chatColumn,
                    "flex flex-col h-full min-h-full items-stretch"
                  )}
                >
                  {LazyCatchupTextChat}
                </div>
              )}

              {/* chat drawer for mobile */}
              {isCatchupTextChatOpen && isMobile && (
                <React.Fragment key={chatDrawerAnchor}>
                  <Drawer
                    className={classes.chatColumn}
                    anchor={chatDrawerAnchor}
                    open={isCatchupTextChatOpen}
                  >
                    {LazyCatchupTextChat}
                  </Drawer>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </FuseAnimate>
      {whiteBoardDialog.props.open && <WhiteBoard />}
    </React.Fragment>
  );
}

export default withReducer("catchupsApp", reducer)(CatchupsApp);
