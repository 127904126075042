import { authRoles } from 'app/auth';
import FuseUtils from '@fuse/utils';
import appsConfigs from 'app/main/apps/appsConfigs';
import authRoleExamplesConfigs from 'app/main/auth/authRoleExamplesConfigs';
import CallbackConfig from 'app/main/callback/CallbackConfig';
import LoginConfig from 'app/main/login/LoginConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import RegisterConfig from 'app/main/register/RegisterConfig';
import UserInterfaceConfig from 'app/main/user-interface/UserInterfaceConfig';
import React from 'react';
import { Redirect } from 'react-router-dom';
import ResetPasswordPage from 'app/main/pages/auth/reset-password/ResetPasswordPage';

const routeConfigs = [
	...appsConfigs,
	...pagesConfigs,
	...authRoleExamplesConfigs,
	UserInterfaceConfig,
	LogoutConfig,
	LoginConfig,
	RegisterConfig,
	LogoutConfig,
	CallbackConfig
];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ["admin"]),
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
	{
		path: '/',
		exact: true,
		// component: () => <Redirect to="/apps/ourspace" />
		component: () => <Redirect to="/apps/activityFeed" />
	},
	{
		path: '/feed',
		exact: true,
		component: () => <Redirect to="/apps/ourspace" />
	},
	{
		path: '/admin',
		exact: true,
		component: () => <Redirect to="/apps/dashboards/analytics" />
	},
	{
		path     : '/forgot',
		exact    : true,
		auth     : null,
		component: () => <Redirect to="/pages/auth/forgot-password" />
	},
	{
		path     : '/reset/:id',
		auth     : null,
		component: React.lazy(() => import('app/main/pages/auth/reset-password/ResetPasswordPage'))
	},
	{
		component: () => <Redirect to="/login" />
	}
];

export default routes;
