import { useForm } from "@fuse/hooks";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from "@material-ui/core/Select";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import RichTextEditor from "../components/RichTextEditor";
import {
  removeMessage,
  updateMessage,
  addMessage,
  sendMessage,
  closeNewMessageDialog,
  closeEditMessageDialog,
} from "./store/messagesSlice";
import { getUsers } from "app/main/apps/users/store/usersSlice";
import { useParams } from "react-router-dom";
import withReducer from "../../../store/withReducer";
import reducer from "../users/store";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const dummyBig = {
  id: 0,
  firstname: "Select an unmessageed Big",
  lastname: "User",
  role: {
    id: 0,
    name: "",
  },
};
const dummyLittle = {
  id: 0,
  firstname: "Select an unmessageed Little",
  lastname: "User",
  role: {
    id: 0,
    name: "",
  },
};

let defaultFormState = {
  id: 0,
  sender_id: 0,
  recipient_id: 0,
  subject: "",
  content: "",
  globalTo: "",
  bSent: false,
  bRead: false,
  parent_id: null,
  user_id: 0,
  selectedUsers: [],
};

function MessageDialog(props) {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const classes = useStyles();
  const messageDialog = useSelector(
    ({ messagesApp }) => messagesApp.messages.messageDialog
  );
  const users = useSelector(({ usersApp }) =>
    usersApp.users.ids.map(
      (id) =>
        `${id} : ${usersApp.users.entities[id].firstname} ${usersApp.users.entities[id].lastname}`
    )
  );
  const user = useSelector(({ auth }) => auth.user.data);

  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);
  const routeParams = useParams();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const globalToRef = useRef(null);
  const globalToOptions = [
    "All Bigs, All Littles",
    "All Bigs",
    "All Littles",
    "Selection",
  ];

  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (messageDialog.type === "edit" && messageDialog.data) {
      setForm({
        ...messageDialog.data,
        sender_id: user.id,
      });
    }

    /**
     * Dialog type: 'new'
     */
    if (messageDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...messageDialog.data,
        sender_id: user.id,
        user_id: user.id,
      });
    }
  }, [messageDialog, setForm]);

  useEffect(() => {
    dispatch(getUsers(routeParams));
  }, []);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (messageDialog.props.open) {
      initDialog();
    }
  }, [messageDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return messageDialog.type === "edit"
      ? dispatch(closeEditMessageDialog())
      : dispatch(closeNewMessageDialog());
  }

  function canBeSubmitted() {
    let isValid = true;

    return isValid;
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (messageDialog.type === "new") {
      dispatch(
        addMessage({
          ...form,
          selectedUsers: form.selectedUsers.map((ele) =>
            ele.split(":")[0].trim()
          ),
        })
      );
    } else {
      dispatch(
        updateMessage({
          ...form,
          selectedUsers: form.selectedUsers.map((ele) =>
            ele.split(":")[0].trim()
          ),
        })
      );
    }
    closeComposeDialog();
  }

  function handleRemove() {
    dispatch(removeMessage(form.id));
    closeComposeDialog();
  }

  const handleInputChange = (e) => {
    setInForm("inputValue", e.target.value);
  };

  function handleEditorChange(data) {
    setInForm("content", data);
  }

  function handleRecipientChange(event) {
    setInForm("globalTo", event.target.value);
  }

  function handleUserInput(event, newValue) {
    if (form.selectedUsers.includes(newValue)) {
      setInForm(
        "selectedUsers",
        form.selectedUsers.filter((usr) => usr !== newValue)
      );
    } else {
      setInForm("selectedUsers", [...form.selectedUsers, newValue]);
    }
  }

  function removeSelectedUser(option) {
    setInForm(
      "selectedUsers",
      form.selectedUsers.filter((usr) => usr !== option)
    );
  }

  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      open={messageDialog.props.open}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="md"
    >
      <AppBar position="static" className="shadow-md">
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {messageDialog.type === "new" ? "New Message" : "Edit Message"}
          </Typography>
        </Toolbar>
      </AppBar>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className="flex flex-col md:overflow-hidden"
      >
        <DialogContent classes={{ root: "p-24" }}>
          <div className="flex">
            <div className="min-w-48 pt-20">
              <Icon color="action">group</Icon>
            </div>
            <Select
              id="globalTo"
              name="globalTo"
              ref={globalToRef}
              options={[...globalToOptions]}
              onChange={(event, newValue) => {
                handleRecipientChange(event);
              }}
              value={form.globalTo}
              style={{ width: 300 }}
              // renderInput={(params) => (
              //     <TextField {...params} label="Send To" variant="outlined" />
              // )}
              // onInputCapture={handleInputChange}
            >
              {globalToOptions.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </div>
          {form.globalTo === "Selection" && (
            <div className="flex pt-20">
              <div className="min-w-48 pt-20">
                <Icon color="action">group</Icon>
              </div>
              <Autocomplete
                id="selectedUsers"
                name="selectedUsers"
                ref={globalToRef}
                options={[...users]}
                onChange={(event, newValue) => {
                  handleUserInput(event, newValue);
                }}
                // value={form.globalTo}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Send To" variant="outlined" />
                )}
                onInputCapture={handleInputChange}
              />
            </div>
          )}
          <div className="flex my-16">
            {form.selectedUsers?.map((option) => {
              return (
                <div
                  style={{
                    backgroundColor: "#9C26B0",
                    borderRadius: 20,
                    padding: "5px 20px",
                    color: "#fff",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  key={option}
                  onClick={() => removeSelectedUser(option)}
                >
                  {option} x
                </div>
              );
            })}
          </div>

          {/*<br />*/}
          {/*<br />*/}

          <div className="flex">
            <div className="min-w-48 pt-20">
              <Icon color="action">label</Icon>
            </div>
            <TextField
              className="mb-24"
              label="Subject"
              autoFocus
              id="subject"
              name="subject"
              value={form.subject}
              onChange={handleChange}
              variant="outlined"
              required
              fullWidth
              autoComplete="off"
            />
          </div>

          <div className="flex">
            <div className="min-w-48 pt-20">
              <Icon color="action">article</Icon>
            </div>

            <RichTextEditor
              value={form.content}
              onChange={handleEditorChange}
            />
          </div>
        </DialogContent>

        {messageDialog.type === "new" ? (
          <DialogActions className="justify-between p-8">
            <div className="px-16">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                type="submit"
                disabled={!canBeSubmitted()}
              >
                Add
              </Button>
            </div>
          </DialogActions>
        ) : (
          <DialogActions className="justify-between p-8">
            <div className="px-16">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={!canBeSubmitted()}
              >
                Save
              </Button>
            </div>
            <IconButton onClick={handleRemove}>
              <Icon>delete</Icon>
            </IconButton>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
}

export default withReducer("usersApp", reducer)(MessageDialog);
