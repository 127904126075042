import CalendarAppConfig from './catchupCalendar/CalendarAppConfig';
import UsersAppConfig from './users/UsersAppConfig';
import AnalyticsDashboardAppConfig from './dashboards/analytics/AnalyticsDashboardAppConfig';
import LogsAppConfig from './logs/LogsAppConfig';
import BackupsAppConfig from './backups/BackupsAppConfig';
import FeatureControlsAppConfig from './featureControls/FeatureControlsAppConfig';
import ExplorePostsAppConfig from './explorePosts/ExplorePostsAppConfig';
import DocumentsAppConfig from './documents/DocumentsAppConfig';
import HelpsAppConfig from './helps/HelpsAppConfig';
import LanguagesAppConfig from './languages/LanguagesAppConfig';
import MatchesAppConfig from './matches/MatchesAppConfig';
import ActivityFeedAppConfig from './activityFeed/ActivityFeedAppConfig';
import ExploreAppConfig from './explore/ExploreAppConfig';
import DocumentListAppConfig from './documentList/DocumentListAppConfig';
import HelpAppConfig from './help/HelpAppConfig';
import CatchupsAppConfig from './catchups/CatchupsAppConfig';
import AdminCatchupsAppConfig from './adminCatchups/AdminCatchupsAppConfig';
import UserAccountAppConfig from './userAccount/UserAccountAppConfig';
import UserMessagesAppConfig from './userMessages/UserMessagesAppConfig';
import PostTypesAppConfig from './postTypes/PostTypesAppConfig';
import MatchOwnersAppConfig from './matchOwners/MatchOwnersAppConfig';
import AdminProfileAppConfig from './adminProfile/AdminProfileAppConfig';
import MessagesAppConfig from './messages/MessagesAppConfig';

const appsConfigs = [
	AnalyticsDashboardAppConfig,
	UsersAppConfig,
	CalendarAppConfig,
	LogsAppConfig,
	BackupsAppConfig,
	FeatureControlsAppConfig,
	ExplorePostsAppConfig,
	DocumentsAppConfig,
	HelpsAppConfig,
	LanguagesAppConfig,
	MatchesAppConfig,
	ActivityFeedAppConfig,
	ExploreAppConfig,
	DocumentListAppConfig,
	HelpAppConfig,
	CatchupsAppConfig,
	AdminCatchupsAppConfig,
	UserAccountAppConfig,
	UserMessagesAppConfig,
	PostTypesAppConfig,
	MatchOwnersAppConfig,
	AdminProfileAppConfig,
	MessagesAppConfig
];

export default appsConfigs;
