import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/CameraAlt';
import ActivityFeedItemComment from './ActivityFeedItemComment';
import ActivityFeedItemReplyForm from './ActivityFeedItemReplyForm';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {
  },
  feedItem: {
    minWidth: '200px',
    backgroundColor: '#e8e8e8',
    marginBottom: '16px',
    borderRadius: '5px',
    padding: '6px',
    [theme.breakpoints.down("sm")]: {
      maxWidth: '620px'
    },
    [theme.breakpoints.up("md")]: {
      minWidth: '500px'
    }
  },
  avatar: {
    marginRight: '16px'
  },
  textChatTitle: {
    color: '#521B5F',
    fontWeight: 'bold',
    marginRight: '16px'
  },
  textChatRemoteTitle: {
    fontSize: '12px',
    color: '#BDBDBD',
    fontWeight: 'bold',
    paddingLeft: '6px'
  },
  textChatTime: {
    color: '#BDBDBD',
    fontWeight: 'bold',
    textAlign: 'left'
  },
  textChatMessageBody: {
    color: '#000000',
    fontSize: '12px',
    paddingBottom: '20px',
    marginLeft: '6px',
    marginTop: '16px',
  },
  replyMsgBox: {
    width: '100%',
    borderRadius: '5px',
    color: 'grey',
    fontSize: '16px',
    fontWeight: 'bold',
    padding:  '6px'
  },
  photoButton: {
    marginLeft: '6px',
    marginRight: '6px',
    backgroundColor: '#FFFFFF'
  },
  replyButton: {
    backgroundColor: '#FFFFFF'
  }
}));

function ActivityFeedItem({ item, match, replyHandler, openImageDialog}) {
	const classes = useStyles();
  const currentUser = useSelector(({ auth }) => auth.user.data);
  const currentMatch = useSelector(({ feedApp }) => feedApp.chat.currentMathID);
  const replyMsgRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:647px)');
  const timezone = process.env.REACT_APP_TIMEZONE;

  return (
			<FuseAnimate animation="transition.fadeIn">
				<div className={clsx(classes.feedItem, 'flex flex-col flex-grow sm:w-full md:w-3/4')}>
          <div className="flex flex-row items-center">
            {/* avatar */}
            <div className="flex flex-col ">
              <Avatar alt={item.user.firstname} src={`assets/images/avatars/${item.user.avatar.image}`} className={classes.avatar} />
            </div>
            {/* username */}
            <div className={clsx(classes.textChatTitle, 'flex flex-col items-center justify-items-center')}>
              <span>{item.user.firstname}</span>
            </div>
            {/* time */}
            <div className="flex flex-col">
              <span className={classes.textChatTime}>{moment(item.updated_at).format("hh:mm a")}</span>
            </div>
          </div>
          <div className={clsx(classes.textChatMessageBody, 'flex flex-row flex-grow')}>
            <div className="flex flex-col">
            {/* message body */}
              { item.activity_type_id == 1 && (
                <p>{item.text}</p>
              )}
              { item.activity_type_id == 5 && (
                <img src={`/assets/uploads/postImages/${item.image.url}`} />
              )}
            </div>
          </div>
          <div className="flex flex-row flex-shrink">
            <div className="flex flex-col flex-shrink">
              {item.comments && item.comments.map((comment, index) => (
                <ActivityFeedItemComment
                  replyHandler={replyHandler}
                  key={index}
                  item={comment}
                  match={currentUser.match}
                  showForm={false} />
              ))}
            </div>
          </div>
          <div className={clsx(classes.textChatMessageBody, 'flex flex-row flex-shrink')}>
            <ActivityFeedItemReplyForm
              activity_id={item.id}
              replyHandler={replyHandler}
              openImageDialog={openImageDialog}
            />
          </div>
				</div>
			</FuseAnimate>
	);
}

ActivityFeedItem.propTypes = {
  item: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  replyHandler: PropTypes.func.isRequired,
  openImageDialog: PropTypes.func.isRequired
};

export default ActivityFeedItem;
