import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "../../../../@fuse/hooks";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px",
      padding: "6px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100px",
      height: "100px",
      padding: "12px",
    },
    "&:hover": {
      background: "#BDBDBD",
    },
    cursor: "pointer",
  },
  avatarOn: {
    backgroundColor: "#521B5F",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px",
      padding: "6px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100px",
      height: "100px",
      padding: "12px",
    },
    "&:hover": {
      background: "#BDBDBD",
    },
    cursor: "pointer",
  },
  whiteBlock: {
    backgroundColor: "#FFFFFF",
    padding: "6px",
  },
  subtitle: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "14px",
    marginTop: "12px",
    marginBottom: "12px",
  },
  avatarGrid: {
    backgroundColor: "#FFFFFF",
    padding: "6px",
  },
}));

const LazySettingsForm = ({
  handleSubmit,
  handleAvatarSelection,
  form,
  handleChange,
}) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const avatars = useSelector(
    ({ userAccountApp }) => userAccountApp.avatars.avatars
  );
  const userDetail = useSelector(
    ({ userAccountApp }) => userAccountApp.user.user
  );
  const matchUser =
    userDetail.role.name.toLowerCase() === "little"
      ? userDetail.match.big
      : userDetail.match.little;

  function canBeSubmitted() {
    // if no changes...
    if (form.avatar.id == userDetail.avatar.id) {
      // if password present
      if (
        form.passwordone.length > 0 ||
        form.passwordtwo.length > 0 ||
        form.email.length > 0
      ) {
        if (form.passwordone === form.passwordtwo) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      className="flex flex-col w-full"
    >
      <div className="flex flex-row justify-center">
        <div className="flex flex-col justify-center">
          <div
            className={clsx(
              classes.whiteBlock,
              "flex flex-row items-center md:items-start w-full"
            )}
          >
            <p className={classes.subtitle}>Choose an avatar profile image</p>
          </div>
          <div
            className={clsx(
              classes.whiteBlock,
              "flex flex-row w-full items-center"
            )}
          >
            <img
              className="flex w-full"
              src="assets/images/site/divider_line.svg"
            />
          </div>
          <div
            className={clsx(
              classes.avatarGrid,
              "flex flex-row flex-wrap items-center justify-items-center"
            )}
          >
            {avatars.map((avatar, index) => (
              <Avatar
                key={index}
                alt={matchUser.firstname}
                src={`/assets/images/avatars/${avatar.image}`}
                className={
                  form.avatar.id === avatar.id
                    ? classes.avatarOn
                    : classes.avatar
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleAvatarSelection(avatar);
                }}
              />
            ))}
          </div>

          <div
            className={clsx(
              classes.avatarGrid,
              "flex flex-row items-center justify-items-center"
            )}
          >
            <div className="flex flex-col w-full">
              <div className="flex flex-row w-full">
                <img
                  className="flex w-full"
                  src="assets/images/site/divider_line.svg"
                />
              </div>
              <div
                className={clsx(
                  classes.whiteBlock,
                  "flex flex-row items-center md:items-start w-full"
                )}
              >
                <p className={classes.subtitle}>Change Email</p>
              </div>

              <div className="flex flex-row w-full">
                <div className="min-w-48 pt-20">
                  <Icon color="action">email</Icon>
                </div>
                <TextField
                  className="mb-12"
                  label="New Email"
                  id="email"
                  type="email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </div>

              {/*<div className="flex flex-row w-full">*/}
              {/*  <div className="min-w-48 pt-20">*/}
              {/*    <Icon color="action">lockopen</Icon>*/}
              {/*  </div>*/}
              {/*  <TextField*/}
              {/*    className="mb-12 flex flex-grow"*/}
              {/*    label="Current Password"*/}
              {/*    id="emailPassword"*/}
              {/*    type="password"*/}
              {/*    name="emailPassword"*/}
              {/*    value={form.emailPassword}*/}
              {/*    onChange={handleChange}*/}
              {/*    variant="outlined"*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
          </div>

          <div
            className={clsx(
              classes.avatarGrid,
              "flex flex-row items-center justify-items-center"
            )}
          >
            <div className="flex flex-col w-full">
              <div className="flex flex-row w-full">
                <img
                  className="flex w-full"
                  src="assets/images/site/divider_line.svg"
                />
              </div>
              <div
                className={clsx(
                  classes.whiteBlock,
                  "flex flex-row items-center md:items-start w-full"
                )}
              >
                <p className={classes.subtitle}>Change Password</p>
              </div>

              <div className="flex flex-row w-full">
                <div className="min-w-48 pt-20">
                  <Icon color="action">lockopen</Icon>
                </div>
                <TextField
                  className="mb-12"
                  label="New Password"
                  id="passwordone"
                  type="password"
                  name="passwordone"
                  value={form.passwordone}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  autoComplete={"" + Math.random(1, 1000)}
                />
              </div>

              <div className="flex flex-row w-full">
                <div className="min-w-48 pt-20">
                  <Icon color="action">lockopen</Icon>
                </div>
                <TextField
                  className="mb-12 flex flex-grow"
                  label="Confirm Password"
                  id="passwordtwo"
                  type="password"
                  name="passwordtwo"
                  value={form.passwordtwo}
                  onChange={handleChange}
                  variant="outlined"
                  autoComplete={"" + Math.random(1, 1000)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          classes.whiteBlock,
          "flex flex-row w-full items-end justify-end"
        )}
      >
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit}
          disabled={!canBeSubmitted()}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default LazySettingsForm;
