import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
// import _ from 'lodash';

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + 'matches';

export const getMatchData = createAsyncThunk('feedApp/match/getMatchData', async (match_id, { getState }) => {
	const response = await axios.get(API_URL + '/' + match_id);
	const data = await response.data;

	return { data };
});

const matchSlice = createSlice({
	name: 'feedApp/match',
	initialState: {
		catchups: [],
		currentMatch: {},
		routeParams: {}
	},
	reducers: {
	},
	extraReducers: {
		[getMatchData.fulfilled]: (state, action) => {
			const { data, routeParams } = action.payload;
			state.currentMatch = data;
		}
	}
});

// export const {
// } = matchSlice.actions;

export default matchSlice.reducer;
