import React, { useEffect, useState, useRef } from "react";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import history from "@history";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FuseAnimate from "@fuse/core/FuseAnimate";
import HelpItem from "./HelpItem";
import withReducer from "app/store/withReducer";
import reducer from "./store";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getUserDetail, updateUser } from "./store/userSlice";
import { getUserProjects } from "./store/projectsSlice";
import { getAvatars } from "./store/avatarsSlice";
import Avatar from "@material-ui/core/Avatar";
import ExplorePostCard from "../explore/ExplorePostCard";
import { setUser } from "../../../auth/store/userSlice";
import { showMessage } from "app/store/fuse/messageSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "36px",
  },
  submenu: {
    marginTop: "36px",
    marginLeft: "12px",
    marginRight: "12px",
  },
  header: {
    backgroundColor: "#F4E1FD",
    marginLeft: "16px",
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
      minHeight: "200px",
      textAlign: "center",
      padding: "6px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
      minHeight: "200px",
      marginBottom: "16px",
      textAlign: "right",
      padding: "16px",
    },
  },
  shortHeader: {
    backgroundColor: "#F4E1FD",
    height: "60px",
    minHeight: "60px",
    marginTop: "56px",
    marginBottom: "16px",
    textAlign: "right",
    padding: "16px",
  },
  headerimg: {
    marginBottom: "26px",
  },
  mainContent: {
    // marginLeft: '16px',
    // marginRight: '16px',
  },
  paper: {
    display: "flex",
    width: 56,
  },
  headerTitle: {
    fontSize: "1.5em",
    fontWeight: "bold",
    color: "#000000",
    marginBottom: "26px",
  },
  navHeaderTitle: {
    fontSize: "1.5em",
    fontWeight: "bold",
    color: "#000000",
    [theme.breakpoints.up("md")]: {
      marginLeft: "26px",
    },
  },
  headerBody: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#000000",
  },
  subNavButton: {
    marginBottom: "16px",
    width: "130px",
  },
  avatar: {
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px",
      padding: "6px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100px",
      height: "100px",
      padding: "12px",
    },
    "&:hover": {
      background: "#BDBDBD",
    },
    cursor: "pointer",
  },
  avatarOn: {
    backgroundColor: "#521B5F",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px",
      padding: "6px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100px",
      height: "100px",
      padding: "12px",
    },
    "&:hover": {
      background: "#BDBDBD",
    },
    cursor: "pointer",
  },
  profileBlock: {
    marginLeft: "12px",
  },
  whiteBlock: {
    backgroundColor: "#FFFFFF",
    padding: "6px",
  },
  profileBody: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "16px",
  },
  subtitle: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "14px",
    marginTop: "12px",
    marginBottom: "12px",
  },
  photoGrid: {
    marginLeft: "16px",
    marginRight: "16px",
  },
  avatarGrid: {
    backgroundColor: "#FFFFFF",
    padding: "6px",
  },
  userPhoto: {
    maxWidth: "100px",
    width: "100px",
    display: "flex",
    marginRight: "6px",
    marginBottom: "6px",
  },
  explorePostsButton: {
    marginTop: "16px;",
    marginBottom: "16px;",
  },
}));

const LazyProjects = ({ handlePostSelection }) => {
  const classes = useStyles();
  const userProjects = useSelector(
    ({ userAccountApp }) => userAccountApp.projects.projects
  );

  return (
    <div
      className={clsx(
        classes.photoGrid,
        "flex flex-row min-w-full w-full flex-wrap items-center justify-items-center"
      )}
    >
      {userProjects.map((project, index) => (
        <ExplorePostCard
          key={index}
          post={project}
          handlePostSelection={handlePostSelection}
        />
      ))}
    </div>
  );
};

export default LazyProjects;
