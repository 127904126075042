import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

const LogsAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/apps/logs/:id',
			component: React.lazy(() => import('./LogsApp'))
		},
		{
			path: '/apps/logs',
			component: () => <Redirect to="/apps/logs/all" />
		}
	]
};

export default LogsAppConfig;
