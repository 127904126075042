import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import FuseAnimate from "@fuse/core/FuseAnimate";
import CatchupTextChat from "./CatchupTextChat";
import Drawer from "@material-ui/core/Drawer";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import withReducer from "app/store/withReducer";
import reducer from "./store";
import WhiteBoard from "../catchups/whiteboard/WhiteBoard";
import history from "@history";
import { createSocketConnectionInstance } from "../catchups/Connection";
import { useParams } from "react-router-dom";
import { showMessage } from "../../../store/fuse/messageSlice";
import {
  initiateSiteSocket,
  registerUser,
  deRegisterUser,
  addAdminToCatchupSockets,
  removeAdminFromCatchupSockets,
} from "../../../auth/store/socketSlice";
import {
  getCatchupByMatch,
  setRoomID,
  getRoomID,
  getCatchup,
  startCatchup,
  sendDataMessageToRoom,
  setIsCatchupLive,
  setIsCatchupAudioOn,
  setIsCatchupVideoOn,
  setIsTextChatOpen,
  setIsCatchupVisible,
  toggleCatchupAudio,
  toggleCatchupVideo,
  toggleTextChatOpen,
  hangupCatchup,
  setIsAdminMonitoring,
} from "../catchups/store/catchupsSlice";
import {
  getTextChatMsgsByCatchup,
  saveTextChatMsg,
  selectChatMsgs,
} from "../catchups/store/chatMsgsSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingRight: "26px",
    paddingLeft: "26px",
    marginRight: "26px",
    marginLeft: "26px",
  },
  headerimg: {
    marginBottom: "26px",
  },
  catchupTitle: {
    backgroundColor: "#E3E4E5",
    color: "#797A7B",
    fontWeight: "bold",
    width: "100%",
    padding: "6px",
  },
  localVideoWrapper: {
    backgroundColor: "#000000",
    minHeight: "650px",
    overflow: "hidden",
    width: "100%",
    minWidth: "500px",
  },
  localVideo: {
    height: "100% !important",
    minHeight: "650px",
    width: "100%",
    minWidth: "500px",
    // transform: 'scaleX(.5)'
    // transform: 'translateX(-50%)'
  },
  remoteVideoWrapper: {
    height: "100%",
    width: "100%",
    backgroundColor: "#000000",
    // marginLeft: '10px',
    minHeight: "650px",
    minWidth: "500px",
  },
  remoteVideo: {
    height: "100%",
    width: "100%",
    // marginRight: '10px',
    minHeight: "650px",
    minWidth: "500px",
  },
  wrapper: {
    width: "100%",
    height: "100%",
    paddingRight: "26px",
    paddingLeft: "26px",
    minHeight: "650px",
  },
  controlBar: {
    backgroundColor: "#000000 !important",
    minHeight: "120px",
    width: "100%",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  imageControl: {
    marginRight: "16px",
    marginLeft: "16px",
    height: "40px",
    width: "40px",
  },
  chatColumn: {
    alignItems: "flex-start",
    verticalAlign: "top",
    height: "100%",
    minHeight: "100%",
    zIndex: "1302",
    backgroundColor: "#F6F7F9",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "100%",
    },

    [theme.breakpoints.up("md")]: {
      minWidth: "300px",
    },
    padding: "8px",
  },
}));

// get video/voice stream

const submitChatMessages = (e) => {
  e.preventDefault();
};

function CatchupsApp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector(({ auth }) => auth.user);
  const roomID = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.chatRoomID
  );
  const catchup = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.catchup
  );
  const isCatchupAudioOn = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupAudioOn
  );
  const isCatchupVideoOn = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupVideoOn
  );
  const isCatchupTextChatOpen = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupTextChatOpen
  );
  const isAdminMonitoring = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isAdminMonitoring
  );
  const isCatchupLive = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupLive
  );
  const socketInstance = window.socketInstance;
  const textChatMessages = useSelector(selectChatMsgs);

  const localVideo = useRef(null);
  const localChatOutgoing = useRef(null);
  const remoteVideo = useRef(null);
  const remoteChatIncoming = useRef(null);
  const adminVideo = useRef(null);

  const [mediaType, setMediaType] = useState(false);
  const [matchUserName, setMatchUserName] = useState("");
  const [catchupID, setCatchupID] = useState(0);
  const [matchID, setMatchID] = useState(0);
  const [monitoringMode, setMonitoringMode] = useState(false);

  const isMobile = useMediaQuery("(max-width:647px)");
  const chatDrawerAnchor = "right";

  const handleIncomingDataMessage = (msg, catchup_id) => {
    dispatch(getTextChatMsgsByCatchup(catchup_id)).then((result) => {
      scrollToBottomOfChat();
    });
  };

  useEffect(() => {
    if (isAdminMonitoring) {
      setMonitoringMode(true);
    } else {
      setMonitoringMode(false);
    }
  }, [isAdminMonitoring]);

  const startConnection = (roomID, catchupID, matchID) => {
    const params = {
      catchup_id: catchupID,
      roomID: roomID,
      quality: 12,
      user_id: user.data.id,
      match_id: matchID,
      remote_id: 0,
      isAdmin: true,
      isMobile: isMobile,
      isMonitoring: isAdminMonitoring,
      handleDataMessage: handleIncomingDataMessage,
    };
    if (isAdminMonitoring) {
      dispatch(
        setIsAdminMonitoring({ isMonitoring: true, user_id: user.data.id })
      );
      setMonitoringMode(true);
    }
    dispatch(startCatchup(params));
  };

  const scrollToBottomOfChat = () => {
    let objDiv = document.getElementById("chatscrollmarker");
    if (objDiv) {
      objDiv.scrollIntoView();
    }
  };

  const saveTextChatMessage = (msg) => {
    const newMsg = {
      fromUser: user,
      catchup_id: catchupID,
      msg,
    };
    dispatch(saveTextChatMsg(newMsg)).then((result) => {
      dispatch(sendDataMessageToRoom(msg));
      scrollToBottomOfChat();
    });
  };

  useEffect(() => {
    dispatch(getCatchup(id))
      .then((result) => {
        if (
          result.payload &&
          result.payload.data &&
          result.payload.data.error
        ) {
          dispatch(showMessage({ message: result.payload.data.error }));
          history.push({
            pathname: "/matches",
            state: { redirectUrl: history.location.pathname },
          });
        } else {
          const theCatchup = result.payload.data;
          setMatchUserName(
            `${theCatchup.match.big.firstname} ${theCatchup.match.big.lastname} & ${theCatchup.match.little.firstname} ${theCatchup.match.little.lastname}`
          );
          setCatchupID(theCatchup.id);
          setMatchID(theCatchup.match_id);
          // register the admin for the catchup
          dispatch(
            addAdminToCatchupSockets({
              data: {
                id: user.data.id,
                match_id: theCatchup.match.id,
              },
            })
          ).then((result) => {
            dispatch(getTextChatMsgsByCatchup(theCatchup.id)).then((result) => {
              startConnection(
                theCatchup.room_id,
                theCatchup.id,
                theCatchup.match_id
              );
              scrollToBottomOfChat();
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // returned function will be called on component unmount
    return () => {
      if (window.socketInstance) {
        hangup();
        dispatch(
          removeAdminFromCatchupSockets({
            data: {
              id: user.data.id,
              match_id: matchID,
            },
          })
        );
      }
    };
  }, []);

  const handleDrawerClose = (e) => {
    e.stopPropagation();
    dispatch(setIsTextChatOpen(false));
  };

  const LazyCatchupTextChat = (
    <CatchupTextChat
      messages={textChatMessages}
      saveTextChatMessage={saveTextChatMessage}
      handleDrawerClose={handleDrawerClose}
    />
  );

  const hangup = () => {
    dispatch(hangupCatchup());
  };

  return (
    <React.Fragment>
      <FuseAnimate animation="transition.fadeIn">
        <div id="wrapper" className="flex wrapper ">
          {/* wrapper column */}
          <div
            className={clsx(
              classes.root,
              "flex flex-col w-full items-center justify-center"
            )}
          >
            <br />

            {/* Header */}
            <div
              className={clsx(
                classes.catchupTitle,
                "flex flex-row  items-center justify-center"
              )}
            >
              <p>Catchup with {matchUserName}</p>
            </div>
            {/* Monitoring Notice */}
            {isAdminMonitoring && (
              <div
                className={clsx(
                  classes.catchupTitle,
                  "flex flex-row  items-center justify-center"
                )}
              >
                <p>
                  MONITORING MODE - Big and Little cannot see or hear you and do
                  not know you are in the room
                </p>
              </div>
            )}

            {/* main body */}
            <div className="flex flex-row">
              {/* inner left column */}
              <div className="flex flex-col min-h-full items-center justify-center">
                {/* videos */}
                <div
                  id="room-container"
                  className="flex flex-row  items-center justify-center"
                ></div>
              </div>

              {isCatchupTextChatOpen && !isMobile && (
                <div
                  className={clsx(
                    classes.chatColumn,
                    "flex flex-col h-full min-h-full items-stretch"
                  )}
                >
                  {LazyCatchupTextChat}
                </div>
              )}

              {/* chat drawer for mobile */}
              {isCatchupTextChatOpen && isMobile && (
                <React.Fragment key={chatDrawerAnchor}>
                  <Drawer
                    className={classes.chatColumn}
                    anchor={chatDrawerAnchor}
                    open={isCatchupTextChatOpen}
                  >
                    {LazyCatchupTextChat}
                  </Drawer>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </FuseAnimate>
      <WhiteBoard />
    </React.Fragment>
  );
}

export default withReducer("adminCatchupsApp", reducer)(CatchupsApp);
