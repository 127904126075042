import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

const LanguagesAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.superuser,
	routes: [
		{
			path: '/apps/languages/:id',
			component: React.lazy(() => import('./LanguagesApp'))
		},
		{
			path: '/apps/languages',
			component: () => <Redirect to="/apps/languages/all" />
		}
	]
};

export default LanguagesAppConfig;
