import React from "react";
import { authRoles } from "app/auth";

const CalendarAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.all,
  routes: [
    {
      path: "/apps/catchupCalendar",
      component: React.lazy(() => import("./CalendarApp")),
    },
  ],
};

export default CalendarAppConfig;
