import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

const HelpsAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/apps/helps',
			component: React.lazy(() => import('./HelpsApp'))
		},
	]
};

export default HelpsAppConfig;
