import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import openSocket from "socket.io-client";
import { showMessage } from "app/store/fuse/messageSlice";

const API_URL = process.env.REACT_APP_SERVER_API_URL + "sockets";

const initializeSocketConnection = () => {
  return openSocket.connect(process.env.REACT_APP_WEBSOCKET_ENDPOINT, {
    secure: true,
    reconnection: true,
    rejectUnauthorized: false,
    reconnectionAttempts: 10,
  });
};

export const initiateSiteSocket = createAsyncThunk(
  "auth/socket/initiateSiteSocket",
  async (user, { dispatch }) => {
    window.ourspaceUserSocket = initializeSocketConnection();
    if (user.match_id) {
      // listen for when a the user's match comes online so we can update status
      window.ourspaceUserSocket.on("matchOnline", (data) => {
        dispatch(setMatchOnline(data));
      });
    }
    dispatch(registerUser(user));
    return;
  }
);

export const registerUser = createAsyncThunk(
  "auth/socket/registerUser",
  async (user, { dispatch }) => {
    window.ourspaceUserSocket.emit("userReg", user);
    return;
  }
);

export const addAdminToCatchupSockets = createAsyncThunk(
  "auth/socket/addAdminToCatchupSockets",
  async (adminUser, { dispatch }) => {
    window.ourspaceUserSocket.emit("addAdminToCatchupSockets", adminUser);
    return;
  }
);

export const removeAdminFromCatchupSockets = createAsyncThunk(
  "auth/socket/removeAdminFromCatchupSockets",
  async (adminUser, { dispatch }) => {
    window.ourspaceUserSocket.emit("removeAdminFromCatchupSockets", adminUser);
    return;
  }
);

export const deRegisterUser = createAsyncThunk(
  "auth/socket/deRegisterUser",
  async (user, { dispatch }) => {
    window.ourspaceUserSocket.emit("userDeReg", user);
    return;
  }
);

export const closeSiteSocket = (user) => async (dispatch) => {
  window.ourspaceUserSocket.disconnect();
  window.ourspaceUserSocket = null;
  return;
};

const initialState = {
  socket: null,
  matchOnline: false,
};

const socketSlice = createSlice({
  name: "auth/socket",
  initialState,
  reducers: {
    setMatchOnline: (state, action) => {
      state.matchOnline = action.payload;
    },
  },
  extraReducers: {},
});

export const { setMatchOnline } = socketSlice.actions;

export default socketSlice.reducer;
