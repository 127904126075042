import FuseUtils from "@fuse/utils/FuseUtils";
import axios from "axios";
import jwtDecode from "jwt-decode";
/* eslint-disable camelcase */

// const API_URL = '/api/auth/';
const API_URL = process.env.REACT_APP_SERVER_API_URL;

class JwtService extends FuseUtils.EventEmitter {
  tokenExpiryTimer = null;

  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (err) => {
        const originalConfig = err.config;
        if (err.response && err.response.status) {
          // if the refresh token is expired log the user out
          if (
            err.response.status === 401 &&
            originalConfig.url === `${API_URL}refreshtoken`
          ) {
            this.emit("onAutoLogout", "Login session has expired");
            this.setSession(null);
            return;
          } else if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if the access token is rejected and it isn't already refreshing
            // refresh the token

            originalConfig.__isRetryRequest = true;
            // refresh the token

            return this.requestNewAuthToken(this.getRefreshToken()).then(
              (res) => {
                return axios({
                  ...originalConfig,
                  headers: {
                    ...originalConfig.headers,
                    Authorization: `Bearer ${res.authToken}`,
                  },
                });
              }
            );
          } else if (
            err.response.status === 401 &&
            err.config &&
            err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Login session has expired");
            this.setSession(null);
          } else if (err.response.status === 403) {
            if (err.response.data.bRedirect) {
              this.emit("onNoMatch", err.response.data.msg);
            } else {
              this.emit(
                "onForbidden",
                "You do not have permission to access this function"
              );
            }
            // this.setSession(null);
            // } else if (err.response.status === 500 || err.response.status === 504) {
          } else if (err.response.status === 500) {
            this.emit("onServerError", err);
          } else {
            console.log({ err });
            // this.emit('onAutoLogout', err);
          }
        }
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();
    const refresh_token = this.getRefreshToken();
    if (!access_token) {
      this.emit("onNoAccessToken");
      return;
    }

    this.requestNewAuthToken(this.getRefreshToken()).then((response) => {
      if (this.isAuthTokenValid(response.authToken)) {
        this.setSession(response.authToken, response.refreshToken);
        // this.restartSessionTimer();
        this.emit("onAutoLogin", true);
      } else {
        this.setSession(null);
        this.emit("onAutoLogout", "Login Session Expired");
      }
    });
  };

  restartSessionTimer = () => {
    if (this.tokenExpiryTimer) {
      clearInterval(this.tokenExpiryTimer);
      this.tokenExpiryTimer = null;
    }
    this.tokenExpiryTimer = setInterval(() => {
      this.requestNewAuthToken(this.getRefreshToken())
        .then(() => {
          this.restartSessionTimer();
        })
        .catch((err) => {
          console.log("refresh error", err);
        });
    }, process.env.REACT_APP_TOKEN_EXPIRY_MILLISECONDS);
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(API_URL + "register", data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.authToken, response.data.refreshToken);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  requestNewAuthToken = (refresh_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "refreshtoken", { refreshtoken: refresh_token })
        .then((response) => {
          if (response.data.authToken) {
            this.setSession(
              response.data.authToken,
              response.data.refreshToken
            );
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((err) => {
          this.emit("onAutoLogout", "Login session has expired");
          this.setSession(null);
        });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "authenticate", {
          email,
          password,
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(
              response.data.authToken,
              response.data.refreshToken
            );
            // this.restartSessionTimer();
            this.emit("manualLogin", response.data.user);
            resolve(response.data.user);
          } else {
            this.emit("onAutoLogout", response.data);
            reject(response.data.error);
          }
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "checktoken", {
          data: {
            access_token: this.getAccessToken(),
          },
        })
        .then((response) => {
          if (response.data) {
            this.setSession(this.getAccessToken(), this.getRefreshToken());
            resolve(response.data);
          } else {
            this.logout();
            reject(new Error("Failed to login with token."));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error("Failed to login with token."));
        });
    });
  };

  updateUserData = (user) => {
    return axios.post(API_URL + "user/update", {
      user,
    });
  };

  setSession = (access_token, refresh_token) => {
    if (access_token) {
      // start the expiry time
      localStorage.setItem("jwt_access_token", access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common.Authorization;
    }
    if (refresh_token) {
      localStorage.setItem("jwt_refresh_token", refresh_token);
    } else {
      localStorage.removeItem("jwt_refresh_token");
    }
  };

  logout = () => {
    // clearInterval(this.tokenExpiryTimer);

    this.setSession(null);
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };
  getRefreshToken = () => {
    return window.localStorage.getItem("jwt_refresh_token");
  };
}

const instance = new JwtService();

export default instance;
