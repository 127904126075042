import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  card: {
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      minWidth: '500px'
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: '260px',
      width: '260px',
    },
    maxHeight: '246px',
    height: '246px',
    margin: '6px',
    backgroundImage: 'url(assets/images/site/documents_panel_white.png)',
    backgroundImageRepeat: 'no-repeat',
    padding: '12px'
  },
  itemTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '26px',
    marginBottom: '6px'
  }
}));


function HelpItem({ item }) {
  const classes = useStyles();
  let textContent = item.text ? item.text.substr(0, 100) : '';
  if (item.text && item.text.length > 100) {
    textContent += '...';
  }

  return (
    <div className={clsx(classes.row, 'flex flex-col')}>
      <div className="flex flex-row  items-stretch">
        <h4 className={classes.itemTitle}>{item.title}</h4>
      </div>
      <div className="flex flex-row flex-grow">
        <div dangerouslySetInnerHTML={{ __html: item.text }} />
      </div>
    </div>
  );
}

HelpItem.propTypes = {
  item: PropTypes.object,
}

export default HelpItem;
