import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
// import _ from 'lodash';

const initialState = {
	routeParams: {},
	avatars: []
}

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + 'avatars';

export const getAvatars = createAsyncThunk('adminProfileApp/avatars/getAvatars', async () => {
	const response = await axios.get(API_URL);
	const data = await response.data;

	return { data };
});

const avatarsSlice = createSlice({
	name: 'adminProfileApp/avatars',
	initialState: initialState,
	reducers: {
	},
	extraReducers: {
		[getAvatars.fulfilled]: (state, action) => {
			const { data } = action.payload;
			state.avatars = data;
		},
	}
});

// export const {
// } = avatarsSlice.actions;

export default avatarsSlice.reducer;
