import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const RichTextEditor = (props) => {
  const { value: contentValue, onChange } = props;

  const getValueAsString = (value) => {
    return value.toString('html')
  }

  const getInitialState = (RichTextEditor) => { value: RichTextEditor.createEmptyValue() }

  return (
    <CKEditor
      editor={ ClassicEditor }
      data={contentValue}
      onReady={ editor => {
          // You can store the "editor" and use when it is needed.
          console.log( 'Editor is ready to use!', editor );
      } }
      onChange={ ( event, editor ) => {
          const data = editor.getData();
          onChange(data)
      } }
      onBlur={ ( event, editor ) => {
          // console.log( 'Blur.', editor );
      } }
      onFocus={ ( event, editor ) => {
          // console.log( 'Focus.', editor );
      } }
    />
  )
}

RichTextEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default RichTextEditor;
