import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

const PostTypesAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.superuser,
	routes: [
		{
			path: '/apps/postTypes/:id',
			component: React.lazy(() => import('./PostTypesApp'))
		},
		{
			path: '/apps/postTypes',
			component: () => <Redirect to="/apps/postTypes/all" />
		}
	]
};

export default PostTypesAppConfig;
