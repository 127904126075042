import FuseShortcuts from "@fuse/core/FuseShortcuts";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Logo from "app/fuse-layouts/shared-components/Logo";
// import ChatPanelToggleButton from 'app/fuse-layouts/shared-components/chatPanel/ChatPanelToggleButton';
import NavbarMobileToggleButton from "app/fuse-layouts/shared-components/NavbarMobileToggleButton";
// import QuickPanelToggleButton from 'app/fuse-layouts/shared-components/quickPanel/QuickPanelToggleButton';
import UserMenu from "app/fuse-layouts/shared-components/UserMenu";
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectToolbarTheme } from "app/store/fuse/settingsSlice";
import FullScreenToggle from "../../shared-components/FullScreenToggle";
import LanguageSwitcher from "../../shared-components/LanguageSwitcher";
import OurspaceUserNavigation from "app/fuse-layouts/shared-components/OurspaceUserNavigation";
import _ from "lodash";
import history from "@history";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";

// import { logoutUser } from '../../../auth/store/userSlice';
import jwtService from "app/services/jwtService";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:
      process.env.REACT_APP_ENV === "production"
        ? "#ffffff"
        : "#34b233 !important",
  },
  mailBadge: {
    marginRight: "12px",
    color: "#8e8989",
    cursor: "pointer",
  },
}));

function ToolbarLayout1(props) {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const user = useSelector(({ auth }) => auth.user);
  const featureControlsMap = useSelector(
    ({ fuse }) => fuse.settings.featureControls
  );
  const isReturningToCatchup = useSelector(
    ({ fuse }) => fuse.settings.isReturningToCatchup
  );

  const [isLanguageSwitchEnabled, setIsLanguageSwitchEnabled] = useState(false);

  let isOurspaceUser = true;
  if (user && user.role && user.role.length > 0) {
    isOurspaceUser =
      user.role[0].toLowerCase() === "little" ||
      user.role[0].toLowerCase() === "big"
        ? true
        : false;
  } else {
    isOurspaceUser = true;
  }

  const isMobile = useMediaQuery("(max-width:647px)");

  const handleOurspaceNavChange = (tabId) => {
    let theUrl = "";

    if (window.socketInstance) {
      const { popoutVideos } = window.socketInstance;
      popoutVideos();
    }
    // dispatch(setCurrentTab(tabId));
    switch (tabId) {
      case 0: {
        theUrl = "/feed";
        break;
      }
      case 1: {
        theUrl = "/explore";
        break;
      }
      case 2: {
        theUrl = "/documentList";
        break;
      }
      case 3: {
        theUrl = "/help";
        break;
      }
    }
    history.push({
      pathname: theUrl,
    });
  };

  const handleMailRedirect = () => {
    let theUrl;
    if (isOurspaceUser) {
      theUrl = "/userMessages";
    } else {
      theUrl = "/apps/messages";
    }
    history.push({
      pathname: theUrl,
    });
  };

  useEffect(() => {
    if (featureControlsMap) {
      setIsLanguageSwitchEnabled(featureControlsMap.languageSwitcher.isEnabled);
    }
  }, [featureControlsMap]);

  useEffect(() => {
    if (isReturningToCatchup) {
      history.push({
        pathname: "/catchup",
      });
    }
  }, [isReturningToCatchup]);

  return (
    <ThemeProvider theme={toolbarTheme}>
      {history.location.pathname.substr(0, 6) != "/reset" &&
        history.location.pathname != "/pages/errors/error-500" && (
          <AppBar
            id="fuse-toolbar"
            className={clsx(classes.root, "flex relative z-10 shadow-md")}
            color="red"
            style={{ backgroundColor: toolbarTheme.palette.background.paper }}
          >
            <Toolbar className="p-0 min-h-48 md:min-h-64 items-center">
              {config.navbar.display &&
                config.navbar.position === "left" &&
                ((isMobile && isOurspaceUser) || !isOurspaceUser) && (
                  <Hidden lgUp>
                    <NavbarMobileToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                  </Hidden>
                )}

              <div className="flex flex-1 mx-16">
                <Logo />
              </div>
              {isOurspaceUser && !isMobile && (
                <div className="flex flex-1 mx-4"></div>
              )}
              {isOurspaceUser && !isMobile && (
                <div className="flex flex-1 items-center">
                  <OurspaceUserNavigation
                    className="px-16"
                    handleChange={handleOurspaceNavChange}
                  />
                </div>
              )}

              {isOurspaceUser && !isMobile && (
                <div className="flex flex-1 mx-4"></div>
              )}

              {/* !isOurspaceUser && (
						<div className="flex flex-1">
							<Hidden mdDown>
								<FuseShortcuts className="px-16" />
							</Hidden>
						</div>
					)*/}

              <div className="flex items-center px-8">
                {isLanguageSwitchEnabled && <LanguageSwitcher />}

                <FullScreenToggle />

                <Badge
                  className={classes.mailBadge}
                  badgeContent={user.data.unreadMessageCount}
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMailRedirect();
                  }}
                >
                  <MailIcon className={classes.mailIcon} />
                </Badge>

                <UserMenu />
              </div>

              {config.navbar.display && config.navbar.position === "right" && (
                <Hidden lgUp>
                  <NavbarMobileToggleButton />
                </Hidden>
              )}
            </Toolbar>
          </AppBar>
        )}
    </ThemeProvider>
  );
}

export default React.memo(ToolbarLayout1);
