import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import history from "@history";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  photoGrid: {
    marginLeft: "16px",
    marginRight: "16px",
  },
  userPhoto: {
    maxWidth: "100px",
    width: "100px",
    display: "flex",
    marginRight: "6px",
    marginBottom: "6px",
  },
}));

const LazyProfilePhotos = () => {
  const classes = useStyles();
  const userDetail = useSelector(
    ({ userAccountApp }) => userAccountApp.user.user
  );

  return (
    <div className="flex flex-row w-full min-w-full">
      <div
        className={clsx(
          classes.photoGrid,
          "flex flex-row w-full flex-wrap w-full"
        )}
      >
        {userDetail.images.map((image, index) => (
          <img
            key={index}
            className={classes.userPhoto}
            src={`assets/images/uploads/postImages/${image.url}`}
          />
        ))}
      </div>
    </div>
  );
};

export default LazyProfilePhotos;
