import React from 'react';
import PropTypes from 'prop-types';
import FuseAnimate from '@fuse/core/FuseAnimate';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';

function MessagesHeader({ match_id, changeTab, currentTabIndex }) {
	const dispatch = useDispatch();
	const mainTheme = useSelector(selectMainTheme);

	return (
		<div className="flex flex-1 items-center justify-between p-4 sm:p-24">
			<div className="flex flex-col w-full">
				<div className="flex flex-1 flex-grow items-center w-full">
					<div className="flex items-center">
						<FuseAnimate animation="transition.expandIn" delay={300}>
							<Icon className="text-32">email</Icon>
						</FuseAnimate>
						<FuseAnimate animation="transition.slideLeftIn" delay={300}>
							<Typography variant="h6" className=" hidden sm:flex">
								&nbsp;&nbsp;Messages
							</Typography>
						</FuseAnimate>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MessagesHeader;
