import React from 'react';
import { authRoles } from 'app/auth';
import ExploreApp from './ExploreApp';
import ExplorePost from './ExplorePost';

const ExploreAppConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: true,
					position: 'left'
				},
				toolbar: {
					display: true
				},
				footer: {
					display: true,
					style: 'fixed',
					position: 'below'
				},
				leftSidePanel: {
					display: true
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/explore/post/:id',
			component: React.lazy(() => import('./ExplorePost'))
		},
		{
			path: '/explore',
			component: ExploreApp
		},

	]
};

export default ExploreAppConfig;
