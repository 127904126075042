import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import history from "@history";
import { getCatchupByMatch as getCatchupByMatchAdmin } from "../../matches/store/catchupsSlice";
// import _ from 'lodash';

const quality = 100;
const initialState = {
  chatRoomID: "defaultRoomID",
  routeParams: {},
  catchup: {},
  chatMsgs: [],
  isCatchupVisible: false,
  isCatchupAudioOn: false,
  isCatchupVideoOn: false,
  isCatchupTextChatOpen: false,
  isCatchupLive: false,
  isAdminMonitoring: false,
};

const API_URL = process.env.REACT_APP_SERVER_API_URL + "catchups";

export const getCatchupByMatch = createAsyncThunk(
  "feedApp/chat/getCatchupByMatch",
  async (match_id) => {
    const response = await axios.get(API_URL + "/getBriefByMatch/" + match_id);
    let data = await response.data;

    return { data };
  }
);

const catchupsSlice = createSlice({
  name: "feedApp/chat",
  initialState: initialState,
  extraReducers: {
    [getCatchupByMatch.fulfilled]: (state, action) => {
      const { data } = action.payload;

      if (data && data.length && data.length > 0) {
        state.catchup = data[0];
        state.chatRoomID = data[0].room_id;
      }
    },
  },
});

// export const {
// } = catchupsSlice.actions;

export default catchupsSlice.reducer;
