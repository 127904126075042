import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import history from "@history";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FuseAnimate from "@fuse/core/FuseAnimate";
import withReducer from "app/store/withReducer";
import reducer from "./store";
import MessagesListSidebar from "./MessagesListSidebar";
import {
  selectMessages,
  getUserMessages,
  markMessageAsRead,
  removeMessage,
} from "./store/messagesSlice";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    backgroundImage:
      "url(assets/images/backgrounds/documents_photo_desktop.jpg)",
    width: "100%",
    minWidth: "100%",
    height: "400px",
    minHeight: "400px",
    marginBottom: "16px",
    textAlign: "right",
  },
  headerimg: {
    marginBottom: "26px",
  },
  paper: {
    display: "flex",
    width: 56,
  },
  headerTitle: {
    fontSize: "3.5em",
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  headerSubTitle: {
    fontSize: "2em",
    fontWeight: "regular",
    color: "#FFFFFF",
  },
  spacer: {
    minWidth: "20px",
  },
  subject: {
    fontWeight: "bold",
    marginBottom: "0px",
  },
  metadata: {
    fontSize: "12px",
    color: "#807c7c",
  },
  messageBody: {
    marginTop: "26px",
  },
  currentMessage: {
    marginRight: "26px",
    padding: "12px",
    backgroundColor: "#FFFFFF",
    paddingBottom: "38px",
    marginTop: "26px",
    width: "500px",
  },
  deleteMessageButton: {
    backgroundColor: "rgb(180, 176, 179)",
    maxWidth: "120px",
    fontSize: "10px",
    fontWeight: "bold",
    padding: "3px",
    maxHeight: "30px",
  },
  noneMessage: {
    fontSize: "32px",
    fontWeight: "bold",
    marginTop: "36px",
    marginRight: "50px",
    textAlign: "left",
  },
}));

function UserMessagesApp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const messages = useSelector(selectMessages);
  const user = useSelector(({ auth }) => auth.user.data);

  const [currentMessage, setCurrentMessage] = useState(messages[0]);
  const isMobile = useMediaQuery("(max-width:647px)");

  const markAsRead = (message_id) => {
    dispatch(
      markMessageAsRead({
        user_id: user.id,
        message_id: message_id,
      })
    );
  };

  const retrieveMessages = () => {
    dispatch(getUserMessages(user.id)).then((_messages) => {
      if (
        _messages.payload.data &&
        _messages.payload.data.length &&
        _messages.payload.data > 0
      ) {
        markAsRead(_messages.payload.data[0].id);
      }
    });
  };

  useEffect(() => {
    retrieveMessages();
  }, []);

  const handleSelectMessage = (message) => {
    setCurrentMessage(message);
    markAsRead(message.id);
  };

  const handleDeleteMessage = () => {
    dispatch(
      removeMessage({
        user_id: user.id,
        message_id: currentMessage.id,
      })
    ).then(() => {
      if (!messages || !messages.length || messages.length === 0) {
        setCurrentMessage(null);
      }
    });
  };

  const LazyMessagesListSidebar = () => (
    <MessagesListSidebar
      messages={messages}
      selectMessage={handleSelectMessage}
    />
  );

  return (
    <div className="flex flex-col min-h-full w-full items-center justify-items-center ${classes.root}">
      <FuseAnimate animation="transition.fadeIn">
        <>
          {/* body Row */}
          <div className="flex flex-row justify-items-center ">
            <div
              className={clsx(
                classes.feed,
                "flex flex-col min-h-full sm:items-center md:items-end w-full"
              )}
            >
              {currentMessage && messages && messages.length > 0 && (
                <div
                  className={clsx(
                    classes.currentMessage,
                    "flex flex-col w-full"
                  )}
                >
                  <div className="flex flex-row w-full items-end justify-end">
                    <Button
                      size="small"
                      variant="contained"
                      className={classes.deleteMessageButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteMessage();
                      }}
                    >
                      Delete Message
                    </Button>
                  </div>
                  <div className="flex flex-row w-full items-stretch">
                    <h1 className={classes.subject}>
                      {currentMessage.subject}
                    </h1>
                  </div>
                  <div className="flex flex-row">
                    <p className={classes.metadata}>
                      Sent:{" "}
                      {moment(currentMessage.created_at).format(
                        "dddd MMM Do HH:ss a"
                      )}
                      <br />
                      From:{" "}
                      {currentMessage.sender.firstname +
                        " " +
                        currentMessage.sender.lastname}
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <p
                      className={classes.messageBody}
                      dangerouslySetInnerHTML={{
                        __html: currentMessage.content,
                      }}
                    />
                  </div>
                  <div className="flex flex-row"></div>
                </div>
              )}

              {(!messages || !messages.length || messages.length === 0) && (
                <h1 className={classes.noneMessage}>No Messages</h1>
              )}
            </div>

            {!isMobile && (
              <div className="flex flex-col items-start justify-start w-2/5">
                {LazyMessagesListSidebar()}
              </div>
            )}
          </div>

          {isMobile && (
            <div className="flex flex-row items-center justify-center w-full">
              {LazyMessagesListSidebar()}
            </div>
          )}
        </>
      </FuseAnimate>
    </div>
  );
}

export default withReducer("userMessagesApp", reducer)(UserMessagesApp);
