import FuseAnimate from "@fuse/core/FuseAnimate";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { darken } from "@material-ui/core/styles/colorManipulator";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import JWTLoginTab from "./tabs/JWTLoginTab";

const ourspacePurple = "#521B5F";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      process.env.REACT_APP_ENV !== "production"
        ? `#fff`
        : `linear-gradient(to left, ${ourspacePurple} 0%, ${darken(
            ourspacePurple,
            0.5
          )} 100%)}`,
    color: theme.palette.primary.contrastText,
  },
  leftSection: {},
  rightSection: {
    background: `linear-gradient(to right, ${ourspacePurple} 0%, ${darken(
      ourspacePurple,
      0.5
    )} 100%)`,
    color: theme.palette.primary.contrastText,
  },
  stagingTitle: {
    position: "fixed",
    top: "16px",
    left: "50%",
    transform: "translate(-50%)",
    color: "red",
    fontSize: "32px",
  },
}));

function Login() {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        "flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24"
      )}
    >
      {process.env.REACT_APP_ENV !== "production" && (
        <div className={classes.stagingTitle}>
          WARNING: This is the staging environment
        </div>
      )}
      <FuseAnimate animation="transition.expandIn">
        <div className="flex w-full max-w-400 md:max-w-3xl rounded-12 shadow-2xl overflow-hidden">
          <Card
            className={clsx(
              classes.leftSection,
              "flex flex-col w-full max-w-sm items-center justify-center shadow-0"
            )}
            square
          >
            <CardContent className="flex flex-col items-center justify-center w-full py-96 max-w-320">
              <JWTLoginTab />
            </CardContent>

            <div className="flex flex-col items-center justify-center pb-32">
              <div>
                <Link className="font-medium" to="/forgot">
                  Forgot your password?
                </Link>
              </div>
            </div>
          </Card>

          <div
            className={clsx(
              classes.rightSection,
              "hidden md:flex flex-1 items-center justify-center p-64"
            )}
          >
            <div className="max-w-320">
              <FuseAnimate animation="transition.slideUpIn" delay={400}>
                <Typography
                  variant="h4"
                  color="inherit"
                  className="font-800 leading-tight"
                >
                  Welcome to <br />
                  <br />
                  <img
                    src="assets/images/logos/ourspace_logo.svg"
                    alt="OurSpace"
                    title="OurSpace"
                  />
                </Typography>
              </FuseAnimate>

              <FuseAnimate delay={500}>
                <Typography
                  variant="subtitle1"
                  color="inherit"
                  className="mt-32"
                >
                  The world's leading E-Mentoring platform
                </Typography>
              </FuseAnimate>
            </div>
          </div>
        </div>
      </FuseAnimate>
    </div>
  );
}

export default Login;
