import { combineReducers } from '@reduxjs/toolkit';
import users from './usersSlice';
import roles from './rolesSlice';
import matches from './matchesSlice';
import user from './userSlice';

const reducer = combineReducers({
	users,
	user,
	matches,
	roles
});

export default reducer;
