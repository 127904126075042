import { combineReducers } from '@reduxjs/toolkit';
import user from './userSlice';
import projects from './projectsSlice';
import avatars from './avatarsSlice';

const reducer = combineReducers({
	user,
	projects,
	avatars
});

export default reducer;
