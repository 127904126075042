import { combineReducers } from '@reduxjs/toolkit';
import nav from './navSlice';
import chat from './chatSlice';
import chatMsgs from './chatMsgsSlice';
import comments from './commentsSlice';
import match from './matchSlice';
import posts from './postsSlice';
import postTypes from './postTypesSlice';

const reducer = combineReducers({
	nav,
	chat,
	chatMsgs,
	comments,
	match,
	posts,
	postTypes
});

export default reducer;
