import React from 'react';
import { authRoles } from 'app/auth';
import UserMessagesApp from './UserMessagesApp';

const UserMessagesAppConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: true,
					position: 'left'
				},
				toolbar: {
					display: true
				},
				footer: {
					display: true,
					style: 'fixed',
					position: 'below'
				},
				leftSidePanel: {
					display: true
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/userMessages',
			component: UserMessagesApp
		},
	]
};

export default UserMessagesAppConfig;
