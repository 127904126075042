import React, { useEffect, useState, useRef } from "react";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import history from "@history";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FuseAnimate from "@fuse/core/FuseAnimate";
import withReducer from "app/store/withReducer";
import reducer from "./store";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getUserDetail, updateUser } from "./store/userSlice";
import { getAvatars } from "./store/avatarsSlice";
import Avatar from "@material-ui/core/Avatar";
import { setUser } from "../../../auth/store/userSlice";
import { showMessage } from "app/store/fuse/messageSlice";

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    backgroundColor: "rgb(109, 27, 123)",
    height: "150px",
    minHeight: "150px",
    marginBottom: "16px",
    padding: "16px",
    color: "#FFFFFF",
  },
  paper: {
    display: "flex",
    width: 56,
  },
  headerTitle: {
    fontSize: "1.5em",
    fontWeight: "bold",
    color: "#000000",
    marginBottom: "26px",
  },
  navHeaderTitle: {
    fontSize: "1.5em",
    fontWeight: "bold",
    color: "#000000",
    marginBottom: "36px",
  },
  headerBody: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#000000",
  },
  subNavButton: {
    marginBottom: "16px",
    width: "130px",
  },
  avatar: {
    backgroundColor: "#FFFFFF",
    width: "100px",
    height: "100px",
    padding: "12px",
    "&:hover": {
      background: "#BDBDBD",
    },
    cursor: "pointer",
  },
  avatarOn: {
    backgroundColor: "#521B5F",
    width: "100px",
    height: "100px",
    padding: "12px",
    "&:hover": {
      background: "#BDBDBD",
    },
    cursor: "pointer",
  },
  profileBlock: {
    marginLeft: "12px",
  },
  whiteBlock: {
    backgroundColor: "#FFFFFF",
    padding: "6px",
    width: "650px",
    maxWidth: "650px",
  },
  profileBody: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "16px",
  },
  subtitle: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "14px",
    marginTop: "12px",
    marginBottom: "12px",
  },
  photoGrid: {
    width: "650px",
    maxWidth: "650px",
  },
  avatarGrid: {
    backgroundColor: "#FFFFFF",
    padding: "6px",
    width: "650px",
    maxWidth: "650px",
  },
  userPhoto: {
    maxWidth: "100px",
    width: "100px",
    display: "flex",
    marginRight: "6px",
    marginBottom: "6px",
  },
}));

function AdminProfileApp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user.data);
  const userDetail = useSelector(
    ({ adminProfileApp }) => adminProfileApp.user.user
  );
  const avatars = useSelector(
    ({ adminProfileApp }) => adminProfileApp.avatars.avatars
  );
  const isMobile = useMediaQuery("(max-width:647px)");
  const avatarImagePath = "/assets/images/avatars/" + userDetail.avatar.image;
  const [currentView, setCurrentView] = useState(0);
  const defaultFormState = {
    id: userDetail.id,
    avatar: userDetail.avatar,
    email: "",
    passwordone: "",
    passwordtwo: "",
  };
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const handleAvatarSelection = (avatar) => {
    setInForm("avatar", avatar);
    setInForm("avatar_id", avatar.id);
  };

  function canBeSubmitted() {
    // if no changes...
    if (form.avatar.id == userDetail.avatar.id) {
      // if password present
      if (form.passwordone.length > 0 || form.passwordtwo.length > 0) {
        if (form.passwordone === form.passwordtwo) {
          return true;
        } else if (form.email !== userDetail.email) {
          return true;
        } else {
          return false;
        }
      } else if (form.email !== userDetail.email) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    dispatch(updateUser(form)).then((updatedUser) => {
      dispatch(setUser(updatedUser.payload.data));
      setInForm("passwordone", "");
      setInForm("passwordtwo", "");
      dispatch(
        showMessage({ message: "Your details have been successfully updated!" })
      );
    });
  }

  useEffect(() => {
    dispatch(getUserDetail(user.id));
    dispatch(getAvatars());
    setForm({
      id: userDetail.id,
      avatar: userDetail.avatar,
      avatar_id: userDetail.avatar.id,
      email: userDetail.email,
      passwordone: "",
      passwordtwo: "",
    });
  }, []);

  return (
    <div
      className={clsx(
        classes.root,
        "flex flex-col min-h-full w-full items-start justify-start"
      )}
    >
      <div
        className={clsx(classes.header, "flex flex-row items-center w-full")}
      >
        <FuseAnimate animation="transition.expandIn" delay={300}>
          <Icon className="text-32">account_circle</Icon>
        </FuseAnimate>
        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
          <Typography variant="h6" className=" hidden sm:flex">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Profile
          </Typography>
        </FuseAnimate>
      </div>
      {/* content */}
      <div
        className={clsx(
          classes.root,
          "flex flex-row min-h-full w-full items-center justify-center"
        )}
      >
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          className="flex flex-col"
        >
          <div className="flex flex-row justify-center">
            <div className="flex flex-col justify-center">
              <div
                className={clsx(
                  classes.whiteBlock,
                  "flex flex-row justify-center w-full"
                )}
              >
                <p className={classes.subtitle}>
                  Choose an avatar profile image
                </p>
              </div>
              <div className={clsx(classes.whiteBlock, "flex flex-row")}>
                <img src="assets/images/site/divider_line.svg" />
              </div>
              <div
                className={clsx(
                  classes.avatarGrid,
                  "flex flex-row flex-wrap items-center justify-items-center"
                )}
              >
                {avatars.map((avatar, index) => (
                  <Avatar
                    key={index}
                    alt="avatar"
                    src={`/assets/images/avatars/${avatar.image}`}
                    className={
                      form.avatar.id === avatar.id
                        ? classes.avatarOn
                        : classes.avatar
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAvatarSelection(avatar);
                    }}
                  />
                ))}
              </div>
              <div
                className={clsx(
                  classes.avatarGrid,
                  "flex flex-row items-center justify-items-center"
                )}
              >
                <div className="flex flex-col w-full">
                  <div className="flex flex-row w-full">
                    <img src="assets/images/site/divider_line.svg" />
                  </div>
                  <div
                    className={clsx(
                      classes.whiteBlock,
                      "flex flex-row items-center md:items-start w-full"
                    )}
                  >
                    <p className={classes.subtitle}>Change Email</p>
                  </div>

                  <div className="flex flex-row w-full">
                    <div className="min-w-48 pt-20">
                      <Icon color="action">email</Icon>
                    </div>
                    <TextField
                      className="mb-12"
                      label="New Email"
                      id="email"
                      type="email"
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </div>

                  <div
                    className={clsx(classes.whiteBlock, "flex flex-row w-full")}
                  >
                    <p className={classes.subtitle}>Change Password</p>
                  </div>

                  <div className="flex flex-row w-full">
                    <div className="min-w-48 pt-20">
                      <Icon color="action">lockopen</Icon>
                    </div>
                    <TextField
                      className="mb-24"
                      label="New Password"
                      id="passwordone"
                      type="password"
                      name="passwordone"
                      value={form.passwordone}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      autoComplete={"" + Math.random(1, 1000)}
                    />
                  </div>

                  <div className="flex flex-row w-full">
                    <div className="min-w-48 pt-20">
                      <Icon color="action">lockopen</Icon>
                    </div>
                    <TextField
                      className="mb-24"
                      label="Confirm Password"
                      id="passwordtwo"
                      type="password"
                      name="passwordtwo"
                      value={form.passwordtwo}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      autoComplete={"" + Math.random(1, 1000)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              classes.whiteBlock,
              "flex flex-row w-full items-end justify-end"
            )}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmit}
              disabled={!canBeSubmitted()}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default withReducer("adminProfileApp", reducer)(AdminProfileApp);
