import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/CameraAlt';
import ActivityFeedItemReplyForm from './ActivityFeedItemReplyForm';

const useStyles = makeStyles(theme => ({
  root: {
  },
  feedItem: {
    marginBottom: '16px',
    borderRadius: '5px',
  },
  avatar: {
    marginRight: '16px'
  },
  textChatTitle: {
    color: '#521B5F',
    fontWeight: 'bold',
    marginRight: '16px'
  },
  textChatTime: {
    color: '#BDBDBD',
    fontWeight: 'bold',
    textAlign: 'left'
  },
  textChatMessageBody: {
    color: '#000000',
    fontSize: '12px',
    paddingBottom: '20px',
    marginLeft: '6px',
    marginTop: '16px',
  },
}));

function ActivityFeedItemComment({ item, match, showForm}) {
	const classes = useStyles();
  const currentUser = useSelector(({ auth }) => auth.user.data);
  const currentMatch = useSelector(({ feedApp }) => feedApp.chat.currentMathID);
  const replyMsgRef = useRef(null);
  const timezone = process.env.REACT_APP_TIMEZONE;

  return (
		<FuseAnimate animation="transition.fadeIn">
			<div className={clsx(classes.feedItem, 'flex flex-col flex-grow')}>
        <div className="flex flex-row items-center">
          {/* avatar */}
          <div className="flex flex-col ">
            <Avatar alt={item.user.firstname} src={`assets/images/avatars/${item.user.avatar.image}`} className={classes.avatar} />
          </div>
          {/* username */}
          <div className={clsx(classes.textChatTitle, 'flex flex-col items-center justify-items-center')}>
            <span>{item.user.firstname}</span>
          </div>
          {/* time */}
          <div className="flex flex-col">
            <span className={classes.textChatTime}>{moment(item.updated_at).format("hh:mm a")}</span>
          </div>
        </div>
        <div className={clsx(classes.textChatMessageBody, 'flex flex-row flex-grow')}>
          <div className="flex flex-col">
            {/* message body */}
            { !item.image_id && (
              <p>{item.text}</p>
            )}
            { item.image_id && (
              <img src={`assets/uploads/postImages/${item.image.url}`} />
            )}
          </div>
        </div>
			</div>
		</FuseAnimate>
	);
}

ActivityFeedItemComment.propTypes = {
  item: PropTypes.object.isRequired,
  match: PropTypes.object,
};

export default ActivityFeedItemComment;
