import React, { useEffect, useState, useRef } from "react";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import history from "@history";
import { makeStyles } from "@material-ui/core/styles";
import withReducer from "app/store/withReducer";
import reducer from "./store";
import { getUserDetail, updateUser } from "./store/userSlice";
import { getUserProjects } from "./store/projectsSlice";
import { getAvatars } from "./store/avatarsSlice";
import { setUser } from "../../../auth/store/userSlice";
import { showMessage } from "app/store/fuse/messageSlice";
import LazyMatchView from "./LazyMatchView";
import LazySubMenu from "./LazySubMenu";
import LazyProfileView from "./LazyProfileView";
import LazyProfilePhotos from "./LazyProfilePhotos";
import LazyProjectsView from "./LazyProjectsView";
import LazyProjects from "./LazyProjects";
import LazySettingsView from "./LazySettingsView";
import LazySettingsForm from "./LazySettingsForm";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "36px",
  },
  mainContent: {
    // marginLeft: '16px',
    // marginRight: '16px',
  },
  navHeaderTitle: {
    fontSize: "1.5em",
    fontWeight: "bold",
    color: "#000000",
    [theme.breakpoints.up("md")]: {
      marginLeft: "26px",
    },
  },
  avatar: {
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px",
      padding: "6px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100px",
      height: "100px",
      padding: "12px",
    },
    "&:hover": {
      background: "#BDBDBD",
    },
    cursor: "pointer",
  },
}));

function UserAccountApp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user.data);
  const userDetail = useSelector(
    ({ userAccountApp }) => userAccountApp.user.user
  );
  const [currentView, setCurrentView] = useState(0);
  const defaultFormState = {
    id: userDetail.id,
    avatar: userDetail.avatar,
    email: "",
    passwordone: "",
    passwordtwo: "",
    secretanswer: userDetail.secretanswer,
  };
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const handleViewChange = (newViewIndex) => {
    setCurrentView(newViewIndex);
  };

  const handlePostSelection = (post_id) => {
    history.push({
      pathname: "/explore/post/" + post_id,
    });
  };

  const goToExplore = () => {
    history.push({
      pathname: "/explore",
    });
  };

  const handleAvatarSelection = (avatar) => {
    setInForm("avatar", avatar);
    setInForm("avatar_id", avatar.id);
  };

  function handleSubmit(event) {
    event.preventDefault();
    dispatch(updateUser(form)).then((updatedUser) => {
      dispatch(setUser(updatedUser.payload.data));
      setInForm("passwordone", "");
      setInForm("passwordtwo", "");
      dispatch(
        showMessage({ message: "Your details have been successfully updated!" })
      );
      history.push({
        pathname: "/userAccount",
      });
    });
  }

  useEffect(() => {
    dispatch(getUserDetail(user.id));
    dispatch(getUserProjects(user.id));
    dispatch(getAvatars());

    setForm({
      id: userDetail.id,
      avatar: userDetail.avatar,
      avatar_id: userDetail.avatar.id,
      email: userDetail.email,
      passwordone: "",
      passwordtwo: "",
      secretanswer: userDetail.secretanswer,
    });
  }, []);

  return (
    <div className="flex flex-row w-full items-start justify-start md:items-center md:justify-center">
      <div
        className={clsx(
          classes.root,
          "flex flex-col w-full md:w-1/2 items-center justify-center flex-wrap"
        )}
      >
        <div className="flex flex-row w-full min-w-full items-center justify-center md:items-start md:justify-start">
          <span className={classes.navHeaderTitle}>My account</span>
        </div>
        <div
          className={clsx(
            classes.mainContent,
            "flex flex-row w-full min-w-full items-start justify-start"
          )}
        >
          <div className="flex flex-col items-start justify-start">
            <LazySubMenu handleViewChange={handleViewChange} />
          </div>
          <div
            className={clsx(
              classes.mainContent,
              "flex flex-col items-start justify-start w-full"
            )}
          >
            {currentView === 0 && <LazyProfileView />}
            {currentView === 1 && <LazyMatchView />}
            {currentView === 2 && (
              <LazyProjectsView goToExplore={goToExplore} />
            )}
            {currentView === 3 && <LazySettingsView />}
          </div>
        </div>
        <div className="flex flex-row min-w-full w-full justify-center">
          {currentView === 0 && <LazyProfilePhotos />}
          {currentView === 2 && (
            <LazyProjects handlePostSelection={handlePostSelection} />
          )}
          {currentView === 3 && (
            <LazySettingsForm
              handleSubmit={handleSubmit}
              handleAvatarSelection={handleAvatarSelection}
              form={form}
              handleChange={handleChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default withReducer("userAccountApp", reducer)(UserAccountApp);
