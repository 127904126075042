import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
  },
  chatMessage: {
    maxWidth: '280px'
  },
  textChatLocalTitle: {
    fontSize: '12px',
    color: '#521B5F',
    fontWeight: 'bold',
    paddingLeft: '6px'
  },
  textChatRemoteTitle: {
    fontSize: '12px',
    color: '#BDBDBD',
    fontWeight: 'bold',
    paddingLeft: '6px'
  },
  textChatTime: {
    color: '#BDBDBD',
    fontWeight: 'bold',
    textAlign: 'right'
  },
  textChatMessageBody: {
    color: '#000000',
    fontSize: '12px',
    fontWeight: 'bold',
    paddingBottom: '20px'
  }
}));

function TextChatMessage({ msg, created_at, user }) {
	const classes = useStyles();
  const currentUser = useSelector(({ auth }) => auth.user.data);
  const source = currentUser.id === user.id ? 'textChatLocalTitle' : 'textChatRemoteTitle';
  const timezone = process.env.REACT_APP_TIMEZONE;

  return (
			<FuseAnimate animation="transition.fadeIn">
				<div className={clsx(classes.chatMessage, 'flex flex-col w-full')}>
          <div className="flex flex-row w-full items-stretch">
            {/* avatar */}
            <div className="flex flex-col">
              <Avatar alt={user.firstname} src={`assets/images/avatars/${user.avatar.image}`} />
            </div>
            {/* username */}
            <div className="flex flex-col flex-grow">
              <span className={classes[source]}>{user.firstname}</span>
            </div>
            {/* time */}
            <div className="flex flex-col flex-grow items-right">
              <span className={classes.textChatTime}>{moment(created_at).format("hh:mm a")}</span>
            </div>
          </div>
          <div className="flex flex-row w-full">
            {/* empty */}
            <div className="flex flex-col w-1/5 h-full min-h-full">

            </div>
            {/* message body */}
            <div className={clsx(classes.textChatMessageBody, 'flex flex-col flex-grow w-full')}>
              <p>{msg}</p>
            </div>
          </div>
				</div>
			</FuseAnimate>
	);
}

TextChatMessage.propTypes = {
  msg: PropTypes.string.isRequired, // message content
  created_at: PropTypes.string.isRequired, // time of message,
  user: PropTypes.object.isRequired, // username of sender
};

export default TextChatMessage;
