const locale = {
	ADMIN_NAV_MENUTITLE: 'Admin Menu',
	ADMIN_NAV_MY_INBOX: 'My Inbox',
	ADMIN_NAV_DASHBOARD: 'Dashboard',
	ADMIN_NAV_CATCHUPCALENDAR: 'Catchup Calendar',
	ADMIN_NAV_MATCHES: 'Matches',
	ADMIN_NAV_MATCH_ACTIVITYFEED: 'Activity Feed',
	ADMIN_NAV_MATCH_PROJECTS: 'Projects',
	ADMIN_NAV_MATCH_CATCHUPS: 'Catchups',
	ADMIN_NAV_MATCH_ACCESSLOGS: 'Access Logs',
	ADMIN_NAV_MATCH_FLAGGEDITEMS: 'Flagged Items',
	ADMIN_NAV_ADMIN_POSTTYPES: 'Post Types / Categories',
	ADMIN_NAV_CONTENT: 'Content',
	ADMIN_NAV_EXPLORE: 'Explore',
	ADMIN_NAV_DOCUMENTS: 'Documents',
	ADMIN_NAV_HELP: 'Help',
	ADMIN_NAV_MESSAGES: 'Messages',
	ADMIN_NAV_ADMIN_NEW_MESSAGE: 'New Message',
	ADMIN_NAV_ADMIN_INBOX: 'Admin Inbox',
	ADMIN_NAV_SYSTEM: 'System',
	ADMIN_NAV_ADMIN_USER_MANAGEMENT: 'User Management',
	ADMIN_NAV_ADMIN_LANGUAGES: 'Languages',
	ADMIN_NAV_ADMIN_FEATURE_CONTROL: 'Feature Control',
	ADMIN_NAV_ADMIN_MATCHOWNERS: 'Match Owners',
	ADMIN_NAV_ADMIN_LOGS: 'Logs',
	ADMIN_NAV_ADMIN_BACKUPS: 'Backups',
	NAV_ACTIVITY_FEED: 'Activity Feed',
	NAV_EXPLORE: 'Explore',
	NAV_DOCUMENTS: 'Documents',
	NAV_HELP: 'Help'
};

export default locale;
