import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import history from "@history";
// import _ from 'lodash';

const quality = 100;
const initialState = {
  chatRoomID: "defaultRoomID",
  catchup: {},
  routeParams: {},
};

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + "catchups";

export const getCatchupByMatch = createAsyncThunk(
  "matchesApp/catchups/getCatchupByMatch",
  async (match_id) => {
    const response = await axios.get(API_URL + "/getByMatch/" + match_id);
    let data = await response.data;

    return { data };
  }
);

export const getRoomID = createAsyncThunk(
  "matchesApp/catchups/getRoomID",
  async (match_id) => {
    const response = await axios.get(API_URL + "/getRoomID/" + match_id);
    const data = await response.data.room_id;

    return { data };
  }
);

export const addCatchup = createAsyncThunk(
  "matchesApp/catchups/addCatchup",
  async (catchup, { dispatch, getState }) => {
    // remove the default generated id
    delete catchup.id;
    const response = await axios.post(API_URL, catchup);
    const data = await response.data;

    dispatch(getCatchupByMatch(catchup.match_id));

    return { data };
  }
);

export const updateCatchup = createAsyncThunk(
  "matchesApp/catchups/updateCatchup",
  async (catchup, { dispatch, getState }) => {
    const response = await axios.put(API_URL + "/" + catchup.id, catchup);
    const data = await response.data;

    dispatch(getCatchupByMatch(catchup.match_id));

    return { data };
  }
);

export const removeCatchup = createAsyncThunk(
  "matchesApp/catchups/removeCatchup",
  async (idObj, { dispatch, getState }) => {
    const response = await axios.delete(API_URL + "/" + idObj.catchup_id);
    const data = await response.data;

    dispatch(getCatchupByMatch(idObj.match_id));

    return { data };
  }
);

const catchupsAdapter = createEntityAdapter({});

export const {
  selectAll: selectCatchups,
  selectById: selectCatchupById,
} = catchupsAdapter.getSelectors((state) => state.matchesApp.catchups);

const catchupsSlice = createSlice({
  name: "matchesApp/catchups",
  initialState: catchupsAdapter.getInitialState({
    routeParams: {},
    chatRoomID: "temproomid",
    catchup: {},
    chatMsgs: [],
    catchupDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setRoomID: (state = initialState, action) => {
      return {
        ...state,
        chatRoomID: action.payload,
      };
    },
    openNewCatchupDialog: (state, action) => {
      state.catchupDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeNewCatchupDialog: (state, action) => {
      state.catchupDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditCatchupDialog: (state, action) => {
      state.catchupDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditCatchupDialog: (state, action) => {
      state.catchupDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getCatchupByMatch.fulfilled]: (state, action) => {
      const { data } = action.payload;
      catchupsAdapter.setAll(state, data);
      if (data && data.length && data.length > 0) {
        state.catchup = data[0];
        state.chatRoomID = data[0].room_id;
      }
    },
    [updateCatchup.fulfilled]: catchupsAdapter.upsertOne,
    [addCatchup.fulfilled]: catchupsAdapter.addOne,
  },
});

export const {
  openNewCatchupDialog,
  closeNewCatchupDialog,
  openEditCatchupDialog,
  closeEditCatchupDialog,
  setRoomID,
} = catchupsSlice.actions;

export default catchupsSlice.reducer;
