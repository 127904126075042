import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import i18next from 'i18next';

// import _ from 'lodash';

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + 'postTypes';

export const getPostTypes = createAsyncThunk('exploreApp/postTypes/getPostTypes', async (routeParams, { getState }) => {
	routeParams = routeParams || getState().exploreApp.postTypes.routeParams;
	const response = await axios.get(API_URL);

	const data = await response.data;
	return { data, routeParams };
});


const postTypesAdapter = createEntityAdapter({});

export const { selectAll: selectPostTypes, selectById: selectPostTypesById } = postTypesAdapter.getSelectors(
	state => state.exploreApp.postTypes
);

const postTypesSlice	 = createSlice({
	name: 'exploreApp/postTypes',
	initialState: postTypesAdapter.getInitialState({
		routeParams: {},
	}),
	reducers: {
	},
	extraReducers: {
		[getPostTypes.fulfilled]: (state, action) => {
			const { data, routeParams } = action.payload;
			postTypesAdapter.setAll(state, data);
			state.routeParams = routeParams;
		},
	}
});

// export const {
// } = postTypesSlice.actions;

export default postTypesSlice.reducer;
