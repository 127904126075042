import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
// import _ from 'lodash';

const initialState = {
	routeParams: {}
}

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + 'documents';

export const getDocuments = createAsyncThunk('documentListApp/documents/getDocuments', async () => {
	const response = await axios.get(API_URL);
	const data = await response.data;

	return { data };
});

const documentsAdapter = createEntityAdapter({});

export const { selectAll: selectDocuments, selectById: selectDocumentById } = documentsAdapter.getSelectors(
	state => state.documentListApp.documents
);

const documentsSlice = createSlice({
	name: 'documentListApp/documents',
	initialState: documentsAdapter.getInitialState({
		routeParams: {}
	}),
	reducers: {
	},
	extraReducers: {
		[getDocuments.fulfilled]: (state, action) => {
			const { data, routeParams } = action.payload;
			documentsAdapter.setAll(state, data);
			state.routeParams = routeParams;
		},
	}
});

// export const {
// } = chatSlice.actions;

export default documentsSlice.reducer;
