import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import history from "@history";
// import _ from 'lodash';

const initialState = {
  routeParams: {},
};

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + "posts";

export const getExplorePosts = createAsyncThunk(
  "exploreApp/posts/getExplorePosts",
  async () => {
    const response = await axios.get(API_URL);
    const data = await response.data;

    return { data };
  }
);

export const postsAdapter = createEntityAdapter({});

export const {
  selectAll: selectExplorePosts,
  selectById: selectPostById,
} = postsAdapter.getSelectors((state) => state.exploreApp.posts);

const chatSlice = createSlice({
  name: "exploreApp/posts",
  initialState: postsAdapter.getInitialState({
    routeParams: {},
  }),
  reducers: {},
  extraReducers: {
    [getExplorePosts.fulfilled]: (state, action) => {
      const { data, routeParams } = action.payload;
      postsAdapter.setAll(state, data);
      state.routeParams = routeParams;
    },
  },
});

// export const {
// } = chatSlice.actions;

export default chatSlice.reducer;
