import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
	root: {
		'& .logo-icon': {
			width: 150,
			height: 30,
			transition: theme.transitions.create(['width', 'height'], {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		},
		'& .react-badge, & .logo-text': {
			transition: theme.transitions.create('opacity', {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		}
	},
	reactBadge: {
		backgroundColor: '#121212',
		color: '#61DAFB'
	}
}));

function Logo() {
	const classes = useStyles();
	const user = useSelector(({ auth }) => auth.user);
	let isOurspaceUser = true;
	if (user && user.role && user.role.length > 0) {
			isOurspaceUser = (user.role[0].toLowerCase() === 'little' || user.role[0].toLowerCase() === 'big') ? true : false;
	} else {
			isOurspaceUser = true;
	}
	const logo = isOurspaceUser ? 'assets/images/logos/logo.ourspace.purple.svg' : 'assets/images/logos/logo.ourspace.white.svg';
	return (
		<div className={clsx(classes.root, 'flex items-center')}>
			<img className="logo-icon" src={logo} alt="logo" />
		</div>
	);
}

export default Logo;
