import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
// import _ from 'lodash';

const initialState = {
	chatMsgs: [],
	catchup: {},
	routeParams: {}
}

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + 'chatroommessages';

export const getTextChatMsgsByCatchup = createAsyncThunk('catchupsApp/chatMsgs/getTextChatMsgsByCatchup', async (catchup_id) => {
	const response = await axios.get(API_URL + '/getByCatchup/' + catchup_id);
	let data = await response.data;

	return { data };
});

export const saveTextChatMsg = createAsyncThunk('catchupsApp/chatMsgs/saveTextChatMsg', async (msg, { dispatch, getState }) => {
	const response = await axios.post(API_URL, msg);
	const data = await response.data.room_id;
  dispatch(getTextChatMsgsByCatchup(msg.catchup_id));
	return { data };
});

const chatMsgsAdapter = createEntityAdapter({});

export const { selectAll: selectChatMsgs, selectById: selectChatMsgById } = chatMsgsAdapter.getSelectors(
	state => state.catchupsApp.chatMsgs
);

const chatMsgsSlice = createSlice({
	name: 'catchupsApp/chatMsgs',
	initialState: chatMsgsAdapter.getInitialState({
		routeParams: {},
		chatMsgs: []
	}),
	reducers: {
	},
	extraReducers: {
		[getTextChatMsgsByCatchup.fulfilled]: (state, action) => {
			const { data, routeParams } = action.payload;
			chatMsgsAdapter.setAll(state, data);
			state.routeParams = routeParams;
		},
	}
});

// export const {
// } = chatMsgsSlice.actions;

export default chatMsgsSlice.reducer;
