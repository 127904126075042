const locale = {
	ADMIN_NAV_MENUTITLE: 'Admin-Menü',
	ADMIN_NAV_MY_INBOX: 'Mein Posteingang',
	ADMIN_NAV_DASHBOARD: 'Instrumententafel',
	ADMIN_NAV_CATCHUPCALENDAR: 'Kalender einholen',
	ADMIN_NAV_MATCHES: 'Paare',
	ADMIN_NAV_MATCH_MYMATCHES: 'Paare',
	ADMIN_NAV_MATCH_ACTIVITYFEED: 'Aktivitäten-Feed',
	ADMIN_NAV_MATCH_PROJECTS: 'Projekte',
	ADMIN_NAV_MATCH_CATCHUPS: 'Aufholjagden',
	ADMIN_NAV_MATCH_ACCESSLOGS: 'Zugriffsprotokolle',
	ADMIN_NAV_MATCH_FLAGGEDITEMS: 'Markierte Artikel',
	ADMIN_NAV_ADMIN_POSTTYPES: 'Post Types / Categories',
	ADMIN_NAV_CONTENT: 'Inhalt',
	ADMIN_NAV_EXPLORE: 'Erkunden',
	ADMIN_NAV_DOCUMENTS: 'Unterlagen',
	ADMIN_NAV_HELP: 'Hilfe',
	ADMIN_NAV_MESSAGES: 'Mitteilungen',
	ADMIN_NAV_ADMIN_NEW_MESSAGE: 'Neue Nachricht',
	ADMIN_NAV_ADMIN_INBOX: 'Admin-Posteingang',
	ADMIN_NAV_SYSTEM: 'System',
	ADMIN_NAV_ADMIN_USER_MANAGEMENT: 'Benutzerverwaltung',
	ADMIN_NAV_ADMIN_LANGUAGES: 'Sprachen',
	ADMIN_NAV_ADMIN_FEATURE_CONTROL: 'Funktionssteuerung',
	ADMIN_NAV_ADMIN_MATCHOWNERS: 'Match Owners',
	ADMIN_NAV_ADMIN_LOGS: 'Protokolle',
	ADMIN_NAV_ADMIN_BACKUPS: 'Backups'
};

export default locale;
