import { combineReducers } from '@reduxjs/toolkit';
import chat from './chatSlice';
import activities from './activitiesSlice';
import comments from './commentsSlice';
import match from './matchSlice';
import catchupRequests from './catchupRequestsSlice';

const reducer = combineReducers({
	activities,
	chat,
	comments,
	match,
	catchupRequests
});

export default reducer;
