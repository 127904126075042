import React, { useRef, useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import StopScreenShareIcon from "@material-ui/icons/StopScreenShare";
import GestureIcon from "@material-ui/icons/Gesture";
import { useDispatch, useSelector } from "react-redux";
import history from "@history";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { setIsReturningToCatchup } from "app/store/fuse/settingsSlice";
import {
  getTextChatMsgsByCatchup,
  saveTextChatMsg,
  selectChatMsgs,
} from "./store/chatMsgsSlice";
import {
  hangupCatchup,
  toggleTextChat,
  toggleCatchupAudio,
  toggleCatchupVideo,
  toggleTextChatOpen,
  setCatchupScreenShareOn,
  setCatchupScreenShareOff,
  setCatchupWhiteBoardOn,
  setCatchupWhiteBoardOff,
  setIsCatchupAudioOn,
  setIsCatchupVideoOn,
  setIsTextChatOpen,
  setIsCatchupLive,
  setIsCatchupBackgrounded,
  registerCatchupNotInProgress,
  setIsAdminMonitoring,
  openWhiteBoardDialog,
  closeWhiteBoardDialog,
} from "./store/catchupsSlice";
import ErrorDialog from "./ErrorDialog";

const useStyles = makeStyles((theme) => ({
  controlBar: {
    backgroundColor: "#000000 !important",
    minHeight: "100px",
    width: "100%",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  imageControl: {
    marginRight: "16px",
    marginLeft: "16px",
    height: "40px",
    width: "40px",
  },
  screenShareOn: {
    backgroundColor: "#fff",
    width: "40px",
    height: "40px",
    marginLeft: "10px",
  },
  screenShareOff: {
    backgroundColor: "#9c27b0",
    color: "#fff",
    width: "40px",
    height: "40px",
    marginLeft: "10px",
  },
  whiteboardOn: {
    backgroundColor: "#fff",
    width: "40px",
    height: "40px",
    marginLeft: "25px",
  },
}));

function CatchupControls() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const [error, setError] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [reconnectionAttempts, setReconnectionAttempts] = useState(0);

  const catchup = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.catchup
  );
  const isCatchupAudioOn = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupAudioOn
  );
  const isCatchupBackgrounded = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupBackgrounded
  );
  const isMonitoring = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isAdminMonitoring
  );
  const isCatchupVideoOn = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupVideoOn
  );
  const isTextChatOpen = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupTextChatOpen
  );
  const isCatchupLive = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupLive
  );
  const featureControlsMap = useSelector(
    ({ fuse }) => fuse.settings.featureControls
  );
  const isCatchupScreenShareOn = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupScreenShareOn
  );
  const isCatchupWhiteBoardOn = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupWhiteBoardOn
  );

  const isMobile = useMediaQuery("(max-width:647px)");

  const [
    isSubscribedToWhiteBoardEvents,
    setIsSubscribedToWhiteBoardEvents,
  ] = useState(false);

  let allowMonitoring;
  if (
    featureControlsMap &&
    featureControlsMap.monitorCatchups &&
    featureControlsMap.monitorCatchups.isEnabled
  ) {
    allowMonitoring = true;
  } else {
    allowMonitoring = false;
  }

  let allowScreenShare;
  if (
    featureControlsMap &&
    featureControlsMap.allowScreenShare &&
    featureControlsMap.allowScreenShare.isEnabled
  ) {
    allowScreenShare = true;
  } else {
    allowScreenShare = false;
  }

  let allowWhiteboard;
  if (
    featureControlsMap &&
    featureControlsMap.allowWhiteboard &&
    featureControlsMap.allowWhiteboard.isEnabled
  ) {
    allowWhiteboard = true;
  } else {
    allowWhiteboard = false;
  }

  const hangup = () => {
    dispatch(
      hangupCatchup({ catchup_id: catchup.id, match_id: user.match_id })
    ).then((res) => {
      let theUrl;
      if (user.role_id === 1 || user.role_id === 999) {
        theUrl = "/apps/dashboards/analytics";
      } else {
        theUrl = "/feed";
      }
      history.push({
        pathname: theUrl,
        state: { redirectUrl: history.location.pathname },
      });
    });
  };

  const toggleSound = () => {
    const { toggleAudio } = window.socketInstance;
    toggleAudio(!isCatchupAudioOn);
    dispatch(toggleCatchupAudio());
  };

  const startScreenShare = async () => {
    const { shareScreen } = window.socketInstance;
    const sharingScreen = shareScreen();

    if (sharingScreen) {
      dispatch(setCatchupScreenShareOn());
    }
  };

  const stopScreenShare = () => {
    const { stopSharingScreen } = window.socketInstance;
    stopSharingScreen();
    dispatch(setCatchupScreenShareOff());
  };

  // for when we open our own whiteboard
  const openWhiteBoard = () => {
    const { popoutVideos } = window.socketInstance;
    popoutVideos();
    dispatch(openWhiteBoardDialog());
    // tell the remotes to open their whiteboards
    window.ourspaceUserSocket.emit("toggleWhiteBoard", {
      sender_id: user.data.id,
      toggle: true,
    });
  };

  // for when the remote toggles our whiteboard
  const toggleWhiteBoard = (data) => {
    const { popoutVideos, moveVideosToMainPage } = window.socketInstance;
    if (!data.toggle || data.toggle == "false") {
      dispatch(closeWhiteBoardDialog());
      moveVideosToMainPage();
      dispatch(setCatchupWhiteBoardOff());
    } else {
      dispatch(openWhiteBoardDialog());
      popoutVideos();
      dispatch(setCatchupWhiteBoardOn());
    }
  };

  const toggleVideo = () => {
    const { toggleVideoTrack } = window.socketInstance;
    toggleVideoTrack({
      video: !isCatchupVideoOn,
      audio: isCatchupAudioOn,
    });
    dispatch(toggleCatchupVideo());
  };

  const toggleTextChat = () => {
    dispatch(toggleTextChatOpen());
    scrollToBottomOfChat();
  };

  const stopMonitoring = () => {
    const { toggleAudio } = window.socketInstance;
    dispatch(
      setIsAdminMonitoring({ isMonitoring: false, user_id: user.data.id })
    );
    toggleAudio(true);
  };

  const returnToCatchup = () => {
    dispatch(setIsReturningToCatchup(true));
  };

  const scrollToBottomOfChat = () => {
    let objDiv = document.getElementById("chatscrollmarker");
    if (objDiv) {
      objDiv.scrollIntoView();
    }
  };

  const saveTextChatMessage = (msg) => {
    const newMsg = {
      fromUser: user,
      catchup_id: catchup.id,
      msg,
    };
    dispatch(saveTextChatMsg(newMsg)).then((result) => {
      scrollToBottomOfChat();
    });
  };

  useEffect(() => {
    if (!isSubscribedToWhiteBoardEvents) {
      if (window.ourspaceUserSocket) {
        // register for when the remote opens their whiteboard
        window.ourspaceUserSocket.on("toggleWhiteBoard", (data) => {
          toggleWhiteBoard(data);
        });
        setIsSubscribedToWhiteBoardEvents(true);
      }
    }

    // returned function will be called on component unmount
    return () => {
      // dispatch(hangupCatchup());
      dispatch(
        registerCatchupNotInProgress({
          catchup_id: catchup.id,
          match_id: user.data.match_id,
        })
      );
    };
  }, [window.ourspaceUserSocket]);

  useEffect(() => {
    if (window.socketInstance) {
      window.socketInstance.myPeer.on("error", (err) => {
        setError(true);
        setErrorTitle(
          `${err.type.replace(/\b\w/g, (l) => l.toUpperCase())} Error`
        );
        setErrorMessage(`${err.message} Try reconnecting or refresh the page`);
      });

      window.socketInstance.socket.on("error", (err) => {
        setError(true);
        setErrorTitle(`A Chat Error Has Occurred`);
        setErrorMessage(`Try reconnecting or refresh the page`);
      });

      window.socketInstance.socket.on("disconnect", (err) => {
        setError(true);
        setErrorTitle(`An error has occurred`);
        setErrorMessage(`Try reconnecting or refresh the page`);
      });
    }
  }, [window.socketInstance, reconnectionAttempts]);

  return (
    <React.Fragment>
      <div
        className={clsx(
          classes.controlBar,
          "flex flex-row  h-full items-center justify-center"
        )}
      >
        {isMonitoring && (
          <img
            className={classes.imageControl}
            src="assets/images/catchups/add_user_desktop_default_button.svg"
            onMouseOver={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/add_user_desktop_rollover_button.svg")
            }
            onMouseOut={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/add_user_desktop_default_button.svg")
            }
            title="Join Catchup"
            onClick={stopMonitoring}
          />
        )}

        {isCatchupAudioOn ? (
          <img
            className={classes.imageControl}
            src="assets/images/catchups/sound_on_desktop_button.svg"
            onMouseOver={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/sound_off_desktop_button.svg")
            }
            onMouseOut={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/sound_on_desktop_button.svg")
            }
            title="Mute My Audio"
            onClick={toggleSound}
          />
        ) : (
          <img
            className={classes.imageControl}
            src="assets/images/catchups/sound_off_desktop_button.svg"
            onMouseOver={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/sound_on_desktop_button.svg")
            }
            onMouseOut={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/sound_off_desktop_button.svg")
            }
            title="Turn On My Audio"
            onClick={toggleSound}
          />
        )}

        {isCatchupVideoOn ? (
          <img
            className={classes.imageControl}
            src="assets/images/catchups/video_on_desktop_button.svg"
            onMouseOver={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/video_off_desktop_button.svg")
            }
            onMouseOut={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/video_on_desktop_button.svg")
            }
            title="Hide My Video"
            onClick={toggleVideo}
          />
        ) : (
          <img
            className={classes.imageControl}
            src="assets/images/catchups/video_off_desktop_button.svg"
            onMouseOver={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/video_on_desktop_button.svg")
            }
            onMouseOut={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/video_off_desktop_button.svg")
            }
            title="Show My Video"
            onClick={toggleVideo}
          />
        )}

        {isTextChatOpen ? (
          <img
            className={classes.imageControl}
            src="assets/images/catchups/chat_on_desktop_button.svg"
            onMouseOver={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/chat_off_desktop_button.svg")
            }
            onMouseOut={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/chat_on_desktop_button.svg")
            }
            title="Close Text Chat"
            onClick={toggleTextChat}
          />
        ) : (
          <img
            className={classes.imageControl}
            src="assets/images/catchups/chat_off_desktop_button.svg"
            onMouseOver={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/chat_on_desktop_button.svg")
            }
            onMouseOut={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/chat_off_desktop_button.svg")
            }
            title="Open Text Chat"
            onClick={toggleTextChat}
          />
        )}

        {isCatchupLive && isCatchupBackgrounded && (
          <img
            className={classes.imageControl}
            src="assets/images/catchups/return-to-catchup-default.svg"
            onMouseOver={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/return-to-catchup-rollover.svg")
            }
            onMouseOut={(e) =>
              (e.currentTarget.src =
                "assets/images/catchups/return-to-catchup-default.svg")
            }
            title="Return to Catchup"
            onClick={returnToCatchup}
          />
        )}

        {allowScreenShare &&
          !isMobile &&
          (!isCatchupScreenShareOn ? (
            <IconButton
              onClick={startScreenShare}
              title="Share my screen"
              color="primary"
              className={clsx(classes.screenShareOn)}
            >
              <ScreenShareIcon color="secondary" />
            </IconButton>
          ) : (
            <IconButton
              onClick={stopScreenShare}
              title="Share my screen"
              color="primary"
              className={clsx(classes.screenShareOff)}
            >
              <StopScreenShareIcon color="warning" />
            </IconButton>
          ))}

        {allowWhiteboard && !isCatchupWhiteBoardOn && (
          <IconButton
            onClick={openWhiteBoard}
            title="Open WhiteBoard"
            color="primary"
            className={clsx(classes.whiteboardOn)}
          >
            <GestureIcon color="secondary" />
          </IconButton>
        )}

        {isCatchupLive && (
          <div className="flex flex-shrink-0 px-12 justify-end items-right">
            <img
              className={classes.imageControl}
              src="assets/images/catchups/end_desktop_default_button.svg"
              onMouseOver={(e) =>
                (e.currentTarget.src =
                  "assets/images/catchups/end_desktop_rollover_button.svg")
              }
              onMouseOut={(e) =>
                (e.currentTarget.src =
                  "assets/images/catchups/end_desktop_default_button.svg")
              }
              title="End Catchup"
              onClick={hangup}
            />
          </div>
        )}
        <ErrorDialog
          open={error}
          closeDialog={async () => {
            setErrorTitle("");
            setErrorMessage("");
            setError(false);
            setReconnectionAttempts(reconnectionAttempts + 1);
          }}
          title={errorTitle}
          message={errorMessage}
        />
      </div>
    </React.Fragment>
  );
}

export default CatchupControls;
