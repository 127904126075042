import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

const ExplorePostsAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/apps/explorePosts/:id',
			component: React.lazy(() => import('./ExplorePostsApp'))
		},
		{
			path: '/apps/explorePosts',
			component: () => <Redirect to="/apps/explorePosts/all" />
		}
	]
};

export default ExplorePostsAppConfig;
