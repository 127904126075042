import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import history from '@history';
// import _ from 'lodash';

const initialState = {
	routeParams: {},
	user: null
}

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + 'users';

export const getUserDetail = createAsyncThunk('adminProfileApp/user/getUserDetail', async (user_id) => {
	const response = await axios.get(API_URL + '/getDetail/' + user_id);
	const data = await response.data;

	return { data };
});

export const updateUser = createAsyncThunk('adminProfileApp/user/updateUser', async (user, { dispatch }) => {
	const response = await axios.put(API_URL + '/' + user.id, user);
	const data = await response.data;

	dispatch(getUserDetail(user.id));
	return { data };
});

const userSlice = createSlice({
	name: 'adminProfileApp/user',
	initialState: initialState,
	reducers: {
	},
	extraReducers: {
		[getUserDetail.fulfilled]: (state, action) => {
			const { data } = action.payload;
			state.user = data;
		},
	}
});

// export const {
// } = userSlice.actions;

export default userSlice.reducer;
