import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "36px",
  },
  header: {
    backgroundColor: "#F4E1FD",
    marginLeft: "16px",
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
      minHeight: "200px",
      textAlign: "center",
      padding: "6px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
      minHeight: "200px",
      marginBottom: "16px",
      textAlign: "right",
      padding: "16px",
    },
  },
  headerTitle: {
    fontSize: "1.5em",
    fontWeight: "bold",
    color: "#000000",
    marginBottom: "26px",
  },
  avatar: {
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px",
      padding: "6px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100px",
      height: "100px",
      padding: "12px",
    },
    "&:hover": {
      background: "#BDBDBD",
    },
    cursor: "pointer",
  },
  profileBlock: {
    marginLeft: "12px",
  },
  profileBody: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "16px",
  },
}));

const LazyProfileView = () => {
  const classes = useStyles();
  const userDetail = useSelector(
    ({ userAccountApp }) => userAccountApp.user.user
  );
  const userType =
    userDetail.role.name.toLowerCase() === "little" ? "Mentee" : "Mentor";
  const avatarImagePath = "/assets/images/avatars/" + userDetail.avatar.image;

  return (
    <div
      className={clsx(
        classes.root,
        "flex flex-col items-center w-full justify-center"
      )}
    >
      <div className={clsx(classes.header, "flex flex-row w-full")}>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <span className={classes.headerTitle}>My profile</span>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col">
              <Avatar
                alt={userDetail.firstname}
                src={avatarImagePath}
                className={classes.avatar}
              />
            </div>
            <div
              className={clsx(
                classes.profileBlock,
                "flex flex-col items-start justify-start"
              )}
            >
              <p className={classes.profileBody}>{userType} user</p>
              <p className={classes.profileBody}>
                {userDetail.firstname} {userDetail.lastname}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LazyProfileView;
