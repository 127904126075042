import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

// import _ from 'lodash';

const initialState = {
  currentTab: 0
};

const siteSlice = createSlice({
	name: 'siteApp/nav',
	initialState: {
		currentTab: 0
	},
	reducers: {
    setCurrentTab: (state = initialState, action) => {
      return {
        ...state,
        currentTab: action.payload
      }
		},
	}
});

export const {
  setCurrentTab
} = siteSlice.actions;

export default siteSlice.reducer;
