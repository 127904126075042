import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import FuseAnimate from "@fuse/core/FuseAnimate";
import ActivityFeedSideBar from "./ActivityFeedSideBar";
import ActivityFeedItem from "./ActivityFeedItem";
import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import CardContent from "@material-ui/core/CardContent";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CameraIcon from "@material-ui/icons/CameraAlt";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ImageUploader from "react-images-upload";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { Link } from "react-router-dom";
import CatchupRequestDialog from "./CatchupRequestDialog";
import withReducer from "app/store/withReducer";
import moment from "moment-timezone";
import reducer from "./store";
import { getTextChatMsgsByCatchup } from "../catchups/store/chatMsgsSlice";
import {
  selectMessages,
  getUserMessages,
} from "../userMessages/store/messagesSlice";
import { getCatchupByMatch, setRoomID } from "./store/catchupsSlice";
import { showMessage } from "../../../store/fuse/messageSlice";
import { getPostTypes } from "../postTypes/store/postTypesSlice";
import { getMatchData } from "./store/matchSlice";
import { getExplorePosts } from "../explore/store/postsSlice";
import { getUserDetail } from "../userAccount/store/userSlice";
import { getUserProjects } from "../userAccount/store/projectsSlice";
import {
  getActivityFeed,
  selectActivities,
  addActivity,
  addImageActivity,
  addActivityComment,
  addImageActivityComment,
} from "./store/activitiesSlice";
import {
  openNewCatchupRequestDialog,
  openEditCatchupRequestDialog,
  saveCatchupRequestConfirmation,
  getCatchupRequest,
} from "./store/catchupRequestsSlice";
import history from "@history";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const ourspacePurple = "#521B5F";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(to left, ${ourspacePurple} 0%, ${darken(
      ourspacePurple,
      0.5
    )} 100%)`,
    color: theme.palette.primary.contrastText,
  },
  leftSection: {},
  rightSection: {
    background: `linear-gradient(to right, ${ourspacePurple} 0%, ${darken(
      ourspacePurple,
      0.5
    )} 100%)`,
    color: theme.palette.primary.contrastText,
  },
  header: {
    [theme.breakpoints.down("sm")]: {
      backgroundImage:
        "url(assets/images/backgrounds/ourspace_activity_feed_hero_mobile_375px.jpg)",
      padding: "0px",
      margin: "0px",
      textAlign: "center",
      height: "253px",
      minHeight: "253px",
    },
    [theme.breakpoints.up("md")]: {
      backgroundImage:
        "url(assets/images/backgrounds/ourspace_activity_feed_hero_1920px.jpg)",
      paddingRight: "26px",
      paddingLeft: "26px",
      marginRight: "26px",
      marginLeft: "26px",
      textAlign: "right",
      height: "484px",
      minHeight: "484px",
    },
    width: "100%",
    minWidth: "100%",
    marginBottom: "16px",
  },
  headerimg: {
    marginBottom: "26px",
  },
  feed: {
    backgroundColor: "#f6f7f9",
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
      maxWidth: "620px",
    },
  },
  spacer: {
    minWidth: "20px",
  },
  statusMsgBox: {
    width: "100%",
    borderRadius: "5px",
    color: "grey",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "6px",
  },
  photoButton: {
    marginLeft: "6px",
    marginRight: "6px",
    backgroundColor: "#FFFFFF",
  },
  statusForm: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      paddingTop: "20px",
      textAlign: "right",
      maxWidth: "500px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "100px",
      paddingTop: "100px",
      textAlign: "right",
      maxWidth: "500px",
    },
  },
  statusButton: {
    backgroundColor: "#FFFFFF",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 480,
  },
}));

function ActivityFeedApp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user.data);
  const catchupRequest = useSelector(
    ({ feedApp }) => feedApp.catchupRequests.catchupRequest
  );
  const activities = useSelector(selectActivities);
  const featureControlsMap = useSelector(
    ({ fuse }) => fuse.settings.featureControls
  );

  const [isCatchupAvailable, setIsCatchupAvailable] = useState(false);
  const [isCatchupRequestAvailable, setIsCatchupRequestAvailable] = useState(
    false
  );
  const [isCatchupRequestConfirmed, setIsCatchupRequestConfirmed] = useState(
    false
  );
  const [isCatchupNow, setIsCatchupNow] = useState(false);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [imageUpload, setImageUpload] = useState("");
  const [commentPostActivityID, setCommentPostActivityID] = useState(null);
  const [adminApprovedCatchups, setAdminApprovedCatchups] = useState(false);
  const [areCatchupRequestsEnabled, setAreCatchupRequestsEnabled] = useState(
    false
  );

  const statusFormRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:647px)");

  let theCatchup;

  const launchCatchup = (catchup_id) => {
    history.push({
      pathname: "/catchup",
      state: { redirectUrl: history.location.pathname },
    });
  };

  const requestCatchup = () => {
    dispatch(
      openNewCatchupRequestDialog({
        start: new Date(),
        end: new Date(),
        match: user.match,
        match_id: user.match.id,
        requestor_id: user.id,
        requestee_id:
          user.match.big.id === user.id
            ? user.match.little.id
            : user.match.big.id,
        requested_at: new Date(),
        bNewTime: false,
      })
    );
  };

  const handleCancelCatchupRequest = (form) => {
    // setIsCatchupRequestAvailable(false);
  };

  const editCatchupRequest = () => {
    dispatch(
      openEditCatchupRequestDialog(catchupRequest, handleCancelCatchupRequest)
    );
  };

  const confirmCatchupRequest = () => {
    dispatch(
      saveCatchupRequestConfirmation({
        request_id: catchupRequest.id,
        match_id: user.match.id,
      })
    ).then((_catchupRequest) => {
      // setIsCatchupRequestAvailable(false);
      setIsCatchupAvailable(true);
      dispatch(showMessage({ message: "Catchup confirmed!" }));
    });
  };

  const handleImageDialogOpen = (activity_id) => {
    if (activity_id) {
      setCommentPostActivityID(activity_id);
    }
    setIsImageDialogOpen(true);
  };

  const handleImageDialogClose = () => {
    setIsImageDialogOpen(false);
  };

  const handleImageDialogPost = () => {
    setIsImageDialogOpen(false);
    if (commentPostActivityID) {
      dispatch(
        addImageActivityComment({
          match_id: user.match.id,
          user_id: user.id,
          activity_id: commentPostActivityID,
          imageUpload: imageUpload,
        })
      ).then(() => {
        setCommentPostActivityID(null);
      });
    } else {
      dispatch(
        addImageActivity({
          match_id: user.match.id,
          user_id: user.id,
          activity_type_id: 5,
          imageUpload: imageUpload,
        })
      );
    }
    setCommentPostActivityID(null);
  };

  const handleStatus = () => {
    if (statusFormRef.current.value) {
      dispatch(
        addActivity({
          match_id: user.match.id,
          user_id: user.id,
          text: statusFormRef.current.value,
          activity_type_id: 1,
        })
      );
    }
  };

  const handleReply = (id, msg) => {
    if (msg) {
      dispatch(
        addActivityComment({
          activity_id: id,
          match_id: user.match.id,
          user_id: user.id,
          text: msg,
        })
      );
    }
  };

  function onImageChange(pictureFiles, pictureDataURLs) {
    setImageUpload(pictureFiles[0]);
  }

  const updateCatchupRequestStatus = (data) => {
    if (!data) {
      return;
    }
    setIsCatchupRequestAvailable(true);
    if (data.bConfirmed) {
      setIsCatchupRequestConfirmed(true);
    } else {
      setIsCatchupRequestConfirmed(false);
    }
  };

  const setupCatchup = (theCatchup) => {
    // update settings for the sidebar
    // setIsCatchupRequestAvailable(false);
    setIsCatchupAvailable(true);
    const now = moment().format("YYYY-MM-DD HH:mm:ss");
    const start = moment(theCatchup.scheduledStart_at).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const end = moment(theCatchup.scheduledEnd_at).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    if (
      theCatchup &&
      !theCatchup.bInProgress &&
      moment(now).isAfter(moment(start).subtract(1, "minute")) &&
      moment(now).isBefore(moment(end))
    ) {
      setIsCatchupNow(true);
    }
  };

  useEffect(() => {
    if (
      featureControlsMap &&
      featureControlsMap.catchupRequests &&
      featureControlsMap.catchupRequests.isEnabled
    ) {
      setAreCatchupRequestsEnabled(true);
    }

    if (
      featureControlsMap &&
      featureControlsMap.approveCatchupRequests &&
      featureControlsMap.approveCatchupRequests.isEnabled
    ) {
      setAdminApprovedCatchups(true);
    }
  }, [featureControlsMap]);

  const checkForCatchup = () => {
    // check for an upcoming catchup for this match
    dispatch(getCatchupByMatch(user.match_id)).then((result) => {
      if (result.payload.data[0]) {
        theCatchup = result.payload.data[0];
      }
      // if we got an error...
      if (result.payload.error) {
        // popup a message
        dispatch(showMessage({ message: theCatchup.error }));
      } else if (theCatchup) {
        // if we found a catchup...
        if (theCatchup && theCatchup.id) {
          if (moment().isBefore(moment(theCatchup.scheduledEnd_at))) {
            setupCatchup(theCatchup);
          }
        } else {
          setIsCatchupAvailable(false);
          setIsCatchupNow(false);
        }
      } else {
        setIsCatchupNow(false);
        setIsCatchupAvailable(false);
      }
    });

    dispatch(getCatchupRequest(user.match_id)).then((result) => {
      // if we got no error and a catchup request...
      if (
        result.payload &&
        result.payload.data &&
        !result.payload.data.error &&
        result.payload.data.id
      ) {
        // update sidebar settings
        setIsCatchupRequestAvailable(true);
        updateCatchupRequestStatus(result.payload.data);
      } else {
        // update sidebar settings, no request available
        setIsCatchupRequestAvailable(false);
        setIsCatchupRequestConfirmed(false);
      }
    });
  };

  useEffect(() => {
    dispatch(getMatchData(user.match_id));
    dispatch(getActivityFeed(user.match_id));
    dispatch(getExplorePosts(user.match.id));
    checkForCatchup();
    // socket isn't always established by this time so keep checking until it is
    let catchupStartListenerTimer = setInterval(() => {
      if (window.ourspaceUserSocket) {
        // listen for when a catchup starts
        window.ourspaceUserSocket.on("catchupStarted", (data) => {
          setupCatchup(data);
        });
        // stop checking
        clearInterval(catchupStartListenerTimer);
      }
    });

    dispatch(getUserDetail(user.id));
    dispatch(getUserProjects(user.id));
    dispatch(getUserMessages(user.id));
    dispatch(getPostTypes());
  }, []);

  useEffect(() => {
    updateCatchupRequestStatus(catchupRequest);
  }, [catchupRequest]);

  const LazyActivityFeedSidebar = (
    _isCatchupAvailable,
    _isCatchupRequestAvailable,
    _isCatchupNow
  ) => {
    return (
      <ActivityFeedSideBar
        match={user.match}
        launchCatchup={launchCatchup}
        requestCatchup={requestCatchup}
        editCatchupRequest={editCatchupRequest}
        confirmCatchupRequest={confirmCatchupRequest}
        isCatchupAvailable={_isCatchupAvailable}
        isCatchupRequestAvailable={_isCatchupRequestAvailable}
        isCatchupNow={_isCatchupNow}
        adminApprovedCatchups={adminApprovedCatchups}
        isCatchupRequestConfirmed={isCatchupRequestConfirmed}
        areCatchupRequestsEnabled={areCatchupRequestsEnabled}
        setIsCatchupAvailable={setIsCatchupAvailable}
      />
    );
  };

  return (
    <>
      <div className="flex flex-col min-h-full w-full items-center justify-items-center ${classes.root}">
        {/* header row */}
        <div
          className={clsx(
            classes.header,
            "flex flex-row items-center justify-items-center w-full"
          )}
        >
          <div className="flex flex-col items-center justify-items-center w-full">
            <div className={clsx(classes.statusForm, "")}>
              <TextareaAutosize
                ref={statusFormRef}
                className={classes.statusMsgBox}
                aria-label="Write a Comment"
                placeholder="Write a comment..."
              />
              <Button
                variant="outlined"
                aria-label="Post an Image Status"
                className={classes.photoButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handleImageDialogOpen();
                }}
              >
                <CameraIcon />
              </Button>
              <Button
                variant="outlined"
                className={classes.statusButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handleStatus(statusFormRef.current.value);
                  statusFormRef.current.value = null;
                }}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
        <FuseAnimate animation="transition.fadeIn">
          {/* body Row */}
          <div className="flex flex-row justify-items-center ">
            <div
              className={clsx(
                classes.feed,
                "flex flex-col min-h-full sm:items-center md:items-end sm:w-full md:w-3/5"
              )}
            >
              {isMobile && (
                <div className="flex flex-col items-center justify-center sm:w-full md:w-2/5">
                  {LazyActivityFeedSidebar(
                    isCatchupAvailable,
                    isCatchupRequestAvailable,
                    isCatchupNow
                  )}
                </div>
              )}

              {activities.map((activity, index) => (
                <ActivityFeedItem
                  replyHandler={handleReply}
                  openImageDialog={handleImageDialogOpen}
                  key={index}
                  item={activity}
                  match={user.match}
                  showForm={true}
                />
              ))}
            </div>

            {!isMobile && (
              <div className="flex flex-col items-start justify-start w-2/5">
                {LazyActivityFeedSidebar(
                  isCatchupAvailable,
                  isCatchupRequestAvailable,
                  isCatchupNow
                )}
              </div>
            )}
          </div>
        </FuseAnimate>
      </div>

      <Dialog
        open={isImageDialogOpen}
        onClose={handleImageDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Post an Image Status</DialogTitle>
        <DialogContent>
          <ImageUploader
            withIcon={true}
            buttonText="Choose Image"
            buttonStyles={{
              textAlign: "left",
            }}
            onChange={onImageChange}
            singleImage={true}
            withPreview={true}
            imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
            maxFileSize={5242880}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImageDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleImageDialogPost} color="primary">
            Post
          </Button>
        </DialogActions>
      </Dialog>

      {areCatchupRequestsEnabled && <CatchupRequestDialog />}
    </>
  );
}

export default withReducer("feedApp", reducer)(ActivityFeedApp);
