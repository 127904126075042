import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "36px",
  },
  header: {
    backgroundColor: "#F4E1FD",
    marginLeft: "16px",
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
      minHeight: "200px",
      textAlign: "center",
      padding: "6px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
      minHeight: "200px",
      marginBottom: "16px",
      textAlign: "right",
      padding: "16px",
    },
  },
  headerTitle: {
    fontSize: "1.5em",
    fontWeight: "bold",
    color: "#000000",
    marginBottom: "26px",
  },

  explorePostsButton: {
    marginTop: "16px;",
    marginBottom: "16px;",
  },
}));

const LazyProjectsView = ({ goToExplore }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        "flex flex-col items-center w-full justify-center"
      )}
    >
      <div className={clsx(classes.header, "flex flex-row w-full")}>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <span className={classes.headerTitle}>My projects</span>
          </div>
          <div className="flex flex-row">
            <Button
              className={classes.explorePostsButton}
              variant="contained"
              onClick={(e) => {
                goToExplore();
              }}
            >
              Explore projects
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LazyProjectsView;
