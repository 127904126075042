import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import history from "@history";
import { createSocketConnectionInstance } from "../Connection";
import { getCatchupByMatch as getCatchupByMatchAdmin } from "../../matches/store/catchupsSlice";
// import _ from 'lodash';

const quality = 100;
const initialState = {
  chatRoomID: "defaultRoomID",
  routeParams: {},
  catchup: {},
  chatMsgs: [],
  isCatchupVisible: false,
  isCatchupAudioOn: false,
  isCatchupVideoOn: false,
  isCatchupTextChatOpen: false,
  isCatchupScreenShareOn: false,
  isCatchupWhiteBoardOn: false,
  isCatchupLive: false,
  isAdminMonitoring: false,
  whiteBoardDialog: {
    props: {
      open: false,
    },
    data: null,
  },
};

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + "catchups";

export const getRoomID = createAsyncThunk(
  "catchupsApp/chat/getRoomID",
  async (match_id) => {
    const response = await axios.get(API_URL + "/getRoomID/" + match_id);
    const data = await response.data.room_id;

    return { data };
  }
);

export const getCatchup = createAsyncThunk(
  "catchupsApp/chat/getCatchup",
  async (catchup_id) => {
    const response = await axios.get(API_URL + "/" + catchup_id);
    let data = await response.data;

    return { data };
  }
);

export const registerCatchupInProgress = createAsyncThunk(
  "catchupsApp/chat/registerCatchupInProgress",
  async (regData, { dispatch }) => {
    const response = await axios.put(
      API_URL + "/setInProgress/" + regData.catchup_id,
      { id: regData.catchup_id, bInProgress: true }
    );
    const data = await response.data.room_id;
    dispatch(getCatchupByMatch(regData.match_id));
    return { data };
  }
);

export const registerCatchupNotInProgress = createAsyncThunk(
  "catchupsApp/chat/registerCatchupNotInProgress",
  async (regData, { dispatch }) => {
    const response = await axios.put(
      API_URL + "/setInProgress/" + regData.catchup_id,
      { id: regData.catchup_id, bInProgress: false }
    );
    const data = await response.data.room_id;
    dispatch(getCatchupByMatch(regData.match_id));
    return { data };
  }
);

export const getCatchupByMatch = createAsyncThunk(
  "catchupsApp/chat/getCatchupByMatch",
  async (match_id) => {
    // debugger;
    const response = await axios.get(API_URL + "/getBriefByMatch/" + match_id);
    let data = await response.data;

    return { data };
  }
);

export const hangupCatchup = createAsyncThunk(
  "catchupsApp/chat/hangupCatchup",
  async (regData, { dispatch }) => {
    if (window.socketInstance) {
      window.socketInstance.destroyConnection();
      window.socketInstance = null;
    }
    dispatch(registerCatchupNotInProgress(regData));

    return { regData };
  }
);

export const sendDataMessageToRoom = createAsyncThunk(
  "catchupsApp/chat/sendTextMessage",
  async (msg, { dispatch, getState }) => {
    window.socketInstance.sendDataMessageToRoom(msg);

    return;
  }
);

const catchupsAdapter = createEntityAdapter({});

export const {
  selectAll: selectCatchups,
  selectById: selectCatchupById,
} = catchupsAdapter.getSelectors((state) => state.catchupsApp.catchups);

const catchupsSlice = createSlice({
  name: "catchupsApp/chat",
  initialState: catchupsAdapter.getInitialState(initialState),
  reducers: {
    setRoomID: (state = initialState, action) => {
      return {
        ...state,
        chatRoomID: action.payload,
      };
    },
    setIsAdminMonitoring: (state = initialState, action) => {
      if (!action.payload.isMonitoring) {
        window.ourspaceUserSocket.emit(
          "showMonitoringAdmin",
          action.payload.user_id
        );
      }
      return {
        ...state,
        isAdminMonitoring: action.payload.isMonitoring,
      };
    },
    setIsCatchupVisible: (state = initialState, action) => {
      return {
        ...state,
        isCatchupVisible: action.payload,
      };
    },
    setIsCatchupAudioOn: (state = initialState, action) => {
      return {
        ...state,
        isCatchupAudioOn: action.payload,
      };
    },
    setIsCatchupVideoOn: (state = initialState, action) => {
      return {
        ...state,
        isCatchupVideoOn: action.payload,
      };
    },
    setIsTextChatOpen: (state = initialState, action) => {
      return {
        ...state,
        isCatchupTextChatOpen: action.payload,
      };
    },
    setIsCatchupBackgrounded: (state = initialState, action) => {
      return {
        ...state,
        isCatchupBackgrounded: action.payload,
      };
    },
    setIsCatchupLive: (state = initialState, action) => {
      return {
        ...state,
        isCatchupLive: action.payload,
      };
    },
    toggleCatchupAudio: (state = initialState, action) => {
      return {
        ...state,
        isCatchupAudioOn: !state.isCatchupAudioOn,
      };
    },
    toggleCatchupVideo: (state = initialState, action) => {
      return {
        ...state,
        isCatchupVideoOn: !state.isCatchupVideoOn,
      };
    },
    toggleTextChatOpen: (state = initialState, action) => {
      return {
        ...state,
        isCatchupTextChatOpen: !state.isCatchupTextChatOpen,
      };
    },
    setCatchupScreenShareOn: (state = initialState, action) => {
      return {
        ...state,
        isCatchupScreenShareOn: true,
      };
    },
    setCatchupScreenShareOff: (state = initialState, action) => {
      return {
        ...state,
        isCatchupScreenShareOn: false,
      };
    },
    setCatchupWhiteBoardOn: (state = initialState, action) => {
      return {
        ...state,
        isCatchupWhiteBoardOn: true,
      };
    },
    setCatchupWhiteBoardOff: (state = initialState, action) => {
      return {
        ...state,
        isCatchupWhiteBoardOn: false,
      };
    },
    openWhiteBoardDialog: (state, action) => {
      return {
        ...state,
        isCatchupWhiteBoardOn: true,
        whiteBoardDialog: {
          props: {
            open: true,
          },
          data: null,
        },
      };
    },
    closeWhiteBoardDialog: (state, action) => {
      return {
        ...state,
        isCatchupWhiteBoardOn: false,
        whiteBoardDialog: {
          props: {
            open: false,
          },
          data: null,
        },
      };
    },
    startCatchup: (state = initialState, action) => {
      window.socketInstance = createSocketConnectionInstance({
        params: action.payload,
      });
      return {
        ...state,
        isCatchupLive: true,
        isCatchupTextChatOpen: false,
        isCatchupWhiteBoardOn: false,
        isCatchupScreenShareOn: false,
        isCatchupVideoOn: true,
        isCatchupAudioOn: true,
        isCatchupVisible: true,
        isCatchupBackgrounded: false,
      };
    },
  },
  extraReducers: {
    [getCatchupByMatch.fulfilled]: (state, action) => {
      const { data } = action.payload;
      if (data && data.length && data.length > 0) {
        state.catchup = data[0];
        state.chatRoomID = data[0].room_id;
      }
    },
    [hangupCatchup.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.isCatchupTextChatOpen = false;
      state.isCatchupVideoOn = false;
      state.isCatchupAudioOn = false;
      state.isCatchupLive = false;
      state.isCatchupVisible = false;
      state.isCatchupBackgrounded = false;
    },
  },
});

export const {
  setRoomID,
  setIsCatchupLive,
  setIsCatchupAudioOn,
  setIsCatchupVideoOn,
  setIsTextChatOpen,
  setIsCatchupVisible,
  setIsCatchupBackgrounded,
  toggleCatchupAudio,
  toggleCatchupVideo,
  toggleTextChatOpen,
  setCatchupScreenShareOn,
  setCatchupScreenShareOff,
  setCatchupWhiteBoardOn,
  setCatchupWhiteBoardOff,
  openWhiteBoardDialog,
  closeWhiteBoardDialog,
  startCatchup,
  setIsAdminMonitoring,
} = catchupsSlice.actions;

export default catchupsSlice.reducer;
