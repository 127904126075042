const locale = {
	WELCOME_BACK_MESSAGE: 'Willkommen zurück',
	MY_MATCHES_TITLE: 'Meine Streichhölzer',
	MY_MATCHES_LAST_ACTIVITY: 'Letzte Aktivität',
	MY_MATCHES_NEXT_CATCHUP: 'Nächster Ups Fangen',
	MY_MATCHES_BUTTON_LABEL: 'GEHE ZU SPIELEN',
	MATCH_ACTIVITY_TITLE: 'Match-Aktivität',
	MY_MATCHES_USERS: 'Mentor & Mentee',
	MATCH_ACTIVITY_SUBTITLE: 'Aktivität',
	MATCH_ACTIVITY_LOGINS_TAB_TITLE: 'Anmeldungen',
	MATCH_ACTIVITY_CATCHUPS_TAB_TITLE: 'Ups Fangen',
	MATCH_ACTIVITY_LEGEND_JAN: 'JAN',
	MATCH_ACTIVITY_LEGEND_FEB: 'FEB',
	MATCH_ACTIVITY_LEGEND_MAR: 'MÄR',
	MATCH_ACTIVITY_LEGEND_APR: 'APR',
	MATCH_ACTIVITY_LEGEND_MAY: 'MAI',
	MATCH_ACTIVITY_LEGEND_JUN: 'JUN',
	MATCH_ACTIVITY_LEGEND_JUL: 'JUL',
	MATCH_ACTIVITY_LEGEND_AUG: 'AUG',
	MATCH_ACTIVITY_LEGEND_SEP: 'SEP',
	MATCH_ACTIVITY_LEGEND_OCT: 'OKT',
	MATCH_ACTIVITY_LEGEND_NOV: 'NOV',
	MATCH_ACTIVITY_LEGEND_DEC: 'DEZ',
	MATCH_ACTIVITY_TOOLTIP_LEGEND_LOGINS: 'Anmeldungen',
	MATCH_ACTIVITY_TOOLTIP_LEGEND_CATCHUPS: 'Ups Fangen',
	CATCHUPS_TITLE: 'Ups Fangen',
	CATCHUPS_USERS: 'Match',
	CATCHUPS_NEXT: 'Start Time',
	CATCHUPS_BUTTON_JOIN: 'IN FORTSCHRITT - BEITRETEN',
	CATCHUPS_BUTTON_MONITOR: 'ÜBERWACHEN',
	CATCHUPS_BUTTON_LABEL: 'GEHE ZU UPS FANGEN',
	FLAGGED_TITLE: 'Markierte Artikel',
	FLAGGED_HEADER_MATCH: 'Paar',
	FLAGGED_HEADER_TYPE: 'Art',
	FLAGGED_HEADER_DATE: 'Datum markiert',
	FLAGGED_BUTTON_LABEL: 'GEHE ZU MARKIERTE ARTIKEL'
};

export default locale;
