
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useDeepCompareEffect } from '@fuse/hooks';
import history from '@history';
import { makeStyles } from '@material-ui/core/styles';
import FuseAnimate from '@fuse/core/FuseAnimate';
import ExplorePost from './ExplorePost';
import ExplorePostCard from './ExplorePostCard';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import ImageUploader from 'react-images-upload';
import withReducer from 'app/store/withReducer';
import _ from 'lodash';
import reducer from './store';
import { selectExplorePosts, getExplorePosts } from './store/postsSlice'
import { selectPostTypes, getPostTypes } from './store/postTypesSlice'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
	root: {
  },
  header: {
    backgroundImage: 'url(assets/images/backgrounds/explore_photo_desktop.jpg)',
    width: '100%',
    minWidth: '100%',
    height: '400px',
		minHeight: '400px',
    marginBottom: '16px',
    textAlign: 'right'
  },
  headerimg: {
    marginBottom: '26px'
  },
  exploreGrid: {
  },
  paper: {
		display: 'flex',
		width: 56,
	},
  formControl: {
    margin: theme.spacing(0),
    minWidth: 480
  },
	selectFormControl: {
		minWidth: '300px',
		marginBottom: '16px'
  },
  backBar: {
    backgroundColor: '#BDBDBD',
  },
  backButton: {
    color: '#521B5F',
    fontWeight: 'bold'
  },
  headerTitle: {
		fontSize: '3.5em',
		fontWeight: 'bold',
		color: '#FFFFFF'
	},
	headerSubTitle: {
		fontSize: '2em',
		fontWeight: 'regular',
		color: '#FFFFFF'
	},
  buttonRow: {
    marginBottom: '16px'
  },
  filterButton: {
    marginRight: '12px'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function ExploreApp() {
	const classes = useStyles();
	const dispatch = useDispatch();
  const explorePosts = useSelector(selectExplorePosts);
  const postTypes = useSelector(selectPostTypes);
  const user = useSelector(({ auth }) => auth.user.data);

  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [imageUpload, setImageUpload] = useState('');
  const [filteredPosts, setFilteredPosts] = useState(explorePosts);
  const [currentPostType, setCurrentPostType] = useState('all');
	const isMobile = useMediaQuery('(max-width:647px)');

	const handlePostSelection = (post_id) => {
    history.push({
			pathname: '/explore/post/' + post_id
		});
  }

  const handleImageDialogOpen = () => {
    setIsImageDialogOpen(true);
  };

  const handleImageDialogClose = () => {
    setIsImageDialogOpen(false);
  };

  const handleImageDialogPost = () => {
    // setIsImageDialogOpen(false);
    // dispatch(addImagePostComment({
    //   match_id: user.match.id,
    //   user_id: user.id,
    //   activity_id: currentPost.id,
    //   imageUpload: imageUpload
    // }));
  };

  const handlePostComment = (msg) => {
    // if (msg) {
    //   dispatch(addPostComment({
    //     post_id: currentPost.id,
    //     match_id: user.match.id,
    //     user_id: user.id,
    //     text: msg,
    //   }));
    // }
  }

  const goBackToExplore = () => {
    // setCurrentPost(null);
  }

  function onImageChange(pictureFiles, pictureDataURLs) {
    setImageUpload(pictureFiles[0]);
  }

  const filterPosts = (postType) => {
    let tempPosts  = _.filter(explorePosts, function(post) {
  		if (postType === 'all' || post.post_type.siteLabel.toLowerCase() === postType) {
				return true;
  		}
  		return false;
  	});
    setFilteredPosts(tempPosts)
  }

  useEffect(() => {
    dispatch(getPostTypes());
  }, []);

	const handlePostTypeChange = (e) => {
		e.stopPropagation();
		setCurrentPostType(e.target.value);
		filterPosts(e.target.value);
	}

	return (
    <div className={clsx(classes.root, 'flex flex-col min-h-full w-full items-center justify-items-center')}>
      {/* header row */}
      <div className={clsx(classes.header, 'flex flex-row items-center justify-items-center w-full')}>
        <div className="flex flex-col w-full items-center justify-items-center">
					<span className={classes.headerTitle}>Explore</span>
					<span className={classes.headerSubTitle}>Fun things to learn and do</span>
				</div>
      </div>
      <FuseAnimate animation="transition.fadeIn">
        <div className={clsx(classes.exploreGrid, 'flex flex-row flex-wrap items-center justify-items-center w-full')}>
          <div className="flex flex-col w-full items-center justify-items-center">
						{isMobile && (
							<div className="flex flex-row w-3/4 items-center justify-items-center">
								<FormControl variant="outlined" className={classes.selectFormControl}>
					        <InputLabel id="postType-label">Post Type</InputLabel>
					        <Select
					          labelId="postType-label"
					          id="postType"
					          value={currentPostType}
					          onChange={handlePostTypeChange}
					          label="Post Type"
					        >
									{postTypes.map((postType, index) => (
										<MenuItem key={index} value={postType.siteLabel.toLowerCase()}>{postType.siteLabel}</MenuItem>
									))}
					        </Select>
					      </FormControl>
							</div>
						)}
						{!isMobile && (
							<div className={clsx(classes.buttonRow, 'flex flex-row items-center justify-items-center')}>
								{postTypes.map((postType, index) => (
									<Button
									 key={index}
		                className={classes.filterButton}
		                variant="contained"
		                color="primary"
		                component="span" onClick={(e) => {
		                  e.stopPropagation();
		                  filterPosts(postType.siteLabel.toLowerCase());
		                }}>
		                {postType.siteLabel}
		              </Button>
								))}
	            </div>
						)}
          </div>
        </div>
      </FuseAnimate>

      <FuseAnimate animation="transition.fadeIn">
        <div className={clsx(classes.exploreGrid, 'flex flex-row flex-wrap items-center justify-items-center sm:w-full md:w-4/5')}>
					{ filteredPosts.map((post, index) => (
						<ExplorePostCard
							key={index}
							post={post}
							handlePostSelection={handlePostSelection} />
					))}
        </div>
      </FuseAnimate>
    </div>
	);
}

export default withReducer('exploreApp', reducer)(ExploreApp);
