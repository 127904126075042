
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import history from '@history';
import { makeStyles } from '@material-ui/core/styles';
import FuseAnimate from '@fuse/core/FuseAnimate';
import DocumentCard from './DocumentCard';
import withReducer from 'app/store/withReducer';
import reducer from './store';
import { selectDocuments, getDocuments } from './store/documentsSlice'

const useStyles = makeStyles(theme => ({
	root: {
  },
  header: {
    backgroundImage: 'url(assets/images/backgrounds/documents_photo_desktop.jpg)',
    width: '100%',
    minWidth: '100%',
    height: '400px',
		minHeight: '400px',
    marginBottom: '16px',
    textAlign: 'right'
  },
  headerimg: {
    marginBottom: '26px'
  },
  paper: {
		display: 'flex',
		width: 56,
	},
	headerTitle: {
		fontSize: '3.5em',
		fontWeight: 'bold',
		color: '#FFFFFF'
	},
	headerSubTitle: {
		fontSize: '2em',
		fontWeight: 'regular',
		color: '#FFFFFF'
	}
}));

function DocumentListApp() {
	const classes = useStyles();
	const dispatch = useDispatch();
  const documents = useSelector(selectDocuments);
  const user = useSelector(({ auth }) => auth.user.data);

  useEffect(() => {
		dispatch(getDocuments());
  }, []);

	return (
    <div className={clsx(classes.root, 'flex flex-col min-h-full w-full items-center justify-items-center')}>
      {/* header row */}
      <div className={clsx(classes.header, 'flex flex-row items-center justify-items-center min-w-full')}>
				<div className="flex flex-col w-full items-center justify-items-center">
					<span className={classes.headerTitle}>Documents</span>
					<span className={classes.headerSubTitle}>Important things to know</span>
				</div>

      </div>
			<FuseAnimate animation="transition.fadeIn">
        <div className={clsx(classes.exploreGrid, 'flex flex-row flex-wrap items-center justify-items-center w-4/5')}>
					{ documents.map((doc, index) => (
						<DocumentCard
							key={index}
							doc={doc}
						/>
					))}
        </div>
			</FuseAnimate>
    </div>
	);
}

export default withReducer('documentListApp', reducer)(DocumentListApp);
