import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
// import _ from 'lodash';

const initialState = {
	routeParams: {},
	projects: []
}

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + 'posts';

export const getUserProjects = createAsyncThunk('userAccountApp/user/getUserProjects', async (user_id) => {
	const response = await axios.get(API_URL + '/getByUser/' + user_id);
	const data = await response.data;

	return { data };
});

const projectsSlice = createSlice({
	name: 'userAccountApp/user',
	initialState: initialState,
	reducers: {
	},
	extraReducers: {
		[getUserProjects.fulfilled]: (state, action) => {
			const { data } = action.payload;
			state.projects = data;
		},
	}
});

// export const {
// } = projectsSlice.actions;

export default projectsSlice.reducer;
