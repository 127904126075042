import FuseAnimate from "@fuse/core/FuseAnimate";
import Fab from "@material-ui/core/Fab";
import FuseUtils from "@fuse/utils";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MessagesTable from "./MessagesTable";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmationDialog from "../../ConfirmationDialog";
import moment from "moment";
import {
  openEditMessageDialog,
  openNewMessageDialog,
  removeMessage,
  selectMessages,
  sendMessage,
} from "./store/messagesSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  addButton: {
    position: "absolute",
    right: 36,
    top: 130,
    zIndex: 99,
  },
}));

const openMessageDialog = () => {};

function MessagesList(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const messages = useSelector(selectMessages);
  const searchText = "";

  const [filteredData, setFilteredData] = useState(null);
  const [deleteMessageID, setDeleteMessageID] = useState(0);
  const [sendMessageID, setSendMessageID] = useState(0);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isSendConfirmDialogOpen, setIsSendConfirmDialogOpen] = useState(false);
  const timezone = process.env.REACT_APP_TIMEZONE;

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "created_at",
        className: "justify-center",
        width: 64,
        sortable: true,
        Cell: ({ row }) => {
          return moment(row.original.created_at).format("YYYY-MM-DD HH:mm:ss");
        },
      },
      {
        Header: "Subject",
        accessor: "subject",
        className: "justify-center",
        width: 64,
        sortable: true,
      },
      {
        Header: "To",
        accessor: "globalTo",
        className: "justify-center",
        width: 64,
        sortable: true,
      },
      {
        Header: "Sender",
        accessor: "sender",
        Cell: ({ row }) => {
          return (
            row.original.sender.firstname + " " + row.original.sender.lastname
          );
        },
        className: "justify-center",
        width: 64,
        sortable: true,
      },
      {
        Header: "Sent",
        accessor: "bSent",
        Cell: ({ row }) => {
          return row.original.bSent ? <Icon>check</Icon> : <Icon>clear</Icon>;
        },
        className: "font-bold",
        sortable: true,
      },
      {
        id: "action",
        width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <>
            {!row.original.bSent && (
              <IconButton
                onClick={(ev) => {
                  ev.stopPropagation();
                  setSendMessageID(row.original.id);
                  setIsSendConfirmDialogOpen(true);
                }}
              >
                <Icon>send</Icon>
              </IconButton>
            )}
            &nbsp;&nbsp;
            <IconButton
              onClick={(ev) => {
                ev.stopPropagation();
                setDeleteMessageID(row.original.id);
                setIsConfirmDialogOpen(true);
              }}
            >
              <Icon>delete</Icon>
            </IconButton>
          </>
        ),
      },
    ],
    [dispatch]
  );

  const handleCloseDialog = (isConfirmed, _deleteMessageID) => {
    setIsConfirmDialogOpen(false);
    if (isConfirmed) {
      dispatch(removeMessage(_deleteMessageID));
    }
  };

  const handleCloseSendDialog = (isConfirmed, _sendMessageID) => {
    setIsSendConfirmDialogOpen(false);
    if (isConfirmed) {
      dispatch(sendMessage(_sendMessageID));
    }
  };

  useEffect(() => {
    function getFilteredArray(entities, _searchText) {
      if (_searchText.length === 0) {
        return messages;
      }
      return FuseUtils.filterArrayByString(messages, _searchText);
    }

    if (messages) {
      setFilteredData(getFilteredArray(messages, searchText));
    }
  }, [messages]);

  if (!filteredData) {
    return null;
  }

  // if (filteredData.length === 0) {
  // 	return (
  //
  // 	);
  // }

  return (
    <>
      {filteredData.length === 0 ? (
        <div className="flex flex-1 items-center justify-center h-full">
          <Typography color="textSecondary" variant="h5">
            There are no messages!
          </Typography>
        </div>
      ) : (
        <FuseAnimate animation="transition.slideUpIn" delay={300}>
          <MessagesTable
            columns={columns}
            data={filteredData}
            onRowClick={(ev, row) => {
              if (row) {
                ev.stopPropagation();
                dispatch(openEditMessageDialog(row.original));
              }
            }}
          />
        </FuseAnimate>
      )}
      <FuseAnimate animation="transition.expandIn" delay={500}>
        <Fab
          color="secondary"
          aria-label="Add a New Message"
          className={classes.addButton}
          onClick={(ev) => {
            ev.stopPropagation();
            dispatch(openNewMessageDialog({}));
          }}
        >
          <Icon>add</Icon>
        </Fab>
      </FuseAnimate>
      <ConfirmationDialog
        classes={{
          paper: classes.paper,
        }}
        id="deleteconfirm"
        keepMounted
        open={isConfirmDialogOpen}
        onClose={handleCloseDialog}
        deleteUserID={deleteMessageID}
        title="Delete Message"
        message="Are you sure you want to delete this Message?"
      />
      <ConfirmationDialog
        classes={{
          paper: classes.paper,
        }}
        id="sendconfirm"
        keepMounted
        open={isSendConfirmDialogOpen}
        onClose={handleCloseSendDialog}
        deleteUserID={sendMessageID}
        title="Send Message"
        message="Are you sure you want to send this Message?"
      />
    </>
  );
}
export default MessagesList;
