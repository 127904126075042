import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import history from "@history";
import firebase from "firebase/app";
import "firebase/auth";
import _ from "@lodash";
import {
  setInitialSettings,
  setDefaultSettings,
} from "app/store/fuse/settingsSlice";
import { showMessage } from "app/store/fuse/messageSlice";
import auth0Service from "app/services/auth0Service";
import firebaseService from "app/services/firebaseService";
import jwtService from "app/services/jwtService";
import {
  initiateSiteSocket,
  registerUser,
  deRegisterUser,
} from "./socketSlice";

export const setUserDataAuth0 = (tokenData) => async (dispatch) => {
  const user = {
    role: ["admin"],
    from: "auth0",
    data: {
      id: tokenData.data.id,
      match_id: tokenData.data.match_id,
      displayName: tokenData.data.firstname,
      photoURL: tokenData.data.avatar.image,
      email: tokenData.data.email,
      settings:
        tokenData.data && tokenData.data.settings && tokenData.data.settings
          ? tokenData.data.settings
          : {},
      shortcuts:
        tokenData.data && tokenData.data.shortcuts && tokenData.data.shortcuts
          ? tokenData.data.shortcuts
          : [],
    },
  };

  return dispatch(setUserData(user));
};

export const setUserDataFirebase = (user, authUser) => async (dispatch) => {
  if (
    user &&
    user.data &&
    user.data.settings &&
    user.data.settings.theme &&
    user.data.settings.layout &&
    user.data.settings.layout.style
  ) {
    // Set user data but do not update
    return dispatch(setUserData(user));
  }

  // Create missing user settings
  return dispatch(createUserSettingsFirebase(authUser));
};

export const createUserSettingsFirebase = (authUser) => async (
  dispatch,
  getState
) => {
  const guestUser = getState().auth.user;
  const fuseDefaultSettings = getState().fuse.settings.defaults;
  const { currentUser } = firebase.auth();

  /**
   * Merge with current Settings
   */
  const user = _.merge({}, guestUser, {
    uid: authUser.uid,
    from: "firebase",
    role: ["admin"],
    data: {
      displayName: authUser.displayName,
      email: authUser.email,
      settings: { ...fuseDefaultSettings },
    },
  });
  currentUser.updateProfile(user.data);

  dispatch(updateUserData(user));

  return dispatch(setUserData(user));
};

export const setUserData = (user) => async (dispatch, getState) => {
  if (history.location.pathname === "/pages/errors/error-500") {
    return;
  }
  /*
    You can redirect the logged-in user to a specific route depending on his role
   */

  var theURL;
  if (!user.role) {
    theURL = "/login";
  } else {
    switch (user.role[0].toLowerCase()) {
      case "admin": {
        theURL = "/apps/dashboards/analytics";
        break;
      }
      case "superuser": {
        theURL = "/apps/dashboards/analytics";
        break;
      }
      case "staff": {
        theURL = "/apps/dashboards/analytics";
        break;
      }
      case "big": {
        theURL = "/feed";
        break;
      }
      case "little": {
        theURL = "/feed";
        break;
      }
      default: {
        theURL = "/login";
      }
    }
  }
  // history.push({
  // 	pathname: '/login',
  // });

  /*
    Set User Settings
     */
  dispatch(setDefaultSettings(user.data.settings));

  dispatch(setUser(user));
  history.push({
    pathname: theURL,
  });
};

const API_URL = process.env.REACT_APP_SERVER_API_URL + "users";

export const updateUserNotes = createAsyncThunk(
  "auth/user/updateUserNotes",
  async (user, { getState }) => {
    const response = await axios.put(API_URL + "/" + user.id, user);
    const data = await response.data;

    return { data };
  }
);

export const updateUserSettings = (settings) => async (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = _.merge({}, oldUser, { data: { settings } });

  dispatch(updateUserData(user));

  return dispatch(setUserData(user));
};

export const updateUserShortcuts = (shortcuts) => async (
  dispatch,
  getState
) => {
  const { user } = getState().auth;
  const newUser = {
    ...user,
    data: {
      ...user.data,
      shortcuts,
    },
  };

  dispatch(updateUserData(user));

  return dispatch(setUserData(newUser));
};

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState().auth;
  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }

  switch (user.from) {
    case "firebase": {
      firebaseService.signOut();
      break;
    }
    case "auth0": {
      auth0Service.logout();
      break;
    }
    default: {
      jwtService.logout();
    }
  }
  // close down any currently running catchups
  if (window.socketInstance) {
    const { destroyConnection } = window.socketInstance;
    destroyConnection();
  }

  dispatch(deRegisterUser(user));

  if (window.ourspaceUserSocket) {
    window.ourspaceUserSocket = null;
  }

  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }
  switch (user.from) {
    case "firebase": {
      firebaseService
        .updateUserData(user)
        .then(() => {
          dispatch(showMessage({ message: "User data saved to firebase" }));
        })
        .catch((error) => {
          dispatch(showMessage({ message: error.message }));
        });
      break;
    }
    case "auth0": {
      auth0Service
        .updateUserData({
          settings: user.data.settings,
          shortcuts: user.data.shortcuts,
        })
        .then(() => {
          dispatch(showMessage({ message: "User data saved to auth0" }));
        })
        .catch((error) => {
          dispatch(showMessage({ message: error.message }));
        });
      break;
    }
    default: {
      jwtService
        .updateUserData(user)
        .then(() => {
          dispatch(showMessage({ message: "User data saved with api" }));
        })
        .catch((error) => {
          dispatch(showMessage({ message: error.message }));
        });
      break;
    }
  }
};

const initialState = {
  role: [], // guest
  data: {
    id: 0,
    displayName: "John Doe",
    photoURL: "assets/images/avatars/Velazquez.jpg",
    email: "johndoe@withinpixels.com",
    shortcuts: ["calendar", "mail", "contacts", "todo"],
    chatID: "",
    notes: "",
  },
};

const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state, action) => {
      return initialState;
    },
  },
  extraReducers: {
    [updateUserNotes.fulfilled]: (state, action) => {
      const { data, routeParams } = action.payload;
      state.data = data.data;
    },
  },
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
