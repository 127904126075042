import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { getCatchupByMatch } from './catchupsSlice';
import axios from 'axios';
import history from '@history';
import moment from 'moment';
// import _ from 'lodash';

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + 'catchuprequests';

export const dateFormat = 'YYYY-MM-DD HH:mm';

export const getCatchupRequest = createAsyncThunk('feedApp/catchupRequests/getCatchupRequest', async (match_id, { dispatch, getState }) => {
	const response = await axios.get(API_URL + '/getByMatch/' + match_id);
	const data = await response.data;

	return { data };
});

export const saveCatchupRequestConfirmation = createAsyncThunk('feedApp/catchupRequests/saveCatchupRequestConfirmation', async (requestData, { dispatch, getState }) => {
	const response = await axios.get(API_URL + '/confirm/' + requestData.request_id);
	const data = await response.data;
	// get the updated request
	dispatch(getCatchupRequest(requestData.match_id));
	// re-run the catchup query as we may now have a new one
	dispatch(getCatchupByMatch(requestData.match_id));
	return { data };
});

export const addCatchupRequest = createAsyncThunk('feedApp/catchupRequests/addCatchupRequest', async (catchupRequestData, { dispatch, getState }) => {
	if (catchupRequestData.id) {
		delete catchupRequestData.id
	}
	const response = await axios.post(API_URL, catchupRequestData);
	const data = await response.data;
	dispatch(getCatchupRequest(catchupRequestData.match_id));
	return { data };
});

export const updateCatchupRequest = createAsyncThunk('feedApp/catchupRequests/updateCatchupRequest', async (catchupRequestData, { dispatch, getState }) => {
	const response = await axios.put(API_URL + '/' + catchupRequestData.id, catchupRequestData);
	const data = await response.data;
	dispatch(getCatchupRequest(catchupRequestData.match_id));
	return { data };
});

export const cancelCatchupRequest = createAsyncThunk('feedApp/catchupRequests/cancelCatchupRequest', async (catchupRequestData, { dispatch, getState }) => {
	const response = await axios.get(API_URL + '/cancel/' + catchupRequestData.id);
	const data = await response.data;
	dispatch(getCatchupRequest(catchupRequestData.match_id));
	return { data };
});


const catchupRequestsSlice = createSlice({
	name: 'feedApp/catchupRequests',
	initialState: {
		catchups: [],
		currentMatch: {},
		routeParams: {},
		catchupRequest: null,
		catchupRequestDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		}
	},
	reducers: {
		openNewCatchupRequestDialog: {
			prepare: (event, matches) => {
				const payload = {
					type: 'new',
					props: {
						open: true
					},
					data: {
						start: moment(event.start).format(dateFormat),
						end: moment(event.end).format(dateFormat),
						matches: matches
					}
				};
				return { payload };
			},
			reducer: (state, action) => {
				state.catchupRequestDialog = action.payload;
			}
		},
		openEditCatchupRequestDialog: {
			prepare: (event, cancel) => {
				const payload = {
					type: 'edit',
					props: {
						open: true,
					},
					data: {
						...event,
						start: moment.utc(event.requestedStart_at).format(dateFormat),
						end: moment.utc(event.requestedEnd_at).format(dateFormat)
					}
				};
				return { payload };
			},
			reducer: (state, action) => {
				state.catchupRequestDialog = action.payload;
			}
		},
		closeNewCatchupRequestDialog: (state, action) => {
			state.catchupRequestDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: null
			};
		},
		closeEditCatchupRequestDialog: (state, action) => {
			state.catchupRequestDialog = {
				type: 'edit',
				props: {
					open: false
				},
				data: null
			};
		}
	},
	extraReducers: {
		[getCatchupRequest.fulfilled]: (state, action) => {
			const { data, routeParams } = action.payload;
			state.catchupRequest = data;
		},
	}
});

export const {
	openNewCatchupRequestDialog,
	closeNewCatchupRequestDialog,
	openEditCatchupRequestDialog,
	closeEditCatchupRequestDialog
} = catchupRequestsSlice.actions;

export default catchupRequestsSlice.reducer;
