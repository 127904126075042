import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import history from "@history";
// import _ from 'lodash';

const quality = 100;
const initialState = {
  chatRoomID: "defaultRoomID",
  catchup: {},
  routeParams: {},
};

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + "catchups";

export const getRoomID = createAsyncThunk(
  "adminCatchupsApp/chat/getRoomID",
  async (match_id) => {
    const response = await axios.get(API_URL + "/getRoomID/" + match_id);
    const data = await response.data.room_id;

    return { data };
  }
);

export const getCatchupByMatch = createAsyncThunk(
  "adminCatchupsApp/chat/getCatchupByMatch",
  async (match_id) => {
    const response = await axios.get(API_URL + "/getBriefByMatch/" + match_id);
    let data = await response.data;

    return { data };
  }
);

export const getCatchup = createAsyncThunk(
  "adminCatchupsApp/chat/getCatchup",
  async (catchup_id) => {
    const response = await axios.get(API_URL + "/" + catchup_id);
    let data = await response.data;

    return { data };
  }
);

export const sendDataMessageToRoom = createAsyncThunk(
  "adminCatchupsApp/chat/sendTextMessage",
  async (msg, { dispatch, getState }) => {
    window.socketInstance.sendDataMessageToRoom(msg);

    return;
  }
);

const catchupsAdapter = createEntityAdapter({});

export const {
  selectAll: selectCatchups,
  selectById: selectCatchupById,
} = catchupsAdapter.getSelectors((state) => state.adminCatchupsApp.catchups);

const catchupsSlice = createSlice({
  name: "adminCatchupsApp/chat",
  initialState: catchupsAdapter.getInitialState({
    routeParams: {},
    chatRoomID: "temproomid",
    catchup: {},
    chatMsgs: [],
  }),
  reducers: {
    setRoomID: (state = initialState, action) => {
      return {
        ...state,
        chatRoomID: action.payload,
      };
    },
  },
  extraReducers: {
    [getCatchupByMatch.fulfilled]: (state, action) => {
      const { data } = action.payload;
      if (data && data.length && data.length > 0) {
        state.catchup = data[0];
        state.chatRoomID = data[0].room_id;
      }
    },
  },
});

export const { setRoomID } = catchupsSlice.actions;

export default catchupsSlice.reducer;
