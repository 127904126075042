import { combineReducers } from '@reduxjs/toolkit';
import user from './userSlice';
import avatars from './avatarsSlice';

const reducer = combineReducers({
	user,
	avatars
});

export default reducer;
