import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  submenu: {
    marginTop: "36px",
    marginLeft: "12px",
    marginRight: "12px",
  },
  subNavButton: {
    marginBottom: "16px",
    width: "130px",
  },
}));

const LazySubMenu = ({ handleViewChange }) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.submenu,
        "flex flex-col items-center justify-center flex-wrap"
      )}
    >
      <Button
        className={classes.subNavButton}
        color="primary"
        variant="contained"
        onClick={(e) => {
          e.stopPropagation();
          handleViewChange(0);
        }}
      >
        My profile
      </Button>
      <Button
        className={classes.subNavButton}
        color="primary"
        variant="contained"
        onClick={(e) => {
          e.stopPropagation();
          handleViewChange(1);
        }}
      >
        My match
      </Button>
      <Button
        className={classes.subNavButton}
        color="primary"
        variant="contained"
        onClick={(e) => {
          e.stopPropagation();
          handleViewChange(2);
        }}
      >
        My projects
      </Button>
      <Button
        className={classes.subNavButton}
        color="primary"
        variant="contained"
        onClick={(e) => {
          e.stopPropagation();
          handleViewChange(3);
        }}
      >
        Settings
      </Button>
    </div>
  );
};

export default LazySubMenu;
