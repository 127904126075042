import { authRoles } from "app/auth";
import i18next from "i18next";
import en from "./navigation-i18n/en";
import de from "./navigation-i18n/de";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("de", "navigation", de);

const navigationConfig = [
  {
    id: "admin.menutitle",
    title: "Admin Menu",
    translate: "ADMIN_NAV_MENUTITLE",
    auth: authRoles.admin,
    type: "group",
    icon: "apps",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        translate: "ADMIN_NAV_DASHBOARD",
        type: "item",
        auth: authRoles.admin,
        icon: "dashboard",
        url: "/apps/dashboards/analytics",
      },
      {
        id: "catchupcalendar",
        title: "Catchup Calendar",
        translate: "ADMIN_NAV_CATCHUPCALENDAR",
        type: "item",
        auth: authRoles.admin,
        icon: "today",
        url: "/apps/catchupCalendar",
      },
    ],
  },
  {
    id: "admin.matches",
    title: "Matches",
    translate: "ADMIN_NAV_MATCHES",
    type: "item",
    auth: authRoles.admin,
    icon: "supervised_user_circle",
    url: "/apps/matches",
  },
  {
    id: "admin.content",
    title: "Content",
    translate: "ADMIN_NAV_CONTENT",
    auth: authRoles.admin,
    type: "collapse",
    icon: "article",
    children: [
      {
        id: "explore",
        title: "Explore",
        translate: "ADMIN_NAV_EXPLORE",
        type: "item",
        auth: authRoles.admin,
        icon: "online_predictions",
        url: "/apps/explorePosts",
      },
      {
        id: "documents",
        title: "Documentss",
        translate: "ADMIN_NAV_DOCUMENTS",
        type: "item",
        auth: authRoles.admin,
        icon: "attach_file",
        url: "/apps/documents",
      },
      {
        id: "help",
        title: "Help",
        translate: "ADMIN_NAV_HELP",
        type: "item",
        auth: authRoles.admin,
        icon: "help_outline",
        url: "/apps/helps",
      },
    ],
  },
  {
    id: "admin.messages",
    title: "Messages",
    translate: "ADMIN_NAV_MESSAGES",
    auth: authRoles.admin,
    type: "item",
    icon: "email",
    url: "/apps/messages",
  },
  {
    id: "admin.system",
    title: "System",
    translate: "ADMIN_NAV_SYSTEM",
    type: "collapse",
    icon: "admin_panel_settings",
    auth: authRoles.admin,
    children: [
      {
        id: "admin.system.users",
        title: "User Management",
        translate: "ADMIN_NAV_ADMIN_USER_MANAGEMENT",
        type: "item",
        auth: authRoles.admin,
        icon: "people_alt",
        url: "/apps/users",
      },
      {
        id: "admin.system.languages",
        title: "Languages",
        translate: "ADMIN_NAV_ADMIN_LANGUAGES",
        type: "item",
        auth: authRoles.superuser,
        icon: "language",
        url: "/apps/languages",
      },
      {
        id: "admin.system.postTypes",
        title: "Post Types / Categories",
        translate: "ADMIN_NAV_ADMIN_POSTTYPES",
        type: "item",
        auth: authRoles.superuser,
        icon: "category",
        url: "/apps/postTypes",
      },
      {
        id: "admin.system.featurecontrol",
        title: "Feature Control",
        translate: "ADMIN_NAV_ADMIN_FEATURE_CONTROL",
        type: "item",
        auth: authRoles.superuser,
        icon: "fact_check",
        url: "/apps/featureControls",
      },
      {
        id: "admin.system.logs",
        title: "Logs",
        translate: "ADMIN_NAV_ADMIN_LOGS",
        type: "item",
        auth: authRoles.superuser,
        icon: "text_snippet",
        url: "/apps/logs",
      },
      {
        id: "admin.system.backups",
        title: "Backups",
        translate: "ADMIN_NAV_ADMIN_BACKUPS",
        type: "item",
        auth: authRoles.superuser,
        icon: "backup",
        url: "/apps/backups",
      },
    ],
  },
  {
    id: "activityfeed",
    title: "Activity Feed",
    translate: "NAV_ACTIVITY_FEED",
    auth: authRoles.user,
    type: "link",
    target: "_self",
    icon: "none",
    url: "/apps/feed",
  },
  {
    id: "explore",
    title: "Explore",
    translate: "NAV_EXPLORE",
    auth: authRoles.user,
    type: "link",
    target: "_self",
    icon: "none",
    url: "/apps/explore",
  },
  {
    id: "documents",
    title: "Documents",
    translate: "NAV_DOCUMENTS",
    auth: authRoles.user,
    type: "link",
    target: "_self",
    icon: "none",
    url: "/apps/documentList",
  },
  {
    id: "help",
    title: "Help",
    translate: "NAV_HELP",
    auth: authRoles.user,
    type: "link",
    target: "_self",
    icon: "none",
    url: "/apps/help",
  },
];

export default navigationConfig;
