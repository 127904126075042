import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
import { getActivityFeed } from '../store/activitiesSlice';
// import _ from 'lodash';

const initialState = {
	routeParams: {}
}

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + 'comments';

export const addActivityComment = createAsyncThunk('feedApp/comments/addActivityComment', async (comment, { dispatch, getState }) => {
	const response = await axios.post(API_URL + '/' + comment.activity_id, comment);
	const data = await response.data;
	// dispatch(getActivityFeed(comment.match_id));
	return { data };
});

export const addImageActivityComment = createAsyncThunk('feedApp/comments/addImageActivityComment', async (comment, { dispatch, getState }) => {
  let formData = new FormData();
  formData.append("user_id", comment.user_id);
  formData.append("activity_id", comment.activity_id);
  formData.append("imageUpload", comment.imageUpload);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  const response = await axios.post(
    API_URL + '/' + comment.activity_id,
    formData,
    config
  );
  const data = await response.data;

  // dispatch(getActivityFeed(comment.match_id));
	return { data };
});

export const addPostComment = createAsyncThunk('feedApp/comments/addPostComment', async (comment, { dispatch, getState }) => {
	const response = await axios.post(API_URL + '/post/' + comment.post_id, comment);
	const data = await response.data;
	// dispatch(getActivityFeed(comment.match_id));
	return { data };
});

export const addImagePostComment = createAsyncThunk('feedApp/comments/addImagePostComment', async (comment, { dispatch, getState }) => {
  let formData = new FormData();
  formData.append("user_id", comment.user_id);
  formData.append("post_id", comment.post_id);
  formData.append("imageUpload", comment.imageUpload);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  const response = await axios.post(
    API_URL + '/post/' + comment.activity_id,
    formData,
    config
  );
  const data = await response.data;

  // dispatch(getActivityFeed(comment.match_id));
	return { data };
});


const commentSlice = createSlice({
	name: 'feedApp/comments',
	initialState: {
		routeParams: {}
	},
	reducers: {
	},
	extraReducers: {
	}
});

// export const {
// } = commentSlice.actions;

export default commentSlice.reducer;
