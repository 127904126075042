import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';


// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL;

export const getOwnedMatches = createAsyncThunk('usersApp/user/getOwnedMatches', async (user, { dispatch, getState }) => {
	const response = await axios.get(API_URL + 'matches/user/' + 1);
	const data = await response.data;
  return { data };
});

const matchesAdapter = createEntityAdapter({});

export const { selectAll: selectMatches, selectById: selectMatchesById } = matchesAdapter.getSelectors(
	state => state.usersApp.matches
);

const matchesSlice = createSlice({
	name: 'usersApp/matches',
	initialState: matchesAdapter.getInitialState({
    currentMatch: '',
    routeParams: {}
  }),
	reducers: {},
	extraReducers: {
		[getOwnedMatches.fulfilled]: (state, action) => {
      const { data } = action.payload;
      matchesAdapter.setAll(state, data);
    }
	}
});

export default matchesSlice.reducer;
