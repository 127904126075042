import FuseAnimate from '@fuse/core/FuseAnimate';
import FusePageSimple from '@fuse/core/FusePageSimple';
import withReducer from 'app/store/withReducer';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from '@fuse/hooks';
import { useParams } from 'react-router-dom';
import MessagesHeader from './MessagesHeader';
import MessagesList from './MessagesList';
import reducer from './store';
import MessageDialog from './MessageDialog';
import {
	getMessages,
	addMessage,
	updateMessage,
	removeMessage,
	sendMessage,
	selectMessages
} from './store/messagesSlice';

function MessagesApp(props) {
	const dispatch = useDispatch();

	const pageLayout = useRef(null);
	const routeParams = useParams();
	const messages = useSelector(selectMessages);

	useEffect(() => {
		dispatch(getMessages());
	}, []);

	return (
		<FuseAnimate animation="transition.fadeIn">
			<div className="overflow-hidden">
				<FusePageSimple
					classes={{
						contentWrapper: 'p-0 sm:p-24 h-full',
						content: 'flex flex-col',
						leftSidebar: 'w-256 border-0',
						header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
						wrapper: 'min-h-0'
					}}
					header={<MessagesHeader pageLayout={pageLayout}/>}
					content={<MessagesList />}
					ref={pageLayout}
					innerScroll
				/>
				<MessageDialog />
			</div>
		</FuseAnimate>
	);
}

export default withReducer('messagesApp', reducer)(MessagesApp);
