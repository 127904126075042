import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { getUserData } from "./userSlice";
// import _ from 'lodash';

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL;

export const getUsers = createAsyncThunk(
  "usersApp/users/getUsers",
  async (routeParams, { getState }) => {
    routeParams = routeParams || getState().usersApp.users.routeParams;
    const response = await axios.get(API_URL + "users");
    const data = await response.data;

    return { data, routeParams };
  }
);

export const addUser = createAsyncThunk(
  "usersApp/users/addUser",
  async (user, { dispatch, getState }) => {
    // remove the default generated id
    delete user.id;
    user.password = user.passwordone;
    user.confirmPassword = user.passwordtwo;
    const response = await axios.post(API_URL + "users/", user);
    const data = await response.data;

    dispatch(getUsers());

    return data;
  }
);

export const updateUser = createAsyncThunk(
  "usersApp/users/updateUser",
  async (user, { dispatch, getState }) => {
    user.password = user.passwordone;
    user.confirmPassword = user.passwordtwo;
    const response = await axios.put(API_URL + "users/" + user.id, user);
    const data = await response.data;
    dispatch(getUsers());

    return data;
  }
);

export const removeUser = createAsyncThunk(
  "usersApp/users/removeUser",
  async (userId, { dispatch, getState }) => {
    const response = await axios.delete(API_URL + "users/" + userId, {
      userId,
    });
    const data = await response.data;
    dispatch(getUsers());

    return data;
  }
);

export const removeUsers = createAsyncThunk(
  "usersApp/users/removeUsers",
  async (userIds, { dispatch, getState }) => {
    const response = await axios.post("/api/users-app/remove-users", {
      userIds,
    });
    const data = await response.data;

    dispatch(getUsers());

    return data;
  }
);

export const toggleStarredUser = createAsyncThunk(
  "usersApp/users/toggleStarredUser",
  async (userId, { dispatch, getState }) => {
    const response = await axios.post("/api/users-app/toggle-starred-user", {
      userId,
    });
    const data = await response.data;

    dispatch(getUserData());

    dispatch(getUsers());

    return data;
  }
);

export const toggleStarredUsers = createAsyncThunk(
  "usersApp/users/toggleStarredUsers",
  async (userIds, { dispatch, getState }) => {
    const response = await axios.post("/api/users-app/toggle-starred-users", {
      userIds,
    });
    const data = await response.data;

    dispatch(getUserData());

    dispatch(getUsers());

    return data;
  }
);

export const setUsersStarred = createAsyncThunk(
  "usersApp/users/setUsersStarred",
  async (userIds, { dispatch, getState }) => {
    const response = await axios.post("/api/users-app/set-users-starred", {
      userIds,
    });
    const data = await response.data;

    dispatch(getUserData());

    dispatch(getUsers());

    return data;
  }
);

export const setUsersUnstarred = createAsyncThunk(
  "usersApp/users/setUsersUnstarred",
  async (userIds, { dispatch, getState }) => {
    const response = await axios.post("/api/users-app/set-users-unstarred", {
      userIds,
    });
    const data = await response.data;

    dispatch(getUserData());

    dispatch(getUsers());

    return data;
  }
);

const usersAdapter = createEntityAdapter({});

export const {
  selectAll: selectUsers,
  selectById: selectUsersById,
} = usersAdapter.getSelectors((state) => state.usersApp.users);

const usersSlice = createSlice({
  name: "usersApp/users",
  initialState: usersAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    userDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setUsersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewUserDialog: (state, action) => {
      state.userDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewUserDialog: (state, action) => {
      state.userDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditUserDialog: (state, action) => {
      state.userDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditUserDialog: (state, action) => {
      state.userDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [updateUser.fulfilled]: usersAdapter.upsertOne,
    [addUser.fulfilled]: usersAdapter.addOne,
    [getUsers.fulfilled]: (state, action) => {
      const { data, routeParams } = action.payload;
      usersAdapter.setAll(state, data);
      state.routeParams = routeParams;
      state.searchText = "";
    },
  },
});

export const {
  setUsersSearchText,
  openNewUserDialog,
  closeNewUserDialog,
  openEditUserDialog,
  closeEditUserDialog,
} = usersSlice.actions;

export default usersSlice.reducer;
