import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
// import _ from 'lodash';

const initialState = {
	routeParams: {}
}

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + 'messages';

export const getUserMessages = createAsyncThunk('userMessagesApp/messages/getMessages', async (user_id) => {
	const response = await axios.get(API_URL + '/getByUser/' + user_id);
	const data = await response.data;
	return { data };
});

export const removeMessage = createAsyncThunk('userMessagesApp/messages/removeMessage', async (removeData, { dispatch }) => {
	const response = await axios.delete(API_URL + '/' + removeData.message_id);
	const data = await response.data;
	dispatch(getUserMessages(removeData.user_id));
	return { data };
});

export const markMessageAsRead = createAsyncThunk('userMessagesApp/messages/markMessageAsRead', async (msgData, { dispatch }) => {
	const response = await axios.post(API_URL + '/markAsRead/' + msgData.message_id);
	const data = await response.data;
	dispatch(getUserMessages(msgData.user_id));
	return { data };
});

const MessagesAdapter = createEntityAdapter({});

export const { selectAll: selectMessages, selectById: selectMessageById } = MessagesAdapter.getSelectors(
	state => state.userMessagesApp.messages
);

const messagesSlice = createSlice({
	name: 'userMessagesApp/messages',
	initialState: MessagesAdapter.getInitialState({
		routeParams: {}
	}),
	reducers: {
	},
	extraReducers: {
		[getUserMessages.fulfilled]: (state, action) => {
			const { data, routeParams } = action.payload;
			MessagesAdapter.setAll(state, data);
			state.routeParams = routeParams;
		},
	}
});

// export const {
// } = messagesSlice.actions;

export default messagesSlice.reducer;
