
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import history from '@history';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FuseAnimate from '@fuse/core/FuseAnimate';
import HelpItem from './HelpItem';
import withReducer from 'app/store/withReducer';
import reducer from './store';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { selectHelps, getHelps } from './store/helpsSlice'

const useStyles = makeStyles(theme => ({
	root: {

  },
  header: {
    backgroundImage: 'url(assets/images/backgrounds/help_hero_desktop.jpg)',
    width: '100%',
    minWidth: '100%',
    height: '400px',
		minHeight: '400px',
    marginBottom: '16px',
    textAlign: 'right'
  },
  headerimg: {
    marginBottom: '26px'
  },
  paper: {
		display: 'flex',
		width: 56,
	},
	headerTitle: {
		fontSize: '3.5em',
		fontWeight: 'bold',
		color: '#FFFFFF'
	},
	headerSubTitle: {
		fontSize: '2em',
		fontWeight: 'regular',
		color: '#FFFFFF'
	},
	helpPanel: {
		backgroundImage: 'url(assets/images/site/help_panel_right.png)',
		backgroundImageRepeat: 'no-repeat',
		height: '261px',
		width: '255px',
		maxHeight: '261px',
		maxWidth: '255px',
		padding: '12px'
	},
	helpList: {
		[theme.breakpoints.down("sm")]: {
			paddingLeft: '16px',
			paddingRight: '16px',
			paddingTop: '16px',
		}
	},
	panelTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '26px',
    marginBottom: '6px'
  },
  panelBody: {
    fontSize: '16px',
    fontWeight: 'regular',
  },
	spacer: {
    minWidth: '20px'
  },
}));

function HelpApp() {
	const classes = useStyles();
	const dispatch = useDispatch();
  const helps = useSelector(selectHelps);
  const user = useSelector(({ auth }) => auth.user.data);

	const isMobile = useMediaQuery('(max-width:647px)');

  useEffect(() => {
		dispatch(getHelps());
  }, []);

	return (
    <div className={clsx(classes.root, 'flex flex-col min-h-full w-full items-center justify-items-center')}>
      {/* header row */}
      <div className={clsx(classes.header, 'flex flex-row items-center justify-items-center min-w-full')}>
				<div className="flex flex-col w-full items-center justify-items-center">
					<span className={classes.headerTitle}>Help</span>
					<span className={classes.headerSubTitle}>Help is on the way!</span>
				</div>
      </div>

			<div className="flex flex-col md:flex-row justify-center sm:min-w-full">

				<div className={clsx(classes.helpList, 'flex sm:flex-row md:flex-col md:min-h-full sm:items-center md:items-end sm:w-full md:w-3/5')}>
					<FuseAnimate animation="transition.fadeIn">
						<div className={clsx(classes.exploreGrid, 'flex flex-row flex-wrap items-center justify-items-center sm:w-full md:w-4/5')}>
							{ helps.map((item, index) => (
								<HelpItem
									key={index}
									item={item}
								/>
							))}
						</div>
					</FuseAnimate>
				</div>

				{/* spacer */}
				{!isMobile && <div className={clsx(classes.spacer, 'flex flex-col')}></div>}

				<div className="flex sm:flex-row md:flex-col sm:items-center sm:justify-center md:items-start md:justify-start md:min-h-full sm:w-full md:w-2/5">
					<div className={clsx(classes.helpPanel, 'flex flex-col')}>
						<div className="flex flex-row">
							<span className={classes.panelTitle}>We're always here to help.</span>
						</div>
						<div className="flex flex-row flex-grow">
							<span className={classes.panelBody}>If you have a question about the program, this website, or general feedback, let us know.</span>
						</div>
						<div className="flex flex-row justify-end items-end">
							<Button
								variant="outlined"
								color="primary"
								onClick={(e) => {
								e.stopPropagation();
								window.location.href = 'https://bbbs.org.au/contact';
							}}>
								Contact Us
							</Button>
						</div>
					</div>
				</div>

			</div>


    </div>
	);
}

export default withReducer('helpApp', reducer)(HelpApp);
