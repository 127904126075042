import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import history from "@history";
// import _ from 'lodash';

const quality = 100;
const initialState = {
  chatRoomID: "defaultRoomID",
  routeParams: {},
};

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + "catchups";

export const getMatchCatchups = createAsyncThunk(
  "feedApp/catchups/getMatchCatchups",
  async (match_id, routeParams, { getState }) => {
    routeParams = routeParams || getState().feedApp.chat.routeParams;
    const response = await axios.get(API_URL + "/getByMatch/" + match_id);
    const data = await response.data.room_id;

    return { data, routeParams };
  }
);

export const getRoomID = createAsyncThunk(
  "feedApp/chat/getRoomID",
  async (match_id) => {
    const response = await axios.get(API_URL + "/getRoomID/" + match_id);
    const data = await response.data.room_id;

    return { data };
  }
);

export const getCatchupByMatch = createAsyncThunk(
  "feedApp/chat/getCatchupByMatch",
  async (match_id) => {
    const response = await axios.get(API_URL + "/getBriefByMatch/" + match_id);
    let data = await response.data;

    return { data };
  }
);

export const removeCatchup = createAsyncThunk(
  "matchesApp/catchups/removeCatchup",
  async (idObj, { dispatch, getState }) => {
    const response = await axios.delete(API_URL + "/" + idObj.catchup_id);
    const data = await response.data;

    dispatch(getCatchupByMatch(idObj.match_id));

    return { data };
  }
);

const catchupsAdapter = createEntityAdapter({});

export const {
  selectAll: selectCatchups,
  selectById: selectCatchupById,
} = catchupsAdapter.getSelectors((state) => state.feedApp.catchups);

const chatSlice = createSlice({
  name: "feedApp/chat",
  initialState: catchupsAdapter.getInitialState({
    routeParams: {},
    chatRoomID: "temproomid",
    catchup: {},
    chatMsgs: [],
  }),
  reducers: {
    setRoomID: (state = initialState, action) => {
      return {
        ...state,
        chatRoomID: action.payload,
      };
    },
  },
  extraReducers: {
    [getMatchCatchups.fulfilled]: (state, action) => {
      const { data, routeParams } = action.payload;
      catchupsAdapter.setAll(state, data);
      state.routeParams = routeParams;
    },
    [getCatchupByMatch.fulfilled]: (state, action) => {
      const { data } = action.payload;
      if (data && data.length && data.length > 0) {
        return { ...state, catchup: data[0], chatRoomID: data[0].room_id };
      } else if (data.length === 0) {
        return { ...state, catchup: {}, chatRoomID: "temproomid" };
      }
    },
  },
});

export const { setRoomID } = chatSlice.actions;

export default chatSlice.reducer;
