import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/CameraAlt';

const useStyles = makeStyles(theme => ({
  root: {
  },
  replyMsgBox: {
    width: '100%',
    borderRadius: '5px',
    color: 'grey',
    fontSize: '16px',
    fontWeight: 'bold',
    padding:  '6px'
  },
  photoButton: {
    marginLeft: '6px',
    marginRight: '6px',
    backgroundColor: '#FFFFFF'
  },
  replyButton: {
    backgroundColor: '#FFFFFF'
  }
}));

function ActivityFeedReplyForm({ replyHandler, openImageDialog, activity_id}) {
	const classes = useStyles();
  const replyMsgRef = useRef(null);

  return (
    <>
      <TextareaAutosize
        ref={replyMsgRef}
        className={classes.replyMsgBox}
        aria-label="Write a Comment"
        placeholder="Write a comment..." />
      <Button
        variant="outlined"
        className={classes.photoButton}
        aria-label="Post an Image"
        onClick={(e) => {
          e.stopPropagation();
          openImageDialog(activity_id);
        }}>
        <CameraIcon />
      </Button>
      <Button
        variant="outlined"
        className={classes.replyButton}
        onClick={(e) => {
          e.stopPropagation();
          replyHandler(activity_id, replyMsgRef.current.value);
          replyMsgRef.current.value = null;
        }}>
        Reply
      </Button>
    </>
	);
}

ActivityFeedReplyForm.propTypes = {
  activity_id: PropTypes.number.isRequired,
  replyHandler: PropTypes.func.isRequired,
  openImageDialog: PropTypes.func.isRequired
};

export default ActivityFeedReplyForm;
