import FuseAnimate from '@fuse/core/FuseAnimate';
import { useForm } from '@fuse/hooks';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'

const useStyles = makeStyles(theme => ({
	root: {
		background: `radial-gradient(${darken(theme.palette.primary.dark, 0.5)} 0%, ${theme.palette.primary.dark} 80%)`,
		color: theme.palette.primary.contrastText
	},
	confirmation: {
		fontWeight: 'bold',
		color: '#521B5F'
	}
}));

function ResetPasswordPage() {
	const classes = useStyles();
	// get url param for reset link id
	const pathArray = window.location.pathname.split('/');
	const linkID = pathArray[pathArray.length-1];
	const { form, handleChange, resetForm, setInForm } = useForm({
		password: '',
		confirmpassword: '',
		linkID: linkID,
		uuid: linkID,
		user_id: 0
	});
	const [isLinkValid, setIsLinkValid] = useState(true);
	const [isServerError, setIsServerError] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [serverError, setServerError] = useState('');

	function isFormValid() {
		return (
			form.password.length > 0 &&
			form.password.length > 3 &&
			form.password === form.confirmpassword
		);
	}

	function handleSubmit(ev) {
		ev.preventDefault();
		submitForm();
	}

	async function submitForm() {
		const response = await axios.post(process.env.REACT_APP_SERVER_API_URL + 'resetPassword', form);
		const result = await response.data;
		if (result.error) {
			setIsServerError(true);
			setServerError(result.error);
			setIsSuccess(false);
		} else {
			setIsSuccess(true);
		}
		resetForm();
	}

	async function validateResetLinkID() {

		const response = await axios.get(process.env.REACT_APP_SERVER_API_URL + 'resetPasswordLink/' + linkID);
		const result = await response.data;
		if (result.linkValid) {
			setIsLinkValid(true);
		} else {
			setIsLinkValid(false);
		}
		if (result.data) {
			setInForm('uuid', result.data.uuid);
			setInForm('user_id', result.data.user_id);
		}

	}

	useEffect(async () => {
		// validate the link id
		await validateResetLinkID();
	}, []);

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
			<div className="flex flex-col items-center justify-center w-full">
				<FuseAnimate animation="transition.expandIn">
					<Card className="w-full max-w-384 rounded-8">
						<CardContent className="flex flex-col items-center justify-center p-32">
							<img className="w-128 m-32" src="assets/images/logos/ourspace_logo.svg" alt="logo" />

							<Typography variant="h6" className="mt-16 mb-32">
								RESET YOUR PASSWORD
							</Typography>
							<p>
								Passwords must be at least 8 characters long and contain at least one uppercase letter and one number
							</p>
							{ !isLinkValid && (
								<div className="flex flex-col items-center justify-center pt-32 pb-24">
										<h3 className={classes.confirmation}>This is not a valid password reset link.</h3>
								</div>
							)}
							{ isServerError && (
								<div className="flex flex-col items-center justify-center pt-32 pb-24">
										<h3 className={classes.confirmation}>{serverError}</h3>
								</div>
							)}
							{ isSuccess && (
								<div className="flex flex-col items-center justify-center pt-32 pb-24">
										<h3 className={classes.confirmation}>Your password has been successfully reset, you can now login with the new password</h3>
								</div>
							)}
							{ isLinkValid && (
								<form
									name="resetForm"
									noValidate
									className="flex flex-col justify-center w-full"
									onSubmit={handleSubmit}
								>
									<TextField
										className="mb-16"
										label="Password"
										type="password"
										name="password"
										value={form.password}
										onChange={handleChange}
										variant="outlined"
										required
										fullWidth
									/>

									<TextField
										className="mb-16"
										label="Password (Confirm)"
										type="password"
										name="confirmpassword"
										value={form.confirmpassword}
										onChange={handleChange}
										variant="outlined"
										required
										fullWidth
									/>

									<Button
										variant="contained"
										color="primary"
										className="w-224 mx-auto mt-16"
										aria-label="Reset"
										disabled={!isFormValid()}
										type="submit"
									>
										RESET MY PASSWORD
									</Button>
								</form>
							)}

							<div className="flex flex-col items-center justify-center pt-32 pb-24">
								<Link className="font-medium" to="/login">
									Go back to login
								</Link>
							</div>
						</CardContent>
					</Card>
				</FuseAnimate>
			</div>
		</div>
	);
}

export default ResetPasswordPage;
