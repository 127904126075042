import FuseNavigation from '@fuse/core/FuseNavigation';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation } from 'app/store/fuse/navigationSlice';

function OurspaceUserNavigation(props) {
	const navigation = useSelector(selectNavigation);
	const dispatch = useDispatch();
	const { handleChange } = props;

	return (
		<FuseNavigation
			className={clsx('navigation', props.className)}
			navigation={navigation}
			layout="horizontal"
			dense={true}
			active={true}
			onChange={handleChange}
		/>
	);
}

OurspaceUserNavigation.defaultProps = {
	layout: 'horizontal'
};

export default React.memo(OurspaceUserNavigation);
