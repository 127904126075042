import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

const MatchOwnersAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.superuser,
	routes: [
		{
			path: '/apps/matchOwners',
			component: React.lazy(() => import('./MatchOwnersApp'))
		},
	]
};

export default MatchOwnersAppConfig;
