import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { updateUserNotes } from '../../../auth/store/userSlice';
import { getCatchupByMatch } from '../catchups/store/catchupsSlice';
import moment from 'moment';
import { showMessage } from '../../../store/fuse/messageSlice';
import { selectMessages } from './store/messagesSlice';

const bgImg = 'assets/images/site/side_panel_purple_261px.png';

const useStyles = makeStyles(theme => ({
  root: {
  },
  sidebarcontainer: {
    width: '261px',
    height: '356px',
    minWidth: '261px',
    minHeight: '356px',
    backgroundImage: `url(${bgImg})`,
    padding: '16px',
    color: '#000000',
    marginBottom: '26px',
    marginTop: '26px'
  },
  purpletext: {
    color: '#521B5F !important',
    fontWeight: 'bold'
  },
  sidebarhr: {
    color: '#521B5F !important',
    borderColor: '#521B5F !important',
    marginTop: '6px',
    marginBottom: '6px'
  },
  sidebarbody: {
    paddingTop: '6px',
    fontSize: '12px',
    fontWeight: 'regular',
  },
  messageContainer: {
    maxHeight: '80px',
    overflow: 'hidden'
  },
  messageTitle: {
    fontWeight: 'regular',
    cursor: 'pointer',
    color: '#000000',
    '&:hover': {
       color: '#521B5F',
    },
  },
  messageTitleUnread: {
    fontWeight: 'bold !important',
    cursor: 'pointer',
    color: '#000000',
    '&:hover': {
       color: '#521B5F',
    },
  },
  messageSummary: {
    fontWeight: 'regular',
    cursor: 'pointer',
    color: '#000000',
    '&:hover': {
       color: '#521B5F',
    },
  },
  messageSummaryUnread: {
    fontWeight: 'bold !important',
    cursor: 'pointer',
    color: '#000000',
    '&:hover': {
       color: '#521B5F',
    },
  }
}));

const isBig = (userRole) => userRole[0].toLowerCase() === 'big';

function MessagesListSidebar({ selectMessage }) {
	const classes = useStyles();
	const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const catchup = useSelector(({ feedApp }) => feedApp.chat.catchup);
  const notesRef = useRef(null);
  const messages = useSelector(selectMessages);
  const [isCatchupAvailable, setIsCatchupAvailable] = useState(false);
  const isMatchOnline = useSelector(({ auth }) => auth.socket.matchOnline);
  let isCatchupNow = true;

  const handleSaveNotes = (e) => {
    e.stopPropagation();
    dispatch(updateUserNotes({
      id: user.uuid,
      notes: notesRef.current.value
    }));
  }

  useEffect(() => {
	}, []);

	return (
			<FuseAnimate animation="transition.fadeIn">
				<div className={classes.sidebarcontainer}>
          <h4>Messages:</h4>
          <hr className={classes.sidebarhr}/>
          {messages.map((message, index) => (
            <div
              key={index}
              onClick={(e) => {
                  e.stopPropagation();
                  selectMessage(message);
              }}>
              <div className={classes.messageContainer} >
                <h3 className={message.bRead ? classes.messageTitle : classes.messageTitleUnread}>{message.subject}</h3>
                <p className={message.bRead ? classes.messageSummary : classes.messageSummaryUnread} dangerouslySetInnerHTML={{ __html: message.content }} />
              </div>
              <hr className={classes.sidebarhr} key={`${index}_hr`}/>
            </div>
          ))}
				</div>
			</FuseAnimate>
	);
}

MessagesListSidebar.propTypes = {
	messages: PropTypes.array,
  selectMessage: PropTypes.func
};

export default MessagesListSidebar;
