import { configureStore } from '@reduxjs/toolkit';
import createReducer from './rootReducer';

if (process.env.NODE_ENV === 'development' && module.hot) {
	module.hot.accept('./rootReducer', () => {
		const newRootReducer = require('./rootReducer').default;
		store.replaceReducer(newRootReducer.createReducer());
	});
}

const middlewares = [];

if (process.env.NODE_ENV === 'development') {
	const { logger } = require(`redux-logger`);

	middlewares.push(logger);
}

const store = configureStore({
	reducer: createReducer(),
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: {
				ignoredActions: [
					'dialog/openDialog',
					'dialog/closeDialog',
					'message/showMessage',
					'message/hideMessage',
					'catchupsApp/chat/setSocketInstance',
					'catchupsApp/chat/startCatchup',
					'catchupsApp/chat/hangupCatchup',
					'catchupsApp/chat/getCatchupByMatch',
					'catchupsApp/chat/getRoomID',
					'catchupsApp/chat/setRoomID',
					'catchupsApp/chat/setSocketInstance',
				  'catchupsApp/chat/setIsCatchupLive',
				  'catchupsApp/chat/setIsCatchupAudioOn',
				  'catchupsApp/chat/setIsCatchupVideoOn',
				  'catchupsApp/chat/setIsTextChatOpen',
					'catchupsApp/chat/setIsCatchupVisible',
				  'catchupsApp/chat/toggleCatchupAudio',
				  'catchupsApp/chat/toggleCatchupVideo',
				  'catchupsApp/chat/toggleTextChatOpen',
				  'catchupsApp/chat/hangupCatchup',
					'catchupsApp/chat/startCatchup',
					'catchupsApp/chatMsgs/getTextChatMsgsByCatchup/pending',
					'catchupsApp/chatMsgs/getTextChatMsgsByCatchup/fulfilled',
					'catchupsApp/chatMsgs/saveTextChatMsg/pending',
					'catchupsApp/chatMsgs/saveTextChatMsg/fulfilled',
					'catchupsApp/chatMsgs/sendTextChatMessage/pending',
					'catchupsApp/chatMsgs/sendTextChatMessage/fulfilled',
					'catchupsApp/chat/sendTextMessage/pending',
					'catchupsApp/chat/sendTextMessage/fulfilled',
					'auth/socket/setMatchOnline',
					'auth/socket/setMatchOnline/pending',
					'auth/socket/setMatchOnline/fulfilled',
					'catchupsApp/chat/registerCatchupInProgress',
					'catchupsApp/chat/registerCatchupInProgress/pending',
					'catchupsApp/chat/registerCatchupInProgress/fulfilled',
					'catchupsApp/chat/hangupCatchup',
					'catchupsApp/chat/hangupCatchup/pending',
					'catchupsApp/chat/hangupCatchup/fulfilled',
					'feedApp/catchupRequests/openEditCatchupRequestDialog',
					'matchesApp/catchups/getCatchupByMatch',
					'matchesApp/catchups/getCatchupByMatch/pending',
					'matchesApp/catchups/getCatchupByMatch/fulfilled',
					'adminCatchupsApp/chatMsgs/getTextChatMsgsByCatchup',
					'adminCatchupsApp/chatMsgs/getTextChatMsgsByCatchup/fulfilled',
					'adminCatchupsApp/chatMsgs/getTextChatMsgsByCatchup/pending',

				]
			}
		}).concat(middlewares),
	devTools: process.env.NODE_ENV === 'development'
});

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return false;
	}
	store.asyncReducers[key] = reducer;
	store.replaceReducer(createReducer(store.asyncReducers));
	return store;
};

export default store;
