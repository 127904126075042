import { useForm } from "@fuse/hooks";
import FuseUtils from "@fuse/utils/FuseUtils";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import _ from "@lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCatchupRequest,
  updateCatchupRequest,
  cancelCatchupRequest,
  closeNewCatchupRequestDialog,
  closeEditCatchupRequestDialog,
} from "./store/catchupRequestsSlice";
import { getCatchupByMatch } from "../catchups/store/catchupsSlice";

const defaultFormState = {
  id: FuseUtils.generateGUID(),
  title: "",
  allDay: true,
  start: "",
  end: "",
  requestedStart_at: "",
  requestedEnd_at: "",
  desc: "",
  match_id: 0,
  requestor_id: 0,
  requestee_id: 0,
  requested_at: new Date(),
  bRequesteeAdjusted: false,
  sender_id: 0,
}; // TODO: add global date / time formats to settings for regions

const useStyles = makeStyles((theme) => ({
  invalidDateWarning: {
    color: "red",
    fontWeight: "bold",
  },
}));

function CatchupRequestDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user.data);
  const catchupRequestDialog = useSelector(
    ({ feedApp }) => feedApp.catchupRequests.catchupRequestDialog
  );
  const currentMatch = useSelector(({ auth }) => auth.user.data.match);

  const [invalidDateWarning, setInvalidDateWarning] = useState("");

  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (catchupRequestDialog.type === "edit" && catchupRequestDialog.data) {
      setForm({
        ...catchupRequestDialog.data,
        bRequesteeAdjusted: true,
        isNewTime: true,
        sender_id: user.id,
        // start: catchupRequestDialog.data.requestedStart_at,
        // end: catchupRequestDialog.data.requestedEnd_at,
      });
    }

    setInvalidDateWarning("");
    /**
     * Dialog type: 'new'
     */
    if (catchupRequestDialog.type === "new") {
      let defaultStart, defaultEnd;

      const now = moment().format("YYYY-MM-DD HH:mm");
      if (
        catchupRequestDialog.data.start &&
        moment(catchupRequestDialog.data.start).isAfter(moment(now))
      ) {
        defaultStart = moment
          .utc(catchupRequestDialog.data.start)
          .format("YYYY-MM-DD HH:mm:ss");
        defaultEnd = moment
          .utc(catchupRequestDialog.data.end)
          .format("YYYY-MM-DD HH:mm:ss");
      } else {
        defaultStart = moment().add(0, "hours").format("YYYY-MM-DD HH:mm:ss");
        defaultEnd = moment().add(1, "hours").format("YYYY-MM-DD HH:mm:ss");
      }

      setForm({
        ...defaultFormState,
        big_id: currentMatch.big.id,
        little_id: currentMatch.little.id,
        match: catchupRequestDialog.match,
        match_id: user.match.id,
        requestor_id: user.id,
        requestee_id:
          currentMatch.big.id === user.id
            ? currentMatch.little.id
            : currentMatch.big.id,
        requested_at: new Date(),
        isNewTime: false,
        sender_id: user.id,
        start: defaultStart,
        end: defaultEnd,
        requestedStart_at: defaultStart,
        requestedEnd_at: defaultEnd,
      });
    }
  }, [catchupRequestDialog.data, catchupRequestDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (catchupRequestDialog.props.open) {
      initDialog();
    }
  }, [catchupRequestDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return catchupRequestDialog.type === "edit"
      ? dispatch(closeEditCatchupRequestDialog())
      : dispatch(closeNewCatchupRequestDialog());
  }

  let catchupMinimumDate = new Date();
  catchupMinimumDate.setDate(catchupMinimumDate.getDate() - 1);

  function validateDates(startDate = form.start, endDate = form.end) {
    let valid = true;
    // check both dates in the future
    if (
      moment().subtract(1, "minute").isSameOrAfter(moment(startDate)) ||
      moment().subtract(1, "minute").isSameOrAfter(moment(endDate))
    ) {
      valid = false;
    }
    // check start date is before end date
    if (moment(startDate).isSameOrAfter(endDate)) {
      valid = false;
    }
    return valid;
  }

  function canBeSubmitted() {
    return validateDates() && form.match_id > 0;
  }

  function handleSubmit(catchup) {
    catchup.preventDefault();

    if (catchupRequestDialog.type === "new") {
      dispatch(addCatchupRequest(form));
    } else {
      dispatch(updateCatchupRequest(form));
    }
    closeComposeDialog();
  }

  function handleCancelCatchupRequest() {
    dispatch(cancelCatchupRequest(form));
    closeComposeDialog();
  }

  function handleStartDateChange(date) {
    setInForm("start", date.format("YYYY-MM-DD HH:mm:ss"));
    setInForm("end", date.add(1, "hour").format("YYYY-MM-DD HH:mm:ss"));
    if (
      !validateDates(date, date.add(1, "hour").format("YYYY-MM-DD HH:mm:ss"))
    ) {
      // setInvalidDateWarning(
      //   "Start date must be in the future, and End date must be after Start date"
      // );
    } else {
      setInvalidDateWarning("");
    }
  }

  function handleEndDateChange(date) {
    setInForm("end", date.format("YYYY-MM-DD HH:mm:ss"));
    if (!validateDates(form.start, date)) {
      setInvalidDateWarning(
        "Start date must be in the future, and End date must be after Start date"
      );
    } else {
      setInvalidDateWarning("");
    }
  }

  return (
    <Dialog
      {...catchupRequestDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="xs"
      component="form"
      classes={{
        paper: "rounded-8",
      }}
    >
      <AppBar position="static">
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {catchupRequestDialog.type === "edit"
              ? "Change Catchup Request Time"
              : "Request a Catchup"}
          </Typography>
        </Toolbar>
      </AppBar>

      <form noValidate onSubmit={handleSubmit}>
        <DialogContent classes={{ root: "p-16 pb-0 sm:p-24 sm:pb-0" }}>
          <DateTimePicker
            label="Start"
            inputVariant="outlined"
            value={form.start}
            onChange={handleStartDateChange}
            className="mt-8 mb-16 w-full"
            minDate={catchupMinimumDate}
          />

          <DateTimePicker
            label="End"
            inputVariant="outlined"
            value={form.end}
            onChange={handleEndDateChange}
            className="mt-8 mb-16 w-full"
            minDate={catchupMinimumDate}
          />

          <div>
            <p className={classes.invalidDateWarning}>{invalidDateWarning}</p>
          </div>
        </DialogContent>

        <DialogActions className="justify-between px-8 sm:px-16">
          {catchupRequestDialog.type === "edit" && (
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                handleCancelCatchupRequest();
              }}
            >
              Cancel Catchup
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!canBeSubmitted()}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CatchupRequestDialog;
