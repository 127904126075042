import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + 'users';

export const getUserData = createAsyncThunk('usersApp/user/getUserData', async (user_id) => {
	const response = await axios.get(API_URL + '/' + user_id);
	const data = await response.data;
	return data;
});

export const emailTakenCheck = createAsyncThunk('usersApp/user/emailTakenCheck', async (email) => {
	const response = await axios.post(API_URL + '/emailTakenCheck', { emailAddress: email });
	const data = await response.data;
	return data;
});

const userSlice = createSlice({
	name: 'usersApp/user',
	initialState: {},
	reducers: {},
	extraReducers: {
		[getUserData.fulfilled]: (state, action) => action.payload
	}
});

export default userSlice.reducer;
