import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "180px",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "200px",
    },

    width: "200px",
    height: "300px",
    maxHeight: "300px",
    margin: "6px",
  },
  media: {
    height: 140,
    position: "relative",
  },
  tab: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  postTitle: {
    fontSize: "12px",
    fontWeight: "bold",
  },
}));

function ExplorePostCard({ post, handlePostSelection }) {
  const classes = useStyles();
  let postContent = post.text ? post.text.substr(0, 100) : "";
  if (post.text && post.text.length > 100) {
    postContent += "...";
  }
  let imageUrl = "";
  if (post.image && post.image.url) {
    imageUrl = `assets/uploads/postImages/${post.image.url}`;
  }

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={imageUrl}
          title={post.title}
          onClick={(e) => {
            e.stopPropagation();
            handlePostSelection(post.id);
          }}
        />
        {post.post_type.name !== "Default" && (
          <img
            className={classes.tab}
            src={`assets/images/explore/${post.post_type.siteLabel.toLowerCase()}_icon_trimmed.svg`}
            onClick={(e) => {
              e.stopPropagation();
              handlePostSelection(post.id);
            }}
          />
        )}
        <CardContent
          onClick={(e) => {
            e.stopPropagation();
            handlePostSelection(post.id);
          }}
        >
          <h4 className={classes.postTitle}>{post.title}</h4>
          <div dangerouslySetInnerHTML={{ __html: postContent }} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

ExplorePostCard.propTypes = {
  post: PropTypes.object,
  handlePostSelection: PropTypes.func.isRequired,
};

export default ExplorePostCard;
