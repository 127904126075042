import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
// import _ from 'lodash';

const quality = 100;
const initialState = {
	routeParams: {}
}

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + 'activities';
const API_URL_COMMENTS = process.env.REACT_APP_SERVER_API_URL + 'comments';

export const getActivityFeed = createAsyncThunk('exploreApp/activities/getActivityFeed', async (match_id, { getState }) => {
	const response = await axios.get(API_URL + '/getByMatch/' + match_id);
	const data = await response.data;

	return { data };
});

export const addActivity = createAsyncThunk('exploreApp/activities/addActivity', async (activity, { dispatch, getState }) => {
	const response = await axios.post(API_URL, activity);
	const data = await response.data;
  dispatch(getActivityFeed(activity.match_id));
	return { data };
});

export const addImageActivity = createAsyncThunk('exploreApp/activities/addImageActivity', async (activity, { dispatch, getState }) => {
  let formData = new FormData();
  formData.append("match_id", activity.match_id);
  formData.append("user_id", activity.user_id);
  formData.append("activity_type_id", 1);
  formData.append("imageUpload", activity.imageUpload);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  const response = await axios.post(
    API_URL,
    formData,
    config
  );
  const data = await response.data;

  dispatch(getActivityFeed(activity.match_id));
	return { data };
});



export const addActivityComment = createAsyncThunk('exploreApp/activities/addActivityComment', async (comment, { dispatch, getState }) => {
	const response = await axios.post(API_URL_COMMENTS + '/' + comment.activity_id, comment);
	const data = await response.data;
	dispatch(getActivityFeed(comment.match_id));
	return { data };
});

export const addImageActivityComment = createAsyncThunk('exploreApp/activities/addImageActivityComment', async (comment, { dispatch, getState }) => {
  let formData = new FormData();
  formData.append("user_id", comment.user_id);
  formData.append("activity_id", comment.activity_id);
  formData.append("imageUpload", comment.imageUpload);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  const response = await axios.post(
    API_URL_COMMENTS + '/' + comment.activity_id,
    formData,
    config
  );
  const data = await response.data;

  dispatch(getActivityFeed(comment.match_id));
	return { data };
});


const activitiesAdapter = createEntityAdapter({});

export const { selectAll: selectActivities, selectById: selectActivityById } = activitiesAdapter.getSelectors(
	state => state.exploreApp.activities
);

const chatSlice = createSlice({
	name: 'exploreApp/activities',
	initialState: activitiesAdapter.getInitialState({
		routeParams: {}
	}),
	reducers: {
	},
	extraReducers: {
		[getActivityFeed.fulfilled]: (state, action) => {
			const { data, routeParams } = action.payload;
			activitiesAdapter.setAll(state, data);
			state.routeParams = routeParams;
		},
	}
});

// export const {
// } = chatSlice.actions;

export default chatSlice.reducer;
