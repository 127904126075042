import { createMuiTheme } from '@material-ui/core/styles';
import { getContrastRatio } from '@material-ui/core/styles/colorManipulator';
import navigationConfig from 'app/fuse-configs/navigationConfig';
import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import _ from '@lodash';
import {
	defaultThemes,
	mainThemeVariations,
	extendThemeWithMixins,
	mustHaveThemeOptions,
	defaultThemeOptions,
	getParsedQuerySettings,
	defaultSettings
} from '@fuse/default-settings';
import { setNavigation } from './navigationSlice';
import FuseSettingsConfig from 'app/fuse-configs/settingsConfig';
import FuseThemesConfig from 'app/fuse-configs/themesConfig';
import FuseLayoutConfigs from 'app/fuse-layouts/FuseLayoutConfigs';
import axios from 'axios';
import { authRoles } from 'app/auth';

const API_URL_FEATURE_CONTROL = process.env.REACT_APP_SERVER_API_URL + 'featureControls';

const featureControlsDefault = {
	restrictToMatchOwners: false,
	matchOwners: true,
	languageSwitcher: true,
	monitorCatchups: false,
	adminJoinCatchus: true,
	catchupRequests: true,
	approveCatchupRequests: false
};

function getInitialSettings() {
	const defaultLayoutStyle =
		FuseSettingsConfig.layout && FuseSettingsConfig.layout.style ? FuseSettingsConfig.layout.style : 'layout1';
	const layout = {
		style: defaultLayoutStyle,
		config: FuseLayoutConfigs[defaultLayoutStyle].defaults
	};

	return _.merge({}, defaultSettings, { layout }, FuseSettingsConfig, getParsedQuerySettings(), featureControlsDefault);
}

export function generateSettings(_defaultSettings, _newSettings) {
	const response = _.merge(
		{},
		_defaultSettings,
		_newSettings && _newSettings.layout && _newSettings.layout.style
			? { layout: { config: FuseLayoutConfigs[_newSettings.layout.style].defaults } }
			: {},
		_newSettings
	);

	/**
	 * Making theme values failsafe
	 */
	Object.entries(response.theme).forEach(([key, value]) => {
		if (value !== 'mainThemeDark' && value !== 'mainThemeLight' && !FuseThemesConfig[value]) {
			response.theme[key] = 'default';
		}
	});

	return response;
}

const getThemes = state => state.fuse.settings.themes;
const getDirection = state => state.fuse.settings.current.direction;
const getMainThemeId = state => state.fuse.settings.current.theme.main;
const getNavbarThemeId = state => state.fuse.settings.current.theme.navbar;
const getToolbarThemeId = state => state.fuse.settings.current.theme.toolbar;
const getFooterThemeId = state => state.fuse.settings.current.theme.footer;

function generateMuiTheme(themes, id, direction) {
	const data = _.merge({}, defaultThemeOptions, themes[id], mustHaveThemeOptions);
	const response = createMuiTheme(
		_.merge({}, data, {
			mixins: extendThemeWithMixins(data),
			direction
		})
	);
	return response;
}

export const selectFuseThemeById = id =>
	createSelector([getThemes, getDirection], (themes, direction) => generateMuiTheme(themes, id, direction));

export const selectContrastMainTheme = bgColor => {
	function isDark(color) {
		return getContrastRatio(color, '#ffffff') >= 3;
	}
	return isDark(bgColor) ? selectMainThemeDark : selectMainThemeLight;
};

export const selectMainTheme = createSelector([getThemes, getDirection, getMainThemeId], (themes, direction, id) =>
	generateMuiTheme(themes, id, direction)
);

export const selectMainThemeDark = createSelector([getThemes, getDirection], (themes, direction, id) =>
	generateMuiTheme(themes, 'mainThemeDark', direction)
);
export const selectMainThemeLight = createSelector([getThemes, getDirection], (themes, direction, id) =>
	generateMuiTheme(themes, 'mainThemeLight', direction)
);

export const selectNavbarTheme = createSelector([getThemes, getDirection, getNavbarThemeId], (themes, direction, id) =>
	generateMuiTheme(themes, id, direction)
);

export const selectToolbarTheme = createSelector(
	[getThemes, getDirection, getToolbarThemeId],
	(themes, direction, id) => generateMuiTheme(themes, id, direction)
);

export const selectFooterTheme = createSelector([getThemes, getDirection, getFooterThemeId], (themes, direction, id) =>
	generateMuiTheme(themes, id, direction)
);

const themesObjRaw = Object.keys(FuseThemesConfig).length !== 0 ? FuseThemesConfig : defaultThemes;
const initialSettings = getInitialSettings();
const initialThemes = {
	...themesObjRaw,
	...mainThemeVariations(themesObjRaw[initialSettings.theme.main])
};

export const getFeatureControls = createAsyncThunk('navigation/featureControls/getFeatureControls', async (routeParams, { dispatch, getState }) => {
	const response = await axios.get(API_URL_FEATURE_CONTROL + '/map');
	const data = await response.data;
	dispatch(setNavigation(updateNavigationByFeatureControls(data)));
	return { data, routeParams };
});

const updateNavigationByFeatureControls = (controlMap) => {
	let tempNav = _.cloneDeep(navigationConfig);
	if (controlMap.matchOwners.isEnabled) {
		for (let i=0; i<tempNav.length; i++) {
			if (tempNav[i].id === 'admin.system') {
				tempNav[i].children.splice(i, 0, {
					id: 'admin.system.matchOwners',
					title: 'Match Owners',
					translate: 'ADMIN_NAV_ADMIN_MATCHOWNERS',
					type: 'item',
					auth: authRoles.superuser,
					icon: 'security',
					url: '/apps/matchOwners',
				})
			}
		}
	}
	return tempNav;
}

const initialState = {
	initial: initialSettings,
	defaults: _.merge({}, initialSettings),
	current: _.merge({}, initialSettings),
	themes: initialThemes,
	isReturningToCatchup: false
};

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setSettings: (state, action) => {
			const current = generateSettings(state.defaults, action.payload);
			const themes =
				current.theme.main !== state.current.theme.main
					? { ...state.themes, ...mainThemeVariations(themesObjRaw[current.theme.main]) }
					: state.themes;
			return {
				...state,
				current,
				themes
			};
		},
		setDefaultSettings: (state, action) => {
			const defaults = generateSettings(state.defaults, action.payload);
			const themes =
				defaults.theme.main !== state.defaults.theme.main
					? { ...state.themes, ...mainThemeVariations(themesObjRaw[defaults.theme.main]) }
					: state.themes;
			return {
				...state,
				defaults: _.merge({}, defaults),
				current: _.merge({}, defaults),
				themes
			};
		},
		setInitialSettings: (state, action) => {
			return _.merge({}, initialState);
		},
		resetSettings: (state, action) => {
			const themes = {
				...state.themes,
				...mainThemeVariations(themesObjRaw[state.defaults.theme.main])
			};
			return {
				...state,
				defaults: _.merge({}, state.defaults),
				current: _.merge({}, state.defaults),
				themes
			};
		},
		setIsReturningToCatchup: (state, action) => {
			return {
				...state,
				isReturningToCatchup: action.payload
			};
		}
	},
	extraReducers: {
		[getFeatureControls.fulfilled]: (state, action) => {
			const { data, routeParams } = action.payload;
			state.featureControls = data;

		},
	}
});

export const {
	resetSettings,
	setDefaultSettings,
	setInitialSettings,
	setIsReturningToCatchup,
	setSettings,

} = settingsSlice.actions;

export default settingsSlice.reducer;
