import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {
  setCatchupWhiteBoardOff,
  closeWhiteBoardDialog,
} from "../store/catchupsSlice";
import Swatch from "./Swatch";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "1302 !important",
  },
  whiteboardCanvas: {
    backgroundColor: "#FFFFFF !important",
    [theme.breakpoints.down("sm")]: {
      minHeight: "250px !important",
      maxHeight: "600px !important",
      height: "calc(100vh - 230px) !important",
      width: "100% !important",
      maxWidth: "100% !important",
    },
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "#000000",
  },
  contentBox: {
    padding: 0,
    margin: 0,
    overflow: "hidden",
  },
}));

function WhiteBoard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector(({ auth }) => auth.user);
  const whiteBoardDialog = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.whiteBoardDialog
  );

  const [highlightTool, setHighlightTool] = useState("pencil");
  const [activeShape, setActiveShape] = useState(null);
  const [isCanvasSet, setIsCanvasSet] = useState(false);
  const [switchColor, setSwitchColor] = useState("#000000");

  let activeTool = useRef("pencil");
  let color = useRef("#000000");
  let canvas = null;
  let ctx = null;
  let offsets = null;
  let isOverCanvas = false;
  let lastPoint = null;
  let originPoint = null;
  let force = 1;
  let mouseDown = null;

  const broadcast = (content) => {
    const { sendDataMessageToRoom } = window.socketInstance;
    sendDataMessageToRoom(content);
  };

  const resize = () => {
    let canvasHeight = 600;
    let canvasWidth = 900;

    if (window.innerWidth < 900 || window.innerHeight < 600) {
      canvasWidth = document.getElementById("whiteboard").offsetWidth;
      canvasHeight = document.getElementById("whiteboard").offsetHeight;
    }

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
  };

  const draw = (data) => {
    ctx.beginPath();
    ctx.moveTo(
      data.lastPoint.x * canvas.width,
      data.lastPoint.y * canvas.height
    );

    ctx.lineTo(data.x * canvas.width, data.y * canvas.height);
    ctx.strokeStyle = data.color;

    let width = 2;
    if (data.tool === "pencil") {
      width = 2;
    } else if (data.tool === "eraser") {
      width = 40;
    }

    ctx.lineWidth = Math.pow(data.force || 1, 4) * width;

    ctx.lineCap = "round";
    ctx.stroke();
    ctx.closePath();
  };

  const drawRect = (data, commit) => {
    ctx.strokeStyle = data.color;
    ctx.strokeRect(
      data.origin.x * canvas.width,
      data.origin.y * canvas.width,
      data.width * canvas.width,
      data.height * canvas.height
    );
    setActiveShape(data);
  };

  const onEnter = (e) => {
    isOverCanvas = true;
  };

  const onLeave = (e) => {
    isOverCanvas = false;
  };

  const move = (e) => {
    e = getTouchMouseCoords(e);
    mouseDown = e.buttons;

    if (e.buttons || (e.touches && e.touches.length && e.touches.length > 0)) {
      if (!lastPoint) {
        lastPoint = {
          x: e.offsetX / canvas.width,
          y: e.offsetY / canvas.height,
        };
        originPoint = {
          x: e.offsetX / canvas.width,
          y: e.offsetY / canvas.height,
        };
        return;
      }

      if (activeTool.current === "pencil" || activeTool.current === "eraser") {
        draw({
          lastPoint,
          x: e.offsetX / canvas.width,
          y: e.offsetY / canvas.height,
          force: force,
          color: color.current,
          tool: activeTool.current,
        });

        broadcast(
          JSON.stringify({
            event: "draw",
            lastPoint,
            x: e.offsetX / canvas.width,
            y: e.offsetY / canvas.height,
            force: force,
            color: color.current,
            tool: activeTool.current,
          })
        );
      } else if (activeTool.current === "rect") {
        let origin = {
          x: Math.min(originPoint.x, e.offsetX),
          y: Math.min(originPoint.y, e.offsetY),
        };

        drawRect({
          origin: origin,
          color: color.current,
          width: Math.abs(originPoint.x - e.offsetX) / canvas.width,
          height: Math.abs(originPoint.y - e.offsetY) / canvas.height,
          tool: activeTool.current,
        });

        broadcast(
          JSON.stringify({
            event: "drawRect",
            origin: origin,
            color: color.current,
            width: Math.abs(originPoint.x - e.offsetX) / canvas.width,
            height: Math.abs(originPoint.y - e.offsetY) / canvas.height,
            tool: activeTool.current,
          })
        );
      }

      lastPoint = {
        x: e.offsetX / canvas.width,
        y: e.offsetY / canvas.height,
      };
    } else {
      lastPoint = undefined;
    }
  };

  function getTouchMouseCoords(e) {
    let posx = 0;
    let posy = 0;

    if (!e) var e = window.event;

    if (
      e.targetTouches &&
      e.targetTouches.length &&
      e.targetTouches.length > 0
    ) {
      if (canvas) {
        const rect = canvas.getBoundingClientRect();

        if (e.targetTouches[0].pageX || e.targetTouches[0].pageY) {
          posx = e.targetTouches[0].pageX - rect.left;
          posy = e.targetTouches[0].pageY - rect.top;
        } else if (e.targetTouches[0].clientX || e.targetTouches[0].clientY) {
          posx = e.targetTouches[0].clientX - rect.left;
          posy = e.targetTouches[0].clientY - rect.top;
        }

        e.offsetX = posx;
        e.offsetY = posy;
      }
    }

    return e;
  }

  const down = (e) => {
    e = getTouchMouseCoords(e);
    originPoint = { x: e.offsetX, y: e.offsetY };
  };

  const up = () => {
    if (activeShape) {
      drawRect(activeShape, true);
      broadcast(
        JSON.stringify(
          Object.assign(
            {
              event: "drawRect",
              commit: true,
            },
            activeShape
          )
        )
      );
      // activeShape = undefined;
      setActiveShape(null);
    }
    lastPoint = undefined;
    originPoint = undefined;
  };

  const forceChanged = (e) => {
    force = e.webkitForce || 1;
  };

  const recursiveOffsetLeftAndTop = (element) => {
    var offsetLeft = 0;
    var offsetTop = 0;

    if (window.innerWidth < 900) {
      offsetLeft = 8;
    } else {
      offsetLeft = (window.innerWidth - 900) / 2;
    }

    if (window.innerHeight < 752.5) {
      offsetTop = 0;
    } else {
      offsetTop = (window.innerHeight - 600) / 2;
    }

    return {
      offsetLeft: offsetLeft,
      offsetTop: offsetTop,
    };
  };

  // 1309 x 917

  const setupCanvas = (canvasBlockElem) => {
    if (canvasBlockElem) {
      canvas = document.querySelector("canvas");
      offsets = recursiveOffsetLeftAndTop(canvas);
      ctx = canvas.getContext("2d");

      let canvasHeight = 600;
      let canvasWidth = 900;

      if (window.innerWidth < 960 || window.innerHeight < 817) {
        canvasWidth = document.getElementById("whiteboard").offsetWidth;
        canvasHeight = document.getElementById("whiteboard").offsetHeight;
      }

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      setIsCanvasSet(true);
    }
  };

  const saveToImage = () => {
    const link = document.createElement("a");
    link.download = "ourspace-whiteboard.png";
    link.href = canvas.toDataURL();
    link.click();
    link.remove();
  };

  const oldSettings = {};

  const setupListeners = () => {
    window.onresize = resize;
    window.onmousedown = down;
    window.onmousemove = move;
    window.onmouseup = up;
    // window.onkeydown = key;

    window.addEventListener("touchstart", down, false);
    window.addEventListener("touchend", up, false);
    window.addEventListener("touchcancel", up, false);
    window.addEventListener("touchmove", move, false);

    canvas.onmouseleave = onLeave;
    canvas.onmouseover = onEnter;

    window.socketInstance.settings.params.handleWhiteBoardData = (data) => {
      let msg = JSON.parse(data);
      if (msg.event === "draw") {
        draw(msg);
      } else if (msg.event === "drawRect") {
        drawRect(msg);
      } else if (msg.event === "clear") {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    };

    window.socketInstance.myPeer.on("data", (data) => {
      console.log("yay!");
    });

    window.onwebkitmouseforcechanged = forceChanged;
    resize();
  };

  const removeListeners = () => {
    window.onresize = oldSettings.onresize;
    window.onmousedown = oldSettings.onmousedown;
    window.onmousemove = oldSettings.onmousemove;
    window.onmouseup = oldSettings.onmouseup;
    window.onkeydown = oldSettings.onkeydown;
  };

  const setupPeerConnections = () => {};

  const removePeerConnections = () => {};

  const startSetup = (elem) => {
    if (!isCanvasSet) {
      setupCanvas(elem);
      setupListeners();
      setupPeerConnections();

      const tctx = document.querySelector("canvas").getContext("2d");
      tctx.fillStyle = "#ffffff";
      tctx.fillRect(0, 0, 900, 600);
    }
  };

  const initDialog = () => {};

  const handleSwatchClick = (e, newColor) => {
    color.current = newColor;
    setSwitchColor(newColor);
  };

  const handleCloseClick = (e) => {
    e.preventDefault();
    const { moveVideosToMainPage } = window.socketInstance;
    moveVideosToMainPage();
    dispatch(closeWhiteBoardDialog());
    // tell the remotes to close their whiteboards
    window.ourspaceUserSocket.emit("toggleWhiteBoard", {
      sender_id: user.data.id,
      toggle: false,
    });
  };

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (whiteBoardDialog.props.open) {
      initDialog();
    }
  }, [whiteBoardDialog.props.open, initDialog]);

  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      removeListeners();
      removePeerConnections();
    };
  }, []);

  return (
    <Dialog
      open={whiteBoardDialog.props.open}
      onClose={(ev) => dispatch(setCatchupWhiteBoardOff())}
      aria-labelledby="fuse-dialog-title"
      maxWidth="lg"
      classes={{
        paper: "rounded-8 m-8 w-100",
      }}
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-slide-title">{"WhiteBoard"}</DialogTitle>
      <DialogContent className={classes.contentBox}>
        <div className="flush vstack">
          <div className="menubar hstack">
            <a
              className="icon-link active center"
              style={{ color: highlightTool === "pencil" ? "#34B233" : "" }}
              data-tool="pencil"
              onClick={() => {
                setHighlightTool("pencil");
                activeTool.current = "pencil";
                setSwitchColor("#000000");
                color.current = "#000000";
              }}
            >
              <i className="ri-lg ri-pencil-fill"></i>
            </a>
            <a
              className="icon-link active center"
              data-tool="eraser"
              style={{
                color: highlightTool === "eraser" ? "#34B233" : "",
              }}
              onClick={() => {
                setHighlightTool("eraser");
                activeTool.current = "eraser";
                setSwitchColor("#ffffff");
                color.current = "#ffffff";
              }}
            >
              <i className="ri-lg ri-eraser-fill"></i>
            </a>
            <div className="spacer"></div>
            <Swatch
              color={switchColor}
              handleSwatchChange={handleSwatchClick}
            />
          </div>
          <div ref={(elem) => startSetup(elem)} className="spacer app">
            <canvas
              className={clsx(classes.whiteboardCanvas, "whiteboard-canvas")}
              id="whiteboard"
              ref={canvas}
            ></canvas>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          type="secondary"
          onClick={saveToImage}
        >
          Save
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={handleCloseClick}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default WhiteBoard;
