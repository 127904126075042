/**
 * Authorization Roles
 */
const authRoles = {
  admin: ["admin", "superuser"],
  superuser: ["superuser"],
  staff: ["staff", "superuser", "admin"],
  big: ["big"],
  little: ["little"],
  user: ["user", "little", "big"],
  all: ["user", "little", "big", "staff", "superuser", "admin"],
  onlyGuest: [],
};

export default authRoles;
