import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import i18next from 'i18next';

// import _ from 'lodash';

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL + 'postTypes';

export const getPostTypes = createAsyncThunk('postTypesApp/postTypes/getPostTypes', async (routeParams, { getState }) => {
	routeParams = routeParams || getState().postTypesApp.postTypes.routeParams;
	const response = await axios.get(API_URL);

	const data = await response.data;
	return { data, routeParams };
});

export const removePostType = createAsyncThunk('postTypesApp/postTypes/removePostType', async (postTypeId, { dispatch, getState }) => {
		const response = await axios.delete(API_URL + '/' + postTypeId);
		const data = await response.data;
		dispatch(getPostTypes());
		return data;
	}
);

export const addPostType = createAsyncThunk('postTypesApp/postTypes/addPostType', async (postType, { dispatch, getState }) => {
		// remove autogen id
		delete postType.id;
		const response = await axios.post(API_URL, postType);
		const data = await response.data;
		dispatch(getPostTypes());
		return data;
	}
);

export const updatePostType = createAsyncThunk('postTypesApp/postTypes/updatePostType', async (postType, { dispatch, getState }) => {
		const response = await axios.put(API_URL + '/' + postType.id, postType);
		const data = await response.data;
		dispatch(getPostTypes());
		return data;
	}
);

const postTypesAdapter = createEntityAdapter({});

export const { selectAll: selectPostTypes, selectById: selectPostTypesById } = postTypesAdapter.getSelectors(
	state => state.postTypesApp.postTypes
);

const postTypesSlice	 = createSlice({
	name: 'postTypesApp/postTypes',
	initialState: postTypesAdapter.getInitialState({
		routeParams: {},
		postTypeDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		}
	}),
	reducers: {
		openNewPostTypeDialog: (state, action) => {
			state.postTypeDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: null
			};
		},
		closeNewPostTypeDialog: (state, action) => {
			state.postTypeDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: null
			};
		},
		openEditPostTypeDialog: (state, action) => {
			state.postTypeDialog = {
				type: 'edit',
				props: {
					open: true
				},
				data: action.payload
			};
		},
		closeEditPostTypeDialog: (state, action) => {
			state.postTypeDialog = {
				type: 'edit',
				props: {
					open: false
				},
				data: null
			};
		}
	},
	extraReducers: {
		[getPostTypes.fulfilled]: (state, action) => {
			const { data, routeParams } = action.payload;
			postTypesAdapter.setAll(state, data);
			state.routeParams = routeParams;
		},
		[addPostType.fulfilled]: postTypesAdapter.addOne,
		[updatePostType.fulfilled]: postTypesAdapter.upsertOne,
		[removePostType.fulfilled]: postTypesAdapter.removeOne
	}
});

export const {
	setPostTypesSearchText,
	openNewPostTypeDialog,
	closeNewPostTypeDialog,
	openEditPostTypeDialog,
	closeEditPostTypeDialog
} = postTypesSlice.actions;

export default postTypesSlice.reducer;
