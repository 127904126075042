import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hangupCatchup } from "app/main/apps/catchups/store/catchupsSlice";
import { logoutUser } from "app/auth/store/userSlice";
import FuseAuthorization from "@fuse/core/FuseAuthorization";
import _ from "lodash";
import history from "@history";

function UserMenu(props) {
  const dispatch = useDispatch();

  const user = useSelector(({ auth }) => auth.user);

  const isCatchupLive = useSelector(
    ({ catchupsApp }) => catchupsApp.catchups.isCatchupLive
  );

  let isOurspaceUser = true;
  if (user && user.role && user.role.length > 0) {
    isOurspaceUser =
      user.role[0].toLowerCase() === "little" ||
      user.role[0].toLowerCase() === "big"
        ? true
        : false;
  } else {
    isOurspaceUser = true;
  }
  const [userMenu, setUserMenu] = useState(null);
  const mailLink = isOurspaceUser ? "/userMessages" : "/apps/messages";
  const profileLink = isOurspaceUser ? "/userAccount" : "/adminProfile";

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
      >
        <div className=" md:flex flex-col mx-4 items-end">
          <Typography component="span" className="normal-case font-bold flex">
            {user.data.displayName}
          </Typography>
          <Typography className="text-11 capitalize" color="textSecondary">
            {user.role.toString()}
            {(!user.role ||
              (Array.isArray(user.role) && user.role.length === 0)) &&
              "Guest"}
          </Typography>
        </div>

        {user.data.photoURL ? (
          <Avatar
            className="md:mx-4"
            alt="user photo"
            src={`assets/images/avatars/${user.data.photoURL}`}
          />
        ) : (
          <Avatar className="md:mx-4">{user.data.displayName[0]}</Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {!user.role ? (
          <>
            <MenuItem component={Link} to="/login" role="button">
              <ListItemIcon className="min-w-40">
                <Icon>lock</Icon>
              </ListItemIcon>
              <ListItemText primary="Login" />
            </MenuItem>
            <MenuItem component={Link} to="/register" role="button">
              <ListItemIcon className="min-w-40">
                <Icon>person_add</Icon>
              </ListItemIcon>
              <ListItemText primary="Register" />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              component={Link}
              to={profileLink}
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <Icon>account_circle</Icon>
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={mailLink}
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <Icon>mail</Icon>
              </ListItemIcon>
              <ListItemText primary="Inbox" />
            </MenuItem>
            <MenuItem
              onClick={() => {
                userMenuClose();

                if (isCatchupLive) {
                  dispatch(hangupCatchup());
                }

                dispatch(logoutUser());
                history.push({
                  pathname: "/login",
                });
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}

export default UserMenu;
