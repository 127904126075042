import React from "react";
import { whiteboardSwatch } from "utils/constants";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  colorPickerContainer: {
    marginLeft: "-175px !important",
  },
}));

const Swatch = ({ handleSwatchChange, color }) => {
  const classes = useStyles();

  return (
    <div className="relative">
      <a className="icon-link center" data-color="#000000">
        <i className="ri-lg ri-palette-line"></i>
        <i
          className="ri-lg ri-checkbox-blank-fill center"
          style={{ color }}
        ></i>
      </a>
      <div
        id="color-picker"
        className={`${classes.colorPickerContainer} dropdown vstack `}
      >
        {whiteboardSwatch.map((row) => {
          return (
            <div className="hstack" key={JSON.stringify(row)}>
              {row.map((color) => {
                return (
                  <div
                    key={color}
                    className={`box color-${color.slice(1)}`}
                    style={{ backgroundColor: color }}
                    onClick={(e) => {
                      handleSwatchChange(e, color);
                    }}
                  ></div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Swatch;

// swatchContainer = document.querySelector("#color-picker");
//
// while (swatchContainer.hasChildNodes()) {
//     swatchContainer.removeChild(swatchContainer.lastChild);
// }
//
// swatch.forEach((row) => {
//     let rowElem = document.createElement("div");
//     rowElem.classList.add("hstack");
//
//     row.forEach((c) => {
//         let elem = document.createElement("div");
//         elem.classList.add("box");
//         elem.classList.add("color-" + c.substr(1));
//         elem.style.backgroundColor = c;
//
//         elem.onclick = function (e) {
//             colorPicker.dataset.color = c;
//             colorPicker.style.color = c;
//
//             if (colorElements[color]) {
//                 colorElements[color].classList.remove("active");
//             }
//
//             color = c;
//             elem.classList.toggle("active");
//             e.preventDefault();
//         };
//
//         colorElements[c] = elem;
//         rowElem.appendChild(elem);
//     });
//
//     swatchContainer.appendChild(rowElem);
// });
//
// colorIndex = randomColor();
// color = colorMap[colorIndex];
// colorPicker = document.querySelector("[data-color]");
// colorPicker.dataset.color = color;
// colorPicker.style.color = color;
// colorElements[color].classList.add("active");
