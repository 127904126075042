import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import FuseAnimate from "@fuse/core/FuseAnimate";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import TextChatMessage from "./TextChatMessage";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapper: {
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "100%",
    },

    [theme.breakpoints.up("md")]: {
      minWidth: "200px",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "100%",
    },

    [theme.breakpoints.up("md")]: {
      // height: '500px',
      // minHeight: '500px',
      minWidth: "200px",
    },
    overflowX: "hidden",
    overflowY: "auto",
  },
  textChatHeader: {
    color: "#521B5F",
    fontWeight: "bold",
    paddingBottom: "10px",
  },
  textChatLocalTitle: {
    color: "#521B5F",
    fontWeight: "bold",
  },
  textChatLocalTitle: {
    color: "#521B5F",
    fontWeight: "bold",
  },
  textChatRemoteTitle: {
    color: "#521B5F",
    fontWeight: "bold",
  },
  textChatTime: {
    color: "#BDBDBD",
    fontWeight: "bold",
  },
  textChatMessageBody: {
    color: "#000000",
    fontWeight: "bold",
  },
  chatColumn: {
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      minWidth: "300px",
    },
    overflow: "hidden",

    [theme.breakpoints.up("md")]: {
      minWidth: "300px",
    },
    padding: "8px",
  },
  closeIconButton: {
    padding: "0px",
    marginRight: "12px",
  },
}));

const isBig = (userRole) => userRole[0].toLowerCase() === "big";

function CatchupTextChat({
  messages = [],
  saveTextChatMessage,
  handleDrawerClose,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user.data);

  const textChatField = useRef(null);
  const textChatScrollableWindow = useRef(null);
  const isMobile = useMediaQuery("(max-width:647px)");

  useEffect(() => {
    const win = textChatScrollableWindow.current;
    win.scrollTop = win.scrollHeight;
  }, [messages]);

  return (
    <FuseAnimate animation="transition.fadeIn">
      <div className={clsx(classes.chatColumn, "flex flex-col sm:w-full")}>
        {/* chat header */}
        <div className="flex flex-row w-full items-start justify-start">
          {isMobile && (
            <IconButton
              className={classes.closeIconButton}
              onClick={handleDrawerClose}
              aria-label="close text chat"
            >
              <CloseIcon />
            </IconButton>
          )}
          <h4 className={classes.textChatHeader}>Chat</h4>
        </div>

        {/* messages list */}
        <div className={clsx(classes.wrapper, "flex flex-row")}>
          <div
            id="chat-container"
            ref={textChatScrollableWindow}
            className={clsx(
              classes.container,
              "flex flex-col w-full justify-start items-start"
            )}
          >
            {/* TODO: loop */}
            {messages.map((msg, index) => (
              <TextChatMessage
                user={msg.user}
                created_at={msg.created_at}
                msg={msg.text}
                key={msg.id}
              />
            ))}

            <hr id="chatscrollmarker" />
          </div>
        </div>

        {/* chat form */}
        <div className="flex flex-row">
          <div className="flex flex-col w-full chatform">
            <hr />
            <textarea ref={textChatField} width="190px" rows="2"></textarea>
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                saveTextChatMessage(textChatField.current.value);
                textChatField.current.value = "";
              }}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </FuseAnimate>
  );
}

CatchupTextChat.propTypes = {
  messages: PropTypes.array.isRequired,
  saveTextChatMessage: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func,
};

export default CatchupTextChat;
