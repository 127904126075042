import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/CameraAlt';

const useStyles = makeStyles(theme => ({
  root: {
  },
  form: {

  },
  replyMsgBox: {
    width: '100%',
    borderRadius: '5px',
    color: 'grey',
    fontSize: '16px',
    fontWeight: 'bold',
    padding:  '6px'
  },
  photoButton: {
    marginLeft: '6px',
    marginRight: '6px',
    backgroundColor: '#FFFFFF',
    textAlign: 'right'
  },
  replyButton: {
    backgroundColor: '#FFFFFF',
    textAlign: 'right'
  },
  statusMsgBox: {
    width: '100%',
    borderRadius: '5px',
    color: 'grey',
    fontSize: '16px',
    fontWeight: 'bold',
    padding:  '6px'
  },
  statusForm: {
    marginTop: '26px',
    marginBottom: '50px'
  }
}));

function ExplorePostCommentForm({ post_id, commentHandler, openImageDialog }) {
	const classes = useStyles();
  const replyMsgRef = useRef(null);

  return (
    <div className="flex flex-col items-end justify-items-end w-full">
      <div className="flex flex-row flex-grow items-end justify-items-end w-full">
        <TextareaAutosize
          ref={replyMsgRef}
          className={classes.replyMsgBox}
          aria-label="Write a Comment"
          placeholder="Write a comment..." />
      </div>
      <div className="flex flex-row flex-grow items-end justify-items-end">
        <Button
          variant="outlined"
          className={classes.photoButton}
          aria-label="Post an Image"
          onClick={(e) => {
            e.stopPropagation();
            openImageDialog(post_id);
          }}>
          <CameraIcon />
        </Button>
        <Button
          variant="outlined"
          className={classes.replyButton}
          onClick={(e) => {
            e.stopPropagation();
            commentHandler(replyMsgRef.current.value);
            replyMsgRef.current.value = null;
          }}>
          Comment
        </Button>
      </div>
    </div>
	);
}

ExplorePostCommentForm.propTypes = {
  post_id: PropTypes.number,
  commentHandler: PropTypes.func.isRequired,
  openImageDialog: PropTypes.func.isRequired,
};

export default ExplorePostCommentForm;
