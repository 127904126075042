import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import auth0Service from 'app/services/auth0Service';
import firebaseService from 'app/services/firebaseService';
import jwtService from 'app/services/jwtService';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import history from '@history';
import { initiateSiteSocket, registerUser, deRegisterUser } from './store/socketSlice';
import _ from 'lodash';
import { setUserDataFirebase, setUserDataAuth0, setUserData, logoutUser } from './store/userSlice';
import { getFeatureControls } from 'app/store/fuse/settingsSlice'

class Auth extends Component {
	state = {
		waitAuthCheck: true
	};

	componentDidMount() {
		return Promise.all([
			// Comment the lines which you do not use
			// this.firebaseCheck(),
			// this.auth0Check(),
			this.jwtCheck()
		]).then(() => {
			this.setState({ waitAuthCheck: false });
		});
	}

	jwtCheck = () =>
		new Promise(resolve => {
			jwtService.on('onAutoLogin', () => {
				// this.props.showMessage({ message: 'Logging in with JWT' });

				/**
				 * Sign in and retrieve user data from Api
				 */
				jwtService
					.signInWithToken()
					.then(user => {
						this.props.setUserData(user);
						// if (user.role_id === 2 || user.role_id === 3) {
							this.props.initiateSiteSocket(user);
						// }

						this.props.getFeatureControls();
						resolve();
						// this.props.showMessage({ message: 'Logged in with JWT' });
					})
					.catch(error => {
						this.props.showMessage({ message: error.message });

						resolve();
					});
			});

			jwtService.on('manualLogin', (user) => {
				// this.props.initiateSiteSocket(user);
				this.props.getFeatureControls();
			});

			jwtService.on('onAutoLogout', msg => {
				if (msg) {
					this.props.showMessage({ message: msg });
				}
				this.props.logout();

				resolve();
			});

			jwtService.on('onServerError', msg => {
				if (msg) {
					window.location.href = '/pages/errors/error-500';
					// history.push({
					// 	pathname: '/pages/errors/error-500',
					// 	state: { redirectUrl: history.location.pathname }
					// });
					// this.props.showMessage({ message: msg });
				}

				resolve();
			});

			// jwtService.on('onForbidden', msg => {
			// 	console.log('onForbidden');
			// 	if (msg) {
			// 		this.props.showMessage({ message: msg });
			// 	}
			// 	history.push({
			// 		pathname: '/login',
			// 		state: { redirectUrl: history.location.pathname }
			// 	});
			//
			// 	resolve();
			// });

			jwtService.on('onNoMatch', msg => {
				if (msg) {
					this.props.showMessage({ message: msg });
				}
				history.push({
					pathname: '/login',
					state: { redirectUrl: history.location.pathname }
				});

				resolve();
			});

			jwtService.on('onNoAccessToken', () => {
				const authExceptions = [
					'/forgot',
					'/reset',
					'/pages/auth/reset-password',
					'pages/errors/error-500'
				];
				if (history.location.pathname.substr(0,6) != '/reset' && _.indexOf(authExceptions, history.location.pathname) === -1 ) {
					history.push({
						pathname: '/login',
						state: { redirectUrl: history.location.pathname }
					});
				}
				resolve();
			});

			jwtService.init();

			return Promise.resolve();
		});

	auth0Check = () =>
		new Promise(resolve => {
			auth0Service.init(success => {
				if (!success) {
					resolve();
				}
			});

			if (auth0Service.isAuthenticated()) {
				this.props.showMessage({ message: 'Logging in with Auth0' });

				/**
				 * Retrieve user data from Auth0
				 */
				auth0Service.getUserData().then(tokenData => {
					this.props.setUserDataAuth0(tokenData);

					resolve();

					this.props.showMessage({ message: 'Logged in with Auth0' });
				});
			} else {
				resolve();
			}

			return Promise.resolve();
		});

	firebaseCheck = () =>
		new Promise(resolve => {
			firebaseService.init(success => {
				if (!success) {
					resolve();
				}
			});

			firebaseService.onAuthStateChanged(authUser => {
				if (authUser) {
					this.props.showMessage({ message: 'Logging in with Firebase' });

					/**
					 * Retrieve user data from Firebase
					 */
					firebaseService.getUserData(authUser.uid).then(
						user => {
							this.props.setUserDataFirebase(user, authUser);

							resolve();

							this.props.showMessage({ message: 'Logged in with Firebase' });
						},
						error => {
							resolve();
						}
					);
				} else {
					resolve();
				}
			});

			return Promise.resolve();
		});

	render() {
		return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>;
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: logoutUser,
			setUserData,
			setUserDataAuth0,
			setUserDataFirebase,
			showMessage,
			hideMessage,
			getFeatureControls,
			initiateSiteSocket,
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(Auth);
