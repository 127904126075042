import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

// TODO: globalise this
// const API_URL = 'https://ourspace2.bbbs.org.au/ourspace/backups';
const API_URL = process.env.REACT_APP_SERVER_API_URL;

export const getRoles = createAsyncThunk('usersApp/roles/getRoles', async () => {
	const response = await axios.get(API_URL + 'roles');
	const data = await response.data;

	return { data };
});

const rolesAdapter = createEntityAdapter({});

export const { selectAll: selectRoles, selectById: selectRolesById } = rolesAdapter.getSelectors(
	state => state.usersApp.roles
);

const rolesSlice = createSlice({
	name: 'usersApp/roles',
	initialState: rolesAdapter.getInitialState({}),
	reducers: {
	},
	extraReducers: {
		[getRoles.fulfilled]: (state, action) => {
			const { data } = action.payload;
			rolesAdapter.setAll(state, data);
		}
	}
});

// export const {
// } = rolesSlice.actions;

export default rolesSlice.reducer;
