import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import FuseAnimate from "@fuse/core/FuseAnimate";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import moment from "moment";

const downloadBG = "assets/images/explore/downloads_panel.png";
const linkBG = "assets/images/explore/links_panel.png";

const useStyles = makeStyles((theme) => ({
  root: {},
  linksContainer: {
    width: "261px",
    height: "200px",
    minWidth: "261px",
    backgroundImage: `url(${linkBG})`,
    padding: "16px",
    color: "#000000",
    fontWeight: "bold",
    backgroundRepeat: "no-repeat",
    marginTop: "100px",
  },
  downloadsContainer: {
    width: "261px",
    height: "200px",
    minWidth: "261px",
    backgroundImage: `url(${downloadBG})`,
    backgroundRepeat: "no-repeat",
    padding: "16px",
    color: "#000000",
    fontWeight: "bold",
  },
  purpletext: {
    color: "#521B5F !important",
    fontWeight: "bold",
  },
  sidebarhr: {
    color: "#521B5F !important",
    borderColor: "#521B5F !important",
    marginTop: "6px",
    marginBottom: "6px",
  },
  sidebarbody: {
    paddingTop: "6px",
    fontSize: "12px",
    fontWeight: "regular",
  },
  notesField: {
    width: "100%",
    padding: "6px",
  },
}));

const isBig = (userRole) => userRole[0].toLowerCase() === "big";

function ExploreSideBar({ links, downloads }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <FuseAnimate animation="transition.fadeIn">
      <div className="flex flex-col md:h-full items-start">
        <div className={clsx(classes.linksContainer, "")}>
          <h4>Links</h4>
          {links && (
            <ul>
              {links.map((link, index) => (
                <li key={index} className={classes.postLinkListItem}>
                  <a
                    className={classes.postLink}
                    href={link.url}
                    target="_blank"
                  >
                    {link.text}
                  </a>
                </li>
              ))}
            </ul>
          )}
          {!links && <p>No Links Available</p>}
        </div>

        <div className={clsx(classes.downloadsContainer, "")}>
          <h4>Downloads</h4>
          {downloads && (
            <ul>
              {downloads.map((download, index) => (
                <li key={index} className={classes.postDownloadListItem}>
                  <a className={classes.postDownload} href={download.url}>
                    {download.fileName}
                  </a>
                </li>
              ))}
            </ul>
          )}
          {!downloads && <p>No Downloads Available</p>}
        </div>
      </div>
    </FuseAnimate>
  );
}

ExploreSideBar.propTypes = {
  links: PropTypes.array,
  downloads: PropTypes.array,
};

export default ExploreSideBar;
