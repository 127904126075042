import openSocket from "socket.io-client";
import Peer from "peerjs";
import _ from "lodash";
import axios from "axios";
import adapter from "webrtc-adapter";

let peers = {};
let dcs = {};
let socketInstance;
let connectionRetryTimer;
let heartbeat;

const initializePeerConnection = (user_id) => {
  // host: process.env.REACT_APP_PEERJS_ENDPOINT, // need to provide peerjs server endpoint (something like localhost:9000)
  return new Peer(user_id, {
    host: process.env.REACT_APP_PEERJS_ENDPOINT,
    port: process.env.REACT_APP_PEERJS_PORT,
    config: {
      iceServers: [
        {
          urls: [
            process.env.REACT_APP_PEERJS_STUN_ENDPOINT,
            process.env.REACT_APP_PEERJS_TURN_ENDPOINT,
          ],
          username: "test",
          credential: "test123",
        },
      ],
      sdpSemantics: "unified-plan",
    },
    secure: true,
    debug: 3,
  });
};
const initializeSocketConnection = () => {
  // need to provide backend server endpoint (ws://localhost:5000) if ssl provided then (wss://localhost:5000)
  return openSocket.connect(process.env.REACT_APP_WEBSOCKET_ENDPOINT, {
    secure: true,
    reconnection: true,
    rejectUnauthorized: false,
    reconnectionAttempts: 10,
  });
};

// Older browsers might not implement mediaDevices at all, so we set an empty object first
if (navigator.mediaDevices === undefined) {
  navigator.mediaDevices = {};
}

// Some browsers partially implement mediaDevices. We can't just assign an object
// with getUserMedia as it would overwrite existing properties.
// Here, we will just add the getUserMedia property if it's missing.
if (navigator.mediaDevices.getUserMedia === undefined) {
  navigator.mediaDevices.getUserMedia = function (constraints) {
    // First get ahold of the legacy getUserMedia, if present
    var getUserMedia =
      navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

    // Some browsers just don't implement it - return a rejected promise with an error
    // to keep a consistent interface
    if (!getUserMedia) {
      return Promise.reject(
        new Error("getUserMedia is not implemented in this browser")
      );
    }

    // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
    return new Promise(function (resolve, reject) {
      getUserMedia.call(navigator, constraints, resolve, reject);
    });
  };
}

class Connection {
  videoContainer = {};
  message = [];
  settings;
  streaming = false;
  myPeer;
  socket;
  myID = "";
  sharedRoomID = "";
  isAdmin = false;
  isMonitoring = false;
  isRemoteAdminMonitoring = false;
  localVidContainerDOMObject = null;
  remoteVidContainerDOMObject = null;
  adminVidContainerDOMObject = null;
  localVidDOMObject = null;
  remoteVidDOMObject = null;
  adminVidDOMObject = null;
  roomIDs = {};
  amSharingScreen = false;
  isInProgress = false;
  onScreenShareEnd = null;
  isCatchupBackgrounded = false;
  adminID = 0;
  audioTracks = null;
  screenShareError = null;

  constructor(settings) {
    this.settings = settings;
    this.myID = settings.params.user_id;
    this.roomIDs[this.myID] = true;
    this.isAdmin = settings.params.isAdmin;
    this.isMonitoring = settings.params.isMonitoring;
    this.myPeer = initializePeerConnection(settings.params.user_id);
    this.socket = initializeSocketConnection();
    this.sharedRoomID = settings.params.roomID;
    this.onScreenShareEnd = settings.params.onScreenShareEnd;
    this.isCatchupBackgrounded = settings.params.isCatchupBackgrounded;

    if (this.settings.params.isAdmin) {
      this.admin_id = this.myID;
    }

    this.initializeSocketEvents();
    this.initializePeersEvents();
    this.beginHeartbeat();
  }

  initializeSocketEvents = () => {
    this.socket.on("connect", (conn) => {
      console.log("socket connected", conn);
    });

    this.socket.on("user-disconnected", (userID) => {
      console.log("user disconnected-- closing peers", userID);
      peers[userID] && peers[userID].close();
      this.removeVideo(userID);
      delete peers[userID];
      if (peers.length === 0) {
        this.isInProgress = false;
      }
    });

    this.socket.on("disconnect", () => {
      console.log("socket disconnected --");
    });

    this.socket.on("error", (err) => {
      console.log("socket error --", err);
    });

    window.ourspaceUserSocket.on("toggleScreenShare", (data) => {
      this.toggleScreenShare(data);
    });
  };

  dataHandler = (data) => {
    let result = data;
    try {
      result = JSON.parse(data);
    } catch (e) {
      console.log("doing nothing");
    }

    if (typeof result === "object") {
      if (this.settings.params.handleWhiteBoardData) {
        this.settings.params.handleWhiteBoardData(
          data,
          this.settings.params.catchup_id
        );
      }
    } else {
      if (this.settings.params.handleDataMessage) {
        this.settings.params.handleDataMessage(
          data,
          this.settings.params.catchup_id
        );
      }
    }
  };

  initializePeersEvents = () => {
    this.myPeer.on("open", (id) => {
      this.myID = id;
      const roomID = this.sharedRoomID;
      const userData = {
        userID: id,
        roomID,
        isMonitoring: this.isMonitoring,
        isAdmin: this.isAdmin,
      };
      console.log("peers established and joined room", userData);

      this.socket.emit("join-room", userData);
      this.setNavigatorToStream();
    });

    this.myPeer.on("error", (err) => {
      try {
        this.myPeer.reconnect();
      } catch (err) {
        console.log(err);
      }
    });
  };

  setNavigatorToStream = () => {
    let vid,
      audio = true;
    if (this.isMonitoring) {
      vid = true;
      audio = true;
    }

    this.getVideoAudioStream(vid, audio).then((mediaStream) => {
      const context = new AudioContext();
      const mediaStreamSource = context.createMediaStreamSource(mediaStream);
      const mediaStreamDestination = context.createMediaStreamDestination();
      const gainNode = context.createGain();

      gainNode.gain.value = 3;

      mediaStreamSource.connect(gainNode);
      gainNode.connect(mediaStreamDestination);

      const tracks = mediaStream.getVideoTracks();
      this.audioTracks = mediaStream.getAudioTracks();

      /**
       * The mediaStreamDestination.stream outputs a MediaStream object
       * containing a single AudioMediaStreamTrack. Add the video track
       * to the new stream to rejoin the video with the controlled audio.
       */
      const stream = mediaStreamDestination.stream;
      for (const videoTrack of tracks) {
        stream.addTrack(videoTrack);
      }

      if (stream) {
        this.streaming = true;
        this.createVideo({ id: this.myID, stream });
        stream.isMonitoring = this.isMonitoring;
        stream.isAdmin = this.isAdmin;
        this.setPeersListeners(stream);
        this.newUserConnection(stream);

        // register to catch messages about showing admin when they've been monitoring
        // and choose to join the catchup visibly
        window.ourspaceUserSocket.on("showMonitoringAdmin", (admin_id) => {
          this.showMonitoringAdmin(admin_id);
        });

        let constraints = {
          aspectRatio: { exact: 1.777777778 },
        };

        if (tracks && tracks[0]) {
          tracks[0].applyConstraints(constraints);
        }

        if (this.isMonitoring) {
          this.toggleAudio(false);
        }

        //
      }
    });
  };

  getVideoAudioStream = (video = true, audio = true) => {
    let quality = this.settings.params.quality;
    if (quality) quality = parseInt(quality);

    const myNavigator =
      navigator.mediaDevices.getUserMedia ||
      navigator.mediaDevices.webkitGetUserMedia ||
      navigator.mediaDevices.mozGetUserMedia ||
      navigator.mediaDevices.msGetUserMedia;

    return navigator.mediaDevices.getUserMedia({
      video: video
        ? {
            frameRate: quality ? quality : 12,
            noiseSuppression: true,
            echoCancellation: true,
            width: { min: 640, ideal: 1280, max: 1920 },
            height: { min: 480, ideal: 720, max: 1080 },
            aspectRatio: { exact: 1.777777778 },
          }
        : false,
      audio: audio ? { echoCancellation: true, noiseSuppression: true } : false,
    });
  };

  shareScreen = async () => {
    let quality = this.settings.params.quality;
    if (quality) quality = parseInt(quality);

    await navigator.mediaDevices
      .getDisplayMedia({
        video: {
          cursor: "always",
        },
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
      })
      .then((stream) => {
        let videoTrack = stream.getVideoTracks()[0];

        Object.values(peers).map((peer) => {
          if (peer && peer.peerConnection) {
            peer.peerConnection.getSenders().map((sender) => {
              if (sender.track.kind == "video") {
                if (videoTrack) {
                  sender.replaceTrack(videoTrack);

                  videoTrack.addEventListener("ended", () => {
                    this.stopSharingScreen();
                    this.moveVideosToMainPage();
                    this.onScreenShareEnd();
                    // tell remotes to popout the videos and enlarge the main video for screenshare
                    this.remoteInitScreenshare(false);
                  });
                }
              }
            });
          }
        });
        this.remoteInitScreenshare(true);
        this.amSharingScreen = true;
      })
      .catch((err) => {
        alert(
          "Unable to share your screen, please make sure you have screen recording enabled on your system for this browser"
        );
        this.amSharingScreen = false;
      });

    return this.amSharingScreen;
  };

  stopSharingScreen = () => {
    this.amSharingScreen = false;

    this.getVideoAudioStream().then((stream) => {
      let videoTrack = stream.getVideoTracks()[0];

      Object.values(peers).map((peer) => {
        if (peer && peer.peerConnection) {
          peer.peerConnection.getSenders().map((sender) => {
            if (sender.track.kind == "video") {
              if (videoTrack) {
                sender.replaceTrack(videoTrack);

                // this.stopSharingScreen();
                this.moveVideosToMainPage();
                this.onScreenShareEnd();
                // tell remotes to popout the videos and enlarge the main video for screenshare
                this.remoteInitScreenshare(false);
              }
            }
          });
        }
      });
    });
    // this.remoteInitScreenshare(false);
  };

  remoteInitScreenshare = (toggle) => {
    window.ourspaceUserSocket.emit("toggleScreenShare", {
      sender_id: this.myID,
      toggle: toggle,
    });
  };

  // used by receivers of screenshare cast
  toggleScreenShare = (data) => {
    if (data.toggle) {
      this.receiveRemoteScreenShare(data.sender_id);
    } else {
      this.stopReceiveRemoteScreenShare();
    }
  };
  // used by receivers of screenshare cast
  receiveRemoteScreenShare = (sender_id) => {
    // popout videos except remote initiator
    this.popoutVideos(true);
  };
  // used by receivers of screenshare cast
  stopReceiveRemoteScreenShare = () => {
    // move videos back to main page
    this.moveVideosToMainPage();
  };

  // create video and audio
  createVideo = (createObj) => {
    if (!this.videoContainer[createObj.id]) {
      this.videoContainer[createObj.id] = {
        ...createObj,
      };

      let videoType = "";
      if (createObj.id && createObj.id == this.myID) {
        if (this.settings.params.isAdmin) {
          videoType = "admin";
        } else {
          videoType = "local";
        }
        // if it's my match...
      } else if (
        createObj.id &&
        createObj.id == this.settings.params.remote_id
      ) {
        videoType = "remote";
        // if I'm the admin and it's another user
      } else if (
        this.settings.params.isAdmin &&
        createObj.id &&
        createObj.id != this.myID
      ) {
        videoType = "remote";
        // if I'm not the admin and this is an incoming admin feed...
      } else {
        videoType = "admin";
      }

      let roomContainer = document.getElementById("room-container");
      const videoContainer = document.createElement("div");
      const video = document.createElement("video");

      let oldContainer = document.getElementById(`${createObj.id}_container`);
      if (oldContainer) {
        oldContainer.parentNode.removeChild(oldContainer);
      }

      if (
        createObj &&
        createObj.userData &&
        createObj.userData.isMonitoring === "true"
      ) {
        this.isRemoteAdminMonitoring = true;
        video.srcObject = this.videoContainer[createObj.id].stream;
        videoContainer.id = createObj.id + "_container";
        video.id = createObj.id;
        video.classList.add("videoBlock-monitoring");
      } else {
        this.isRemoteAdminMonitoring = false;
        video.srcObject = this.videoContainer[createObj.id].stream;

        videoContainer.id = createObj.id + "_container";
        video.id = createObj.id;
        if (videoType == "admin") {
          video.classList.add(
            this.isCatchupBackgrounded
              ? "popoutAdminVideoBlock"
              : "adminVideoBlock"
          );
        } else {
          video.classList.add(
            this.isCatchupBackgrounded ? "popoutVideoBlock" : "videoBlock"
          );
        }

        video.setAttribute("playsinline", "playsinline");
        video.setAttribute("autoplay", "autoplay");
      }

      // store the DOM object references so we can rewrite them in case of users
      // leaving the catchup page and coming back
      switch (videoType) {
        case "remote": {
          this.remoteVidContainerDOMObject = videoContainer;
          this.remoteVidDOMObject = video;
          break;
        }
        case "admin": {
          this.adminVidContainerDOMObject = videoContainer;
          this.adminVidDOMObject = video;
          break;
        }
        // local
        default: {
          this.localVidContainerDOMObject = videoContainer;
          this.localVidDOMObject = video;
        }
      }

      // mute the local audio so that there isn't an echo
      if (videoType === "local" || createObj.id === this.myID) {
        video.muted = true;
      }

      videoContainer.appendChild(video);

      if (
        (videoType === "local" || videoType === "admin") &&
        this.settings.params.isMobile
      ) {
        // append local and admin to the frame so we can put them on top in the corner when on mobile
        const roomContainer = document.getElementById("catchupwrapper");
      }
      if (this.isCatchupBackgrounded) {
        // append videos to a frame in the footer so we can put them on top in the corner when the user is navigating the
        // site while in a catchup
        roomContainer = document.getElementById("popoutVideoContainer");
      }
      // differentiate the placement and classes so we can display them differently with CSS
      // depending on whether we're on desktop or mobile
      if (createObj && createObj.userData && createObj.userData.isMonitoring) {
        videoContainer.classList.add(
          `${this.isCatchupBackgrounded ? "popoutRemote" : "remote"}
          VideoContainer-monitoring`
        );
      } else {
        videoContainer.classList.add(
          `${
            this.isCatchupBackgrounded ? "popoutRemote" : `${videoType}`
          }VideoContainer`
        );
      }
      roomContainer.append(videoContainer);
    } else {
      // @ts-ignore
      document.getElementById(createObj.id).srcObject = createObj.stream;
    }
  };

  moveVideosToMainPage = (isScreenShare = false) => {
    this.isCatchupBackgrounded = false;

    const roomContainer = document.getElementById("room-container");

    if (
      this.localVidContainerDOMObject &&
      this.localVidContainerDOMObject.classList
    ) {
      this.localVidContainerDOMObject.classList.remove(
        `popoutLocalVideoContainer`
      );
      this.localVidContainerDOMObject.classList.add(`localVideoContainer`);
      this.localVidDOMObject.classList.remove(`popoutVideoBlock`);
      this.localVidDOMObject.classList.add(`videoBlock`);
      roomContainer.append(this.localVidContainerDOMObject);
    }

    if (
      this.remoteVidContainerDOMObject &&
      this.remoteVidContainerDOMObject.classList
    ) {
      if (isScreenShare) {
        this.remoteVidDOMObject.classList.remove(`remoteScreenShareVideoBlock`);
        this.remoteVidDOMObject.classList.add(`videoBlock`);
        this.remoteVidContainerDOMObject.classList.remove(
          `popoutRemoteScreenShareVideoContainer`
        );
        this.remoteVidContainerDOMObject.classList.add(`remoteVideoContainer`);
      } else {
        this.remoteVidContainerDOMObject.classList.remove(
          `popoutRemoteVideoContainer`
        );
        this.remoteVidContainerDOMObject.classList.add(`remoteVideoContainer`);
        this.remoteVidDOMObject.classList.remove(`popoutVideoBlock`);
        this.remoteVidDOMObject.classList.add(`videoBlock`);
        roomContainer.append(this.remoteVidContainerDOMObject);
      }
    }

    if (
      this.adminVidContainerDOMObject &&
      this.adminVidContainerDOMObject.classList
    ) {
      if (!this.isRemoteAdminMonitoring) {
        this.adminVidContainerDOMObject.classList.remove(
          `popoutAdminVideoContainer`
        );
        this.adminVidContainerDOMObject.classList.add(`adminVideoContainer`);
        this.adminVidDOMObject.classList.remove(`popoutVideoBlock`);
        this.adminVidDOMObject.classList.add(`videoBlock`);
        roomContainer.append(this.adminVidContainerDOMObject);
      }
    }
  };

  popoutVideos = (isScreenShare = false) => {
    this.isCatchupBackgrounded = true;
    const roomContainer = document.getElementById("popoutVideoContainer");

    if (
      this.localVidContainerDOMObject &&
      this.localVidContainerDOMObject.classList
    ) {
      // replace with popout classes
      this.localVidContainerDOMObject.classList.remove(`localVideoContainer`);
      this.localVidContainerDOMObject.classList.add(
        `popoutLocalVideoContainer`
      );
      this.localVidDOMObject.classList.remove(`videoBlock`);
      this.localVidDOMObject.classList.add(`popoutVideoBlock`);

      // add to the popout container
      roomContainer.append(this.localVidContainerDOMObject);
    }

    if (
      this.remoteVidContainerDOMObject &&
      this.remoteVidContainerDOMObject.classList
    ) {
      // if we're popping out videos because we're receiving a screenshare...
      if (isScreenShare && !this.amSharingScreen) {
        this.remoteVidDOMObject.classList.remove(`videoBlock`);
        this.remoteVidDOMObject.classList.add(`remoteScreenShareVideoBlock`);
        this.remoteVidContainerDOMObject.classList.remove(
          `remoteVideoContainer`
        );
        this.remoteVidContainerDOMObject.classList.add(
          `remoteScreenShareVideoContainer`
        );
      } else if (!isScreenShare) {
        // replace with popout classes
        this.remoteVidContainerDOMObject.classList.remove(
          `remoteVideoContainer`
        );
        this.remoteVidContainerDOMObject.classList.add(
          `popoutRemoteVideoContainer`
        );
        this.remoteVidDOMObject.classList.remove(`videoBlock`);
        this.remoteVidDOMObject.classList.add(`popoutVideoBlock`);

        // add to the popout container
        roomContainer.append(this.remoteVidContainerDOMObject);
      }
    }

    if (
      this.adminVidContainerDOMObject &&
      this.adminVidContainerDOMObject.classList
    ) {
      if (!this.isRemoteAdminMonitoring) {
        // replace with popout classes
        this.adminVidContainerDOMObject.classList.remove(`adminVideoContainer`);
        this.adminVidContainerDOMObject.classList.add(
          `popoutAdminVideoContainer`
        );
        this.adminVidDOMObject.classList.remove(`videoBlock`);
        this.adminVidDOMObject.classList.add(`popoutVideoBlock`);

        // add to popout container
        roomContainer.append(this.adminVidContainerDOMObject);
      }
    }
  };

  setPeersListeners = (stream) => {
    this.myPeer.on("call", (call) => {
      call.answer(stream);

      call.on("stream", (userVideoStream) => {
        this.createVideo({ id: call.metadata.id, stream: userVideoStream });
      });

      call.on("data", (data) => {
        this.dataHandler(data);
      });

      call.on("close", () => {
        console.log("closing peers listeners", call.metadata.id);
        this.removeVideo(call.metadata.id);
      });

      call.on("error", () => {
        console.log("peer error ------");
        this.removeVideo(call.metadata.id);
      });

      peers[call.metadata.id] = call;
    });

    this.myPeer.on("connection", (conn) => {
      dcs[conn.peer] = conn;

      conn.on("data", (data) => {
        this.dataHandler(data);
      });
    });
  };

  establishDataConnection = (userID) => {
    return this.myPeer.connect(userID);
  };

  newUserConnection = (stream) => {
    this.socket.on("new-user-connect", (userData) => {
      this.connectToNewUser(userData, stream);
    });
  };

  showMonitoringAdmin = (admin_id) => {
    const roomContainer = document.getElementById("room-container");
    const containerID = admin_id + "_container";
    const videoContainer = document.getElementById(containerID);
    const video = document.getElementById(admin_id);
    for (const [key, value] of Object.entries(this.videoContainer)) {
      if (key == admin_id) {
        video.pause();
        video.src = value.stream;
        video.load();
        let videoPromise = video.play();
        videoPromise
          .then((result) => {
            console.log(result);
          })
          .catch((err) => {
            video.pause();
          });
        break;
      }
    }

    videoContainer.classList.remove("adminVideoContainer-monitoring");
    videoContainer.classList.add("adminVideoContainer");
    video.classList.remove("videoBlock-monitoring");
    video.classList.add("adminVideoBlock");
    video.setAttribute("playsinline", "playsinline");
    video.setAttribute("autoplay", "autoplay");
    let videoPromise = video.play();

    videoPromise
      .then((result) => {})
      .catch((err) => {
        video.pause();
      });
  };

  connectToNewUser(userData, stream) {
    const { userID, isMonitoring, isAdmin } = userData;
    if (!this.isAdmin) {
      if (isAdmin) {
        this.adminID = userID;
        if (isMonitoring) {
          this.isRemoteAdminMonitoring = true;
        } else {
          this.isRemoteAdminMonitoring = false;
        }
      }
    }

    this.roomIDs[userID] = true;
    connectionRetryTimer = setInterval(() => {
      const call = this.myPeer.call(userID, stream, {
        metadata: {
          id: this.myID,
          isAdmin: this.isAdmin,
          isMonitoring: this.isMonitoring,
        },
      });

      const dc = this.establishDataConnection(userID);
      const dataHandler = this.dataHandler;
      dc.on("open", () => {
        dc.on("data", function (data) {
          dataHandler(data);
        });
      });

      call.on("stream", (userVideoStream) => {
        this.createVideo({ id: userID, stream: userVideoStream, userData });
        if (!this.isAdmin) {
          if (userData.isAdmin) {
            this.adminID = userID;
            if (userData.isMonitoring) {
              this.isRemoteAdminMonitoring = true;
            } else {
              this.isRemoteAdminMonitoring = false;
            }
          }
        }
      });

      call.on("data", (data) => {
        dataHandler(data);
      });

      call.on("close", () => {
        delete this.roomIDs[userID];
        this.removeVideo(userID);
      });

      call.on("error", () => {
        console.log("peer error ------");
        this.removeVideo(userID);
      });

      peers[userID] = call;
      dcs[userID] = dc;

      // if (!this.isInProgress) {
      //   alert("Register in progress");
      //   if (this.settings.params.registerInProgress) {
      this.settings.params.registerInProgress(this.settings.params);
      //   }
      //   this.isInProgress = true;
      // }

      if (
        peers[userID] &&
        peers[userID].peerConnection &&
        peers[userID].peerConnection.connectionState &&
        peers[userID].peerConnection.connectionState.length > 0
      ) {
        clearInterval(connectionRetryTimer);
      }
    }, 5000);
  }

  sendDataMessageToRoom = (msg) => {
    return new Promise((resolve, rejest) => {
      const localPeer = this.myPeer;
      // this.dataConnection.send(msg);
      Object.values(dcs).map((connection) => {
        connection.send(msg);
      });
      resolve(true);
    });
  };

  clearVideos = () => {
    for (const [key, value] of Object.entries(this.roomIDs)) {
      this.removeVideo(key);
    }
  };

  removeVideo = (id) => {
    delete this.videoContainer[id];
    const video = document.getElementById(id);
    const videoContainer = document.getElementById(id + "_container");

    if (video) video.remove();
    if (videoContainer) videoContainer.remove();
  };

  destroyConnection = () => {
    if (
      this.videoContainer[this.myID] &&
      this.videoContainer[this.myID].stream
    ) {
      const myMediaTracks = this.videoContainer[this.myID].stream.getTracks();

      myMediaTracks.forEach((track) => {
        track.stop();
      });

      this.audioTracks.forEach((t) => t.stop());
    }

    this.endHeartbeat();
    this.socket.disconnect();
    this.myPeer.destroy();
    this.clearVideos();

    clearInterval(connectionRetryTimer);
  };

  beginHeartbeat = () => {
    const API_URL = process.env.REACT_APP_SERVER_API_URL + "heartbeat";
    // ping the server every minute to keep our tokens active
    heartbeat = setInterval(() => {
      axios.get(API_URL);
    }, 60000);
  };

  endHeartbeat = () => {
    clearInterval(heartbeat);
  };

  reInitializeStream = (video, audio, type = "userMedia") => {
    const myNavigator =
      navigator.mediaDevices.getUserMedia ||
      navigator.mediaDevices.webkitGetUserMedia ||
      navigator.mediaDevices.mozGetUserMedia ||
      navigator.mediaDevices.msGetUserMedia;

    const media =
      type === "userMedia"
        ? this.getVideoAudioStream(video, audio)
        : myNavigator.mediaDevices.getDisplayMedia();

    return new Promise((resolve) => {
      media.then((stream) => {
        if (type === "displayMedia") {
          this.toggleVideoTrack({ audio, video });
        }
        this.createVideo({ id: this.myID, stream });
        this.replaceStream(stream);
        resolve(true);
      });
    });
  };

  toggleAudio = (isAudioOn) => {
    const myVideo = document.getElementById(this.myID);
    if (myVideo) {
      myVideo.srcObject.getAudioTracks().forEach((t) => {
        t.enabled = isAudioOn;
      });
    }
  };

  toggleVideoTrack = (status) => {
    const myVideo = document.getElementById(this.myID);
    if (myVideo && !status.video)
      myVideo.srcObject.getVideoTracks().forEach((track) => {
        if (track.kind === "video") {
          !status.video && track.stop();
        }
      });
    else if (myVideo) {
      this.reInitializeStream(status.video, status.audio);
    }
  };

  replaceStream = (mediaStream) => {
    Object.values(peers).map((peer) => {
      if (peer && peer.peerConnection) {
        peer.peerConnection.getSenders().map((sender) => {
          if (!sender.track || !sender.track.kind) {
            // fully reinitialize instead of updating tracks
            this.reInitializeStream(true, true);
            return;
          } else {
            if (sender.track.kind == "audio") {
              if (mediaStream.getAudioTracks().length > 0) {
                sender.replaceTrack(mediaStream.getAudioTracks()[0]);
              }
            }
            if (sender.track.kind == "video") {
              if (mediaStream.getVideoTracks().length > 0) {
                sender.replaceTrack(mediaStream.getVideoTracks()[0]);
              }
            }
          }
        });
      }
    });
  };

  reInitialiseConnection = () => {
    this.myPeer = initializePeerConnection(this.myID);
    this.socket = initializeSocketConnection();

    this.initializeSocketEvents();
    this.initializePeersEvents();
    // this.reInitialiseConnection();
  };
}

export function createSocketConnectionInstance(settings = {}) {
  return (socketInstance = new Connection(settings));
}
